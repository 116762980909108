import RootScreens from 'features/RootScreens';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LoadingPage from 'services/UI/LoadingPage';
import { persistedStore, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';

const AppLayout: React.FC = () => <RootScreens />;

const App: React.FC = () => {
  useEffect(() => {
    document.title = 'Fozito Portal';
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <AppLayout />
        <LoadingPage />
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
};

export default App;
