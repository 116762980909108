import { Col, Form, Input, Pagination, Row, Select, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import giftCardApis from '../services/apis';
import GiftCardPageStyled from './styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import Box from 'components/Box';
import Text from 'components/Text';
import Button from 'components/Button';
import Table, { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import accountApis from 'features/account/services/apis';
import { TableRowSelection } from 'antd/es/table/interface';
import { Content } from 'antd/es/layout/layout';
import { currencyFormat } from 'utils/FunctionUtils';
import Swal from 'sweetalert2';

export const GiftCardPage = () => {
    const setPageLoading = useSetLoadingPage();
    const [lstShop, setLstShop] = useState<any[]>([]);
    const ITEMS_PER_PAGE = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotal] = useState(0);
    const [lstData, setLstData] = useState<any[]>([]);

    const getDataList = async (
        shopId: any, customerName: any,
        sourceType: any, giftCode: any,
        resetTime: any, page: any
    ) => {
        try {
            const response = await giftCardApis.filterListGiftCard(shopId, customerName, sourceType, giftCode, resetTime, page, ITEMS_PER_PAGE);
            if (response.status === 200) {
                setLstData(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                const response = await accountApis.filterSalon(null, null, null, null);
                if (response.status === 200) {
                    setLstShop(response.data.data.content);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }

                await getDataList(null, null, null, null, null, currentPage);
            } catch (error) {
            } finally {
                setPageLoading(false);
            }
        };
        initData();
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: <p>SALON<br />NAME</p>,
            dataIndex: 'shopName',
            className: 'shopName',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: <p>GIFT CARD<br />NUMBER</p>,
            dataIndex: 'giftCode',
            className: 'giftCode',
        },
        {
            title: <p>GIFT CARD<br />NAME</p>,
            dataIndex: 'giftName',
            className: 'giftName',
        },
        {
            title: <p>CUSTOMER<br />NAME</p>,
            dataIndex: 'customerName',
            className: 'customerName',
        },
        {
            title: <p>CUSTOMER<br />PHONE</p>,
            dataIndex: 'customerPhone',
            className: 'customerPhone',
        },
        {
            title: <p>AMOUNT</p>,
            dataIndex: 'amount',
            render: (amount: number) =>
                <span>{currencyFormat(amount, 'USD')}</span>,

        },
        {
            title: <p>REDEEMED</p>,
            dataIndex: 'totalRedeem',
            render: (totalRedeem: number) =>
                <span>{currencyFormat(totalRedeem, 'USD')}</span>,

        },
        {
            title: <p>ISSUED<br />DATE</p>,
            dataIndex: 'issuedDate',
            className: 'issuedDate',
            render: (issuedDate: number) =>
                <span>{issuedDate}</span>,

        },
        {
            title: <p>EXPIRED<br />DATE</p>,
            dataIndex: 'totalExpireDate',
            className: 'totalExpireDate',
            render: (totalExpireDate: number) =>
                <span>{totalExpireDate} days left</span>,
        },
        {
            title: <p>SOURCE<br />TYPE</p>,
            dataIndex: 'sourceType',
            className: 'sourceType',
            render: (sourceType: string) =>
                <Fragment>
                    {sourceType === 'STORE' ?
                        (
                            <span style={{ display: 'flex' }}>
                                <img src='/images/icon_online.png' /> In Store
                            </span>
                        )
                        :
                        (
                            <span style={{ display: 'flex' }}>
                                <img src='/images/icon_online.png' /> Online
                            </span>
                        )
                    }
                </Fragment>

        },
        {
            title: <p>RESET<br />TIME</p>,
            dataIndex: 'resetTime',
            className: 'resetTime',
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            render: (item: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Button className='btn-delete' onClick={() => handleDelete(item.giftId, item.shopName)} icon="delete"></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    const onPageChanged = async (pageNumber: number) => {
        setPageLoading(true);
        try {
            setCurrentPage(pageNumber);
            const values = await form.validateFields();
            await getDataList(values.shopId, values.customerName, values.sourceType, values.giftCode, values.resetTime, pageNumber);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            setPageLoading(false);
        }
    };

    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDelete = async (giftId: string, shopName: string) => {
        const result = await Swal.fire({
            title: shopName,
            html: 'Do you want delete gift card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                message.warning({ content: 'Feature is being completed', duration: 2 });
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    return (
        <GiftCardPageStyled>
            <Box className="d-between">
                <Text variant="H5">Coupons List</Text>
                <Box display="flex" gap={1}>
                    <Button icon="delete">Delete</Button>
                    <Button variant="SECONDARY">
                        <ReloadOutlined style={{ marginRight: '5px' }} />
                        Reset
                    </Button>
                </Box>
            </Box>

            <ContentStyled>
                <Form form={form} name="search">
                    <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="shopId">
                                <Select size='large' style={{ width: '100%' }}
                                    showSearch optionFilterProp="label"
                                    onChange={handleSearch}
                                    defaultValue={''}>
                                    <Select.Option key={'0'} value=''>{'All Salon'}</Select.Option>
                                    {lstShop.map((item: any) => (
                                        <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.state} - ${item.zip}`}>
                                            {item.shopName} - {item.state} {item.zip}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="customerName">
                                <Input size='large' onBlur={handleSearch} placeholder='Customer Name' />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="sourceType">
                                <Select defaultValue={''} size="large" onChange={handleSearch}>
                                    <Select.Option key={''} value=''>{'All Source Type'}</Select.Option>
                                    <Select.Option key={'STORE'} value={'STORE'}>{'In Store'}</Select.Option>
                                    <Select.Option key={'ONLINE'} value={'ONLINE'}>{'Online'}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item name="resetTime">
                                <Input size='large' type='number' onBlur={handleSearch} placeholder='Reset Time' />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item name="giftCode">
                                <Input size='large' onBlur={handleSearch} placeholder='Card Number' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>

                <TableContentStyled
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={lstData}
                    rowKey="giftId"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>

            </ContentStyled>
        </GiftCardPageStyled>
    );
};

const ContentStyled = styled.div`
  background: rgba(255, 255, 255, 1);
  margin-top: 2rem;
  padding: 1.5rem;
`;

const TableContentStyled = styled(Table)`
  && .shopName {
    width: 15%;
  };

  && .giftName {
    width: 10%;
  };

  && .issuedDate {
    width: 10%;
  };
  
  && .totalExpireDate {
    width: 10%;
  };
  

  && .sourceType {
    width: 10%;
  };
 
  && .btn-delete {
    background: transparent;
    border: none;
    padding: 8px;
  }

  && .btn-delete:hover {
    background: rgba(245, 118, 127, 1) !important;
    border: none !important;
  }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
`;
