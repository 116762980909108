import React from 'react';
import { AddIconProps } from '..';
const RepeatIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.61469 6.47244C7.83849 5.30588 9.07592 4.1543 10.3106 3C10.3038 3.71548 10.3052 4.43231 10.2888 5.14779C13.5282 5.14779 16.7676 5.14507 20.007 5.14915C21.0141 5.15461 22.0063 5.5907 22.6741 6.3457C23.7152 7.45776 24.0818 9.03725 23.9851 10.5254C23.9033 12.1663 24.2358 13.8957 23.5735 15.4561C23.166 16.4482 22.4287 17.3477 21.4298 17.7811C20.2714 18.2717 18.989 18.0141 17.772 18.0795C17.772 17.176 17.7747 16.2724 17.7707 15.3689C18.5475 15.358 19.3256 15.3934 20.1011 15.3525C21.0237 15.2285 21.5661 14.2459 21.577 13.3833C21.577 12.1595 21.5893 10.9357 21.5715 9.71321C21.5157 8.85873 20.9188 7.90067 19.9866 7.86524C16.7595 7.84752 13.531 7.87069 10.3025 7.85433C10.2929 8.56436 10.312 9.27302 10.297 9.98304C9.06093 8.82193 7.84258 7.6431 6.61469 6.47244Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M2.44584 5.49058C3.62058 4.93728 4.95069 5.21801 6.20038 5.14442C6.2072 6.0466 6.20175 6.95015 6.20311 7.85369C5.42904 7.87414 4.65223 7.82916 3.87952 7.8755C3.18585 7.945 2.69796 8.55281 2.50716 9.18107C2.35725 9.61444 2.3995 10.0764 2.39678 10.5262C2.40359 11.5278 2.38588 12.5295 2.40359 13.5312C2.47173 14.3788 3.05229 15.3274 3.98718 15.3614C7.21295 15.3805 10.4401 15.3519 13.6659 15.375C13.6768 14.6596 13.6495 13.9427 13.6836 13.2286C14.8992 14.4197 16.1448 15.5795 17.3673 16.7651C16.1285 17.9208 14.8842 19.0696 13.6645 20.2457C13.6604 19.5234 13.6727 18.8025 13.6836 18.0802C10.4333 18.0775 7.18297 18.0816 3.93403 18.0789C2.99369 18.0639 2.0697 17.6714 1.41147 17.0009C0.447959 16.021 -0.0481069 14.6201 0.00367997 13.2545C0.0159453 11.9476 -0.0113101 10.6393 0.0173089 9.33234C0.159041 7.77737 0.995808 6.18561 2.44584 5.49058Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default RepeatIcon;
