import React from 'react';
import { AddIconProps } from '..';
const RefundIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.22906 4.24068C6.02375 2.43164 8.4567 1.24649 11.0045 1.04679C13.4309 0.806625 15.9344 1.42661 17.9731 2.76186C20.4818 4.37904 22.2934 7.05476 22.8168 9.99805C23.2306 12.2222 22.9408 14.5742 21.9763 16.6247C20.9034 18.9336 19.0003 20.8458 16.6927 21.9239C14.958 22.7527 13.0133 23.0843 11.0998 23.0086C11.1037 22.4016 11.1024 21.7947 11.1011 21.1878C12.7026 21.2543 14.3407 21.0546 15.8025 20.3655C18.0815 19.3317 19.9023 17.3347 20.6971 14.9592C21.4594 12.739 21.3223 10.2291 20.3251 8.10547C19.4572 6.23507 17.9314 4.68316 16.0884 3.76166C14.1292 2.80363 11.8242 2.53997 9.71102 3.10383C8.12778 3.50062 6.67375 4.35293 5.50949 5.4937C6.10859 6.15937 6.76773 6.76891 7.39816 7.40325C5.56953 7.71259 3.73829 8.00757 1.90967 8.32083C2.12112 6.39039 2.30907 4.45866 2.5179 2.52692C3.08176 3.10513 3.66129 3.66769 4.22906 4.24068Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M11.0983 6.50488C11.7 6.51141 12.3004 6.51141 12.9022 6.50488C12.8982 6.85991 12.8982 7.21623 12.8956 7.57125C13.9633 7.9563 14.7373 9.0044 14.7504 10.1452C14.15 10.1413 13.5509 10.1426 12.9518 10.1439C12.88 9.79276 12.7155 9.41685 12.3448 9.29807C11.7496 9.02528 11.0135 9.56826 11.0631 10.2104C11.0944 10.401 11.2693 10.5185 11.409 10.6333C12.1595 11.1672 13.0627 11.4517 13.7871 12.0286C14.394 12.4894 14.8039 13.2464 14.7308 14.0204C14.6825 15.0998 13.8941 16.0539 12.8969 16.4233C12.8982 16.7783 12.8982 17.1334 12.9022 17.4897C12.3004 17.4832 11.7 17.4832 11.0983 17.4897C11.1022 17.1334 11.1022 16.7783 11.1035 16.4233C10.0359 16.0422 9.25404 14.9928 9.25012 13.8494C9.84792 13.8533 10.4457 13.852 11.0435 13.8494C11.1166 14.1966 11.2771 14.5712 11.6413 14.6926C12.2717 15.0202 13.1697 14.2697 12.863 13.6145C12.0629 12.877 10.9195 12.6499 10.1011 11.9281C9.54119 11.4543 9.20444 10.7221 9.26579 9.98462C9.30494 8.89345 10.1064 7.94977 11.1049 7.56995C11.1022 7.21493 11.1022 6.85991 11.0983 6.50488Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M1.5285 11.1611C2.21766 10.8361 3.04648 11.5657 2.76977 12.2849C2.58965 12.9349 1.63552 13.1202 1.21916 12.5955C0.81584 12.1596 0.98813 11.3973 1.5285 11.1611Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M2.56359 14.9553C2.94733 14.9239 3.36761 15.1302 3.50466 15.5074C3.78137 16.0621 3.27886 16.7956 2.66801 16.7669C2.22031 16.7774 1.82352 16.4067 1.75174 15.9733C1.70475 15.4878 2.07804 15.0153 2.56359 14.9553Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M4.55126 18.2806C5.2339 17.983 6.034 18.7649 5.72205 19.4462C5.54063 20.0714 4.64263 20.2685 4.20538 19.7947C3.73027 19.3626 3.91823 18.4477 4.55126 18.2806Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M7.81845 20.4759C8.45671 20.1874 9.26856 20.8949 9.01404 21.5644C8.8535 22.1923 7.9568 22.4899 7.50519 21.9782C7.01704 21.5488 7.22196 20.6886 7.81845 20.4759Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default RefundIcon;
