import React from 'react';
import { AddIconProps } from '..';
const CashIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.625813 5.27154C0.680657 5.25654 0.73597 5.24904 0.793157 5.24998C8.26363 5.24998 15.7341 5.24998 23.2046 5.24998C23.4403 5.22232 23.6522 5.43607 23.625 5.67138C23.625 9.8906 23.625 14.1098 23.625 18.329C23.6522 18.5634 23.4403 18.7762 23.2055 18.75C15.8138 18.7504 8.42159 18.7495 1.02988 18.7504C0.846126 18.7439 0.624876 18.7879 0.484251 18.6384C0.347376 18.51 0.377376 18.3098 0.374095 18.1415C0.37597 13.9847 0.374563 9.82779 0.375032 5.67045C0.360501 5.50076 0.460345 5.32498 0.625813 5.27154ZM1.12503 5.99998C1.12503 9.99982 1.12503 14.0001 1.12503 18C8.37519 18 15.6249 18 22.875 18C22.875 14.0001 22.875 9.99982 22.875 5.99998C15.6249 5.99998 8.37519 5.99998 1.12503 5.99998Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M3.62299 6.39784C3.67971 6.38143 3.73784 6.37393 3.79737 6.37487C9.26581 6.37487 14.7347 6.37487 20.2036 6.37487C20.3981 6.35518 20.6006 6.49721 20.6175 6.69784C20.6325 6.91206 20.6527 7.13096 20.7427 7.32924C20.9602 7.85987 21.503 8.23534 22.0772 8.24752C22.2905 8.22924 22.5038 8.39893 22.4977 8.62018C22.5038 10.8402 22.4972 13.0602 22.501 15.2802C22.508 15.4185 22.4808 15.5727 22.3641 15.6627C22.2057 15.793 21.9858 15.7316 21.8011 15.7864C21.1472 15.92 20.6353 16.536 20.6269 17.2039C20.6485 17.4383 20.4422 17.6511 20.206 17.6249C14.7361 17.6249 9.26674 17.6249 3.79737 17.6249C3.55737 17.6535 3.34924 17.4364 3.37268 17.1992C3.36143 16.5336 2.85143 15.9196 2.1994 15.7864C2.00487 15.7292 1.76487 15.7986 1.61018 15.6392C1.47284 15.5113 1.5019 15.3106 1.49909 15.1419C1.50049 13.0489 1.50049 10.9555 1.49909 8.86206C1.50237 8.69143 1.47143 8.48752 1.61112 8.35909C1.76627 8.20112 2.00534 8.26956 2.19893 8.21284C2.8519 8.07924 3.36237 7.46471 3.37268 6.79768C3.36424 6.62846 3.45612 6.45221 3.62299 6.39784ZM4.09409 7.12581C3.94549 8.04784 3.17346 8.82081 2.25096 8.96893C2.24909 10.9897 2.24956 13.01 2.25096 15.0303C3.17393 15.178 3.94549 15.9514 4.09409 16.8739C9.36471 16.8753 14.6349 16.8753 19.9055 16.8739C20.0532 15.951 20.8266 15.1789 21.7491 15.0308C21.751 13.01 21.7505 10.9897 21.7491 8.9694C20.8275 8.8194 20.0536 8.04924 19.906 7.12581C14.6353 7.12393 9.36471 7.1244 4.09409 7.12581Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M11.4614 7.53418C12.4097 7.41652 13.3945 7.61387 14.2237 8.08965C15.2067 8.64699 15.9651 9.58683 16.297 10.6678C16.6148 11.6826 16.5534 12.809 16.1287 13.7836C15.7294 14.7168 15.0009 15.5029 14.1033 15.9759C13.2351 16.4376 12.2123 16.6017 11.2434 16.4334C10.1686 16.2539 9.16874 15.6656 8.49045 14.8125C7.87405 14.0484 7.52061 13.0776 7.5028 12.0961C7.47702 11.062 7.82623 10.0246 8.46795 9.21371C9.19264 8.28887 10.2937 7.66965 11.4614 7.53418ZM11.5073 8.28324C10.5642 8.40558 9.6778 8.9034 9.08342 9.64543C8.49749 10.365 8.19702 11.309 8.25889 12.2343C8.31327 13.1582 8.72764 14.0554 9.39608 14.6953C9.99373 15.2765 10.792 15.6473 11.6217 15.7289C12.4134 15.8123 13.2295 15.6318 13.9125 15.2231C14.6423 14.7914 15.217 14.1051 15.5119 13.3101C15.7865 12.5812 15.8226 11.7661 15.6164 11.0156C15.382 10.1418 14.8172 9.3609 14.0587 8.86777C13.3148 8.37465 12.3914 8.16418 11.5073 8.28324Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M11.6128 9.764C11.6325 9.58072 11.7548 9.39697 11.9512 9.37869C12.1758 9.34119 12.3652 9.53572 12.3848 9.74994C12.6337 9.75275 12.8827 9.74478 13.1316 9.75228C13.3289 9.74806 13.5028 9.92666 13.4991 10.1231C13.5047 10.3223 13.3275 10.5027 13.1278 10.4976C12.6722 10.5079 12.2166 10.4901 11.7614 10.5051C11.4811 10.521 11.2458 10.7802 11.2514 11.0601C11.243 11.3423 11.4811 11.602 11.7623 11.6198C12.0258 11.6376 12.2967 11.5982 12.5536 11.6774C13.1348 11.8359 13.5511 12.4345 13.4953 13.0349C13.4611 13.6312 12.9736 14.1482 12.3862 14.2377C12.367 14.4192 12.2442 14.6001 12.0511 14.6207C11.8256 14.6592 11.6344 14.4651 11.6147 14.2499C11.3667 14.2467 11.1183 14.2551 10.8703 14.2476C10.6711 14.2523 10.4939 14.0713 10.5009 13.8721C10.4967 13.6748 10.6725 13.4967 10.8708 13.5023C11.3109 13.4938 11.7516 13.506 12.1917 13.4976C12.472 13.5028 12.727 13.2665 12.7458 12.9876C12.7781 12.7068 12.5634 12.4265 12.2841 12.3857C12.0356 12.3557 11.7811 12.4007 11.535 12.344C11.1337 12.2587 10.7822 11.9737 10.6158 11.5987C10.4334 11.1984 10.4714 10.7067 10.717 10.341C10.9172 10.0331 11.2509 9.82259 11.6128 9.764Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default CashIcon;
