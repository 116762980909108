
import React from 'react';
import { AddIconProps } from '..';
const SMSIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 1.88208L22.7361 12.0001L2.5 22.1181V13.9855L5.27954 12.0001L2.5 10.0147V1.88208ZM7.32046 13.0001L4.5 15.0147V18.8821L16.2639 13.0001H7.32046ZM16.2639 11.0001H7.32047L4.5 8.9855V5.11815L16.2639 11.0001Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default SMSIcon;
