import axios from 'axios';
import { persistStore } from 'redux-persist';
import { store } from 'store';

const API_URL = 'https://api.bonafideadvisors.net';
// const API_URL = 'https://api.fozito.com';

const fastApi = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

fastApi.interceptors.response.use(
  (res: any) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      delete fastApi.defaults.headers['Authorization'];
      const persistor = persistStore(store);
      persistor.purge();
    } else {
      if (err.response && err.response.data && err.response.data.message) {
        const errorObject = {
          message: err.response.data.message,
        };
        return Promise.reject(errorObject);
      } else {
        const errorObject = {
          message: 'Server not available',
        };
        return Promise.reject(errorObject);
      }
    }
  }
);
export { fastApi };