import React from 'react';
import { AddIconProps } from '..';

const MenuVipIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.12988 0.545141C9.26738 0.257636 9.55569 0.0471486 9.87303 0.00884149C10.1755 -0.0334979 10.4936 0.0781976 10.7021 0.301588C10.9537 0.561673 11.0372 0.969341 10.9061 1.30685C10.7956 1.61008 10.5239 1.83629 10.2138 1.91371C11.5404 3.54076 12.867 5.1678 14.1945 6.79404C15.5469 6.11822 16.8965 5.43716 18.2478 4.75973C18.1482 4.60206 18.0691 4.42504 18.0667 4.23593C18.0486 3.90044 18.222 3.56334 18.5054 3.38309C18.7841 3.1976 19.1607 3.17502 19.4599 3.32543C19.7591 3.46938 19.97 3.77786 19.9958 4.10891C20.0268 4.42746 19.8845 4.75489 19.6329 4.95207C19.3873 5.15086 19.0421 5.21175 18.7421 5.11296C17.7586 7.6001 16.7865 10.0921 15.8066 12.5808C11.936 12.5812 8.06494 12.5808 4.19431 12.5812C3.21526 10.0917 2.24508 7.59889 1.2612 5.11175C0.96119 5.21215 0.615217 5.15248 0.369245 4.95247C0.0926275 4.73997 -0.0472941 4.37101 0.0144005 4.02826C0.0809338 3.60689 0.45715 3.2597 0.883367 3.22986C1.26402 3.19115 1.65233 3.40446 1.82532 3.74519C1.99709 4.06214 1.9608 4.46658 1.75314 4.75892C3.10356 5.45934 4.45722 6.1533 5.80684 6.85492C7.13589 5.20933 8.46011 3.55971 9.79118 1.91573C9.54803 1.85161 9.32383 1.70726 9.18834 1.49274C9.00407 1.21612 8.9835 0.842726 9.12988 0.545141Z" fill="#566A7F" />
                <path d="M4.19429 13.2264C8.06532 13.2252 11.9364 13.2268 15.8074 13.2256C15.8062 13.8708 15.8082 14.5159 15.8066 15.1611C11.9355 15.1623 8.06451 15.1607 4.19348 15.1619C4.19469 14.5167 4.19268 13.8716 4.19429 13.2264Z" fill="#566A7F" />
            </svg>
        </span>
    );
};
export default MenuVipIcon;
