import { Device } from './types/account';

export const NAME_REDUCER = 'account';

export const PREFIX_ACTIONS = 'account_feature_';

export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getSalons: `loading.${NAME_REDUCER}.getSalons`,
  getAccounts: `loading.${NAME_REDUCER}.getAccounts`,
};

export const initialAccountDevices: Device[] = [
  {
    feature: 'CHECK_IN',
    label: 'Check In',
    numberDevice: 0,
    enable: false,
    id: '',
    shopId: ''
  },
  {
    feature: 'POS',
    label: 'POS',
    numberDevice: 0,
    enable: false,
    id: '',
    shopId: ''
  },
  {
    feature: 'APPOINTMENT',
    label: 'Appointment',
    numberDevice: 0,
    enable: false,
    id: '',
    shopId: ''
  },
  {
    feature: 'WAITING_LIST',
    label: 'Waiting List',
    numberDevice: 0,
    enable: false,
    id: '',
    shopId: ''
  },
  {
    feature: 'TURN',
    label: 'Turn',
    numberDevice: 0,
    enable: false,
    id: '',
    shopId: ''
  },
];

type TimeZone = {
  label: string;
  value: string;
};

export const GMT_TIMEZONES: TimeZone[] = [
  {
    value: 'GMT-12',
    label: 'GMT-12: Anywhere on Earth (AoE)'
  },
  {
    value: 'GMT-11',
    label: 'GMT-11: Samoa Standard Time (ST)'
  },
  {
    value: 'GMT-10',
    label: 'GMT-10: Hawaii-Aleutian Standard Time (HAT)'
  },
  {
    value: 'GMT-9',
    label: 'GMT-9: Alaska Standard Time (AKT)'
  },
  {
    value: 'GMT-8',
    label: 'GMT−8: Pacific Standard Time (PT)'
  },
  {
    value: 'GMT-7',
    label: 'GMT−7: Mountain Standard Time (MT)'
  },
  {
    value: 'GMT-6',
    label: 'GMT−6: Central Standard Time (CT)'
  },
  {
    value: 'GMT-5',
    label: 'GMT−5: Eastern Standard Time (ET)'
  },
  {
    value: 'GMT-4',
    label: 'GMT−4: Atlantic Standard Time (AST)'
  },
  {
    value: 'GMT+10',
    label: 'GMT+10: Chamorro Standard Time (ChT)'
  },
  {
    value: 'GMT+12',
    label: 'GMT+12: Wake Island Time Zone (WIT)'
  }
];
