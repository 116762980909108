import React from 'react';
import { AddIconProps } from '..';
const DeleteXIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1824 3.81727C20.6343 4.26917 20.6343 5.00185 20.1824 5.45376L5.45397 20.1822C5.00207 20.6341 4.26938 20.6341 3.81748 20.1822C3.36558 19.7303 3.36558 18.9976 3.81748 18.5457L18.5459 3.81727C18.9978 3.36536 19.7305 3.36536 20.1824 3.81727Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81762 3.81727C4.26952 3.36536 5.00221 3.36536 5.45411 3.81727L20.1825 18.5457C20.6344 18.9976 20.6344 19.7303 20.1825 20.1822C19.7306 20.6341 18.9979 20.6341 18.546 20.1822L3.81762 5.45376C3.36571 5.00185 3.36571 4.26917 3.81762 3.81727Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default DeleteXIcon;
