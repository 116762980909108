import React from 'react';
import AddIcon from './icons/AddIcon';
import ArrowForwardOutlineIcon from './icons/ArrowForwardOutlineIcon';
import ArrowLeftIcon from './icons/ArrowLeftIcon';
import ArrowRightIcon from './icons/ArrowRightIcon';
import BackIcon from './icons/BackIcon';
import BlockHourIcon from './icons/BlockHourIcon';
import BreakIcon from './icons/BreakIcon';
import CalendarOutlineIcon from './icons/CalendarOutlineIcon';
import CashDrawerIcon from './icons/CashDrawerIcon';
import CashDrawIcon from './icons/CashDrawIcon';
import CashIcon from './icons/CashIcon';
import CategoryIcon from './icons/CategoryIcon';
import ChangeIcon from './icons/ChangeIcon';
import CheckInIcon from './icons/CheckInIcon';
import CheckMaskIcon from './icons/CheckMaskIcon';
import ClockInIcon from './icons/ClockInIcon';
import ClosedTicketIcon from './icons/ClosedTicketIcon';
import CombineIcon from './icons/CombineIcon';
import CouponIcon from './icons/CouponIcon';
import CustomerIcon from './icons/CustomerIcon';
import CustomerPersonIcon from './icons/CustomerPersonIcon';
import DeleteIcon from './icons/DeleteIcon';
import DeleteXIcon from './icons/DeleteXIcon';
import DiscountGiveIcon from './icons/DiscountGiveIcon';
import DiscountIcon from './icons/DiscountIcon';
import DownIcon from './icons/DownIcon';
import EditIcon from './icons/EditIcon';
import EmailIcon from './icons/EmailIcon';
import EmployeeIcon from './icons/EmployeeIcon';
import HideIcon from './icons/HideIcon';
import NextIcon from './icons/NextIcon';
import PrintIcon from './icons/PrintIcon';
import ProductIcon from './icons/ProductIcon';
import QRScanIcon from './icons/QRScanIcon';
import RefundIcon from './icons/RefundIcon';
import RepeatIcon from './icons/RepeatIcon';
import ReportIcon from './icons/ReportIcon';
import ShowIcon from './icons/ShowIcon';
import SkipIcon from './icons/SkipIcon';
import SMSIcon from './icons/SMSIcon';
import SplitIcon from './icons/SplitIcon';
import TicketIcon from './icons/TicketIcon';
import TipIcon from './icons/TipIcon';
import TipsIcon from './icons/TipsIcon';
import TurnIcon from './icons/TurnIcon';
import VoidIcon from './icons/VoidIcon';
import MenuDashboardIcon from './icons/MenuDashboardIcon';
import MenuAccountsIcon from './icons/MenuAccountsIcon';
import MenuCouponsIcon from './icons/MenuCouponsIcon';
import MenuSmsIcon from './icons/MenuSmsIcon';
import MenuEmailIcon from './icons/MenuEmailIcon';
import MenuLoyaltyRewardsIcon from './icons/MenuLoyaltyRewardsIcon';
import MenuSocialMediaIcon from './icons/MenuSocialMediaIcon';
import MenuGiftCardIcon from './icons/MenuGiftCardIcon';
import MenuVipIcon from './icons/MenuVipIcon';
import MenuCreditCardIcon from './icons/MenuCreditCardIcon';
import MenuPwIcon from './icons/MenuPwIcon';
import MenuLicensesIcon from './icons/MenuLicensesIcon';
import MenuAccountEditIcon from './icons/MenuAccountEditIcon';
import GiftCardIcon from './icons/GiftCardIcon';
import EditV2Icon from './icons/EditV2Icon';

const SVG_ICONS = {
  add: AddIcon,
  arrowForwardOutline: ArrowForwardOutlineIcon,
  back: BackIcon,
  blockHour: BlockHourIcon,
  break: BreakIcon,
  calendarOutline: CalendarOutlineIcon,
  cashDrawer: CashDrawerIcon,
  cashDraw: CashDrawIcon,
  cash: CashIcon,
  category: CategoryIcon,
  change: ChangeIcon,
  checkIn: CheckInIcon,
  checkMask: CheckMaskIcon,
  clockIn: ClockInIcon,
  closedTicket: ClosedTicketIcon,
  combine: CombineIcon,
  coupon: CouponIcon,
  customer: CustomerIcon,
  customerPerson: CustomerPersonIcon,
  delete: DeleteIcon,
  deleteX: DeleteXIcon,
  discountGive: DiscountGiveIcon,
  discount: DiscountIcon,
  down: DownIcon,
  edit: EditIcon,
  email: EmailIcon,
  employee: EmployeeIcon,
  hide: HideIcon,
  next: NextIcon,
  print: PrintIcon,
  product: ProductIcon,
  qRScan: QRScanIcon,
  refund: RefundIcon,
  repeat: RepeatIcon,
  report: ReportIcon,
  show: ShowIcon,
  skip: SkipIcon,
  sMS: SMSIcon,
  split: SplitIcon,
  ticket: TicketIcon,
  tip: TipIcon,
  tips: TipsIcon,
  turn: TurnIcon,
  void: VoidIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  menuDashboard: MenuDashboardIcon,
  menuAccounts: MenuAccountsIcon,
  menuCoupons: MenuCouponsIcon,
  menuSms: MenuSmsIcon,
  menuEmail: MenuEmailIcon,
  menuSocialMedia: MenuSocialMediaIcon,
  menuLoyaltyRewards: MenuLoyaltyRewardsIcon,
  menuGiftCard: MenuGiftCardIcon,
  menuVip: MenuVipIcon,
  menuCreditCard: MenuCreditCardIcon,
  menuPw: MenuPwIcon,
  menuLicenses: MenuLicensesIcon,
  menuAccountEdit: MenuAccountEditIcon,
  giftCardIcon: GiftCardIcon,
  editV2: EditV2Icon
};
export type IconType = keyof typeof SVG_ICONS;

type IIconCusProps = {
  type: IconType;
  active?: boolean;
};
export type AddIconProps = {
  active?: boolean;
};

const Icon: React.FC<IIconCusProps> = ({ type, active = false }) => {
  const SvgIcon = SVG_ICONS[type];
  return <SvgIcon active={active} />;
};

export default Icon;
