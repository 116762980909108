import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, DatePicker, Divider, Form, Input, InputRef, Row, Select, Space, Switch, Tag, message, Button as ButtonAnt, Radio, AutoComplete, } from 'antd';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import Box from 'components/Box';
import Text from 'components/Text';
import Button from 'components/Button';
import { CheckOutlined, CloseOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import accountApis from '../services/apis';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Dragger from 'antd/es/upload/Dragger';
import Table, { ColumnsType } from 'antd/es/table';
import { formatPhoneNumber } from 'utils/FunctionUtils';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

export const SalonCreateCampaignSmsPage = () => {
    const { Option } = Select;
    const navigate = useNavigate();
    const [formCreate] = Form.useForm();
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [lstCouponTemplate, setLstCouponTemplate] = useState<any>(null);
    const [lstGroup, setLstGroup] = useState<any>(null);
    const [lstCustomer, setLstCustomer] = useState<any[]>([]);
    const [typeCustomer, setTypeCustomer] = useState<any>(null);

    const selectedTypeCustomer = (e: any) => {
        setTypeCustomer(e.target.value);
        setLstCustomer([]);
        formCreate.setFieldsValue({
            campaignCustomerSelectGroup: ''
        });
    };

    const [isCoupon, SetIsCoupon] = useState<any>(false);
    const onChangeIsCoupon = (value: boolean) => {
        SetIsCoupon(value);
    };

    const responsive = {
        0: { items: 1 },
        600: { items: 2 },
        1024: { items: 3 },
    };

    useEffect(() => {
        const initData = async () => {
            try {
                setPageLoading(true);
                if (id && !lstCouponTemplate) {
                    const response = await accountApis.getLstCouponTemplateByType('MARKETING');
                    if (response.status === 200) {
                        setLstCouponTemplate(response.data.data);
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }
                    const response1 = await accountApis.getLstGroupByShopId(id);
                    if (response1.status === 200) {
                        setLstGroup(response1.data.data);
                    } else {
                        message.error({ content: response1.data.message, duration: 2 });
                    }
                    formCreate.setFieldsValue({
                        description: '* For your next visit only \n* Valid from Monday - Thursday \n* Can not combine with any other offers'
                    });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                setPageLoading(false);
            }

        };
        initData();
    }, []);

    const onSubmit = async (values: any) => {
        try {
            if (lstCustomer.length == 0) {
                message.error({ content: 'Please choose customer', duration: 2 });
                return;
            }
            setPageLoading(true);
            const formattedDate = values.sendTime.format('MM-DD-YYYY HH:mm:ss');
            const customers = lstCustomer.map((customer) => ({
                customerId: customer.id,
                customerName: customer.name,
                customerPhone: customer.phone,
            }));

            let response;
            if (isCoupon == false) {
                response = await accountApis.createSmsCampaign(id, values.title, values.message, formattedDate, null, customers);
            } else {
                if (templateCouponId == null) {
                    message.error({ content: 'Please choose image coupon', duration: 2 });
                    setPageLoading(false);
                    return;
                }
                const coupon = {
                    couponId: null,
                    discountType: discountType,
                    discountValue: values.discountValue,
                    description: values.description,
                    templateCouponId: templateCouponId,
                    title: values.couponTitle,
                    totalExpireDate: values.expiresDate
                };
                response = await accountApis.createSmsCampaign(id, values.title, values.message, formattedDate, coupon, customers);
            }
            if (response.status === 200) {
                message.success('Create successful');
                navigate(`/accounts/salon/${id}#MARKETING_TAB`);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            setPageLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const handleTagClick = (tagValue: string) => {
        const currentTextAreaValue = formCreate.getFieldValue('message') || '';
        const newTextAreaValue = currentTextAreaValue + `${tagValue}`;
        formCreate.setFieldsValue({ message: newTextAreaValue });
        setTextAreaValue(newTextAreaValue);
    };

    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };
    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };
    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value={'MONEY'}>Dollar</Option>
            <Option value={'PERCENT'}>Percent</Option>
        </Select>
    );

    const inputRef = useRef<InputRef>(null);
    const [expirItems, setExpirItems] = useState<any>(['30', '60', '90', '365']);
    const [newExpirDaysItems, setNewExpirDaysItems] = useState('');
    const onExpirDaysItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewExpirDaysItems(event.target.value);
    };
    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (!newExpirDaysItems) {
            return;
        }
        expirItems.unshift(newExpirDaysItems);
        setExpirItems([...expirItems]);
        setNewExpirDaysItems('');
    };

    const excelFileAccept = '.xls, .xlsx';
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: excelFileAccept
    };

    const columnsCustomerSelect: ColumnsType<any> = [
        {
            title: <p>NAME</p>,
            dataIndex: 'name',
            className: 'name',
            render: (name: string) => <a>{name}</a>,
        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
            className: 'phone',
            render: (phone: string) =>
                <span>{formatPhoneNumber(phone)}</span>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            className: 'email',
            render: (email: string) =>
                <span>{email}</span>,
        },
        {
            title: <p>ACTION</p>,
            render: (item: any) =>
                <Button className='btn-delete' icon="delete" onClick={() => removeCustomerByPhone(item.phone)}></Button>
        },
    ];

    const [templateCouponId, setTemplateCouponId] = useState<any>(null);
    const handAddTemplate = async (templateId: string) => {
        setTemplateCouponId(templateId);
    };

    const handleOnchangeSelectGroupCampaign = async () => {
        const currentValue = formCreate.getFieldValue('campaignCustomerSelectGroup') || '';
        if (currentValue == '') {
            setLstCustomer([]);
        } else {
            try {
                setPageLoading(true);
                const response = await accountApis.getLstCustomerByGroup(currentValue);
                if (response.status === 200) {
                    setLstCustomer(response.data.data);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    const handleBeforeUpload = (file: File) => {
        setLstCustomer([]);
        setPageLoading(true);
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>): void => {
            const data = e.target?.result;
            if (data) {
                const workbook = XLSX.read(data as string, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                const newLstCustomer = excelData.slice(1).map((row: any) => {
                    return {
                        name: row[0], // Assuming the name is in the second column (index 1)
                        phone: row[1], // Assuming the phone is in the third column (index 2)
                        email: row[2] == '' ? '--' : row[2], // Assuming the point is in the fifth column (index 4)
                    };
                });
                setLstCustomer(newLstCustomer);
            }
        };
        reader.readAsBinaryString(file);
        setPageLoading(false);
        return false;
    };

    const removeCustomerByPhone = (phoneToRemove: string): void => {
        setLstCustomer((prevCustomers) =>
            prevCustomers.filter((customer) => customer.phone !== phoneToRemove)
        );
    };

    const addCustomerIfNotExists = (data: any, option: any): void => {
        const parts = option.label.split(' - ');
        const customer = {
            name: parts[0], // Assuming the name is in the label property of the option
            phone: parts[1], // Assuming the phone is in the value property of the option
            email: parts[2], // Assuming point is a property of the option, adjust accordingly
        };

        if (!lstCustomer.some((c) => c.phone === customer.phone)) {
            // Nếu số điện thoại chưa tồn tại, thêm customer vào mảng
            setLstCustomer((prevCustomers) => [...prevCustomers, customer]);
        } else {
            // Nếu số điện thoại đã tồn tại, có thể xử lý theo mong muốn của bạn, ví dụ: thông báo lỗi
            console.error(`Customer with phone ${customer.phone} already exists.`);
        }
    };

    const [optionsAutoComplete, setOptionsAutoComplete] = useState<{ value: string; label: string }[]>([]);

    const handleSearchAutoComplete = async (value: string) => {
        try {
            setOptionsAutoComplete([]);
            const response = await accountApis.filterCustomerByShopId(
                id, null, null, null, null, null, null, null, 1, 1000
            );

            if (response.status === 200) {
                const res = response.data.data.content.map((item: any) => ({
                    value: item.phone, // Assuming item has a property called 'value'
                    label: `${item.name} - ${item.phone} - ${item.email}`,
                }));
                setOptionsAutoComplete(res);
            } else {
                setOptionsAutoComplete([]);
            }
        } catch (error) {
            console.error('Error fetching customer data:', error);
            setOptionsAutoComplete([]);
        }
    };

    return (
        <CreateGiftCardPageStyled>
            <Form form={formCreate}
                layout="vertical"
                onFinish={onSubmit}>
                <Box className="d-between">
                    <Text variant="H5">Create SMS</Text>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate(`/accounts/salon/${id}#MARKETING_TAB`)}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formCreate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <ContentStyled style={{ background: 'white', minHeight: '300px' }}>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Box p={1.5}>
                                <Form.Item
                                    label={'Title'}
                                    name={'title'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Title is required',
                                        }
                                    ]}>
                                    <Input size='large' placeholder='Please enter...'></Input>
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                    label="Message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Message is required',
                                        }
                                    ]}>
                                    <TextArea placeholder='Enter message..'
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        {...(values: any) => ({
                                            value: values.message,
                                            dangerouslySetInnerHTML: { __html: values.message },
                                        })}
                                        showCount />

                                </Form.Item>
                                <Box mb={1}>
                                    <TagStyled onClick={() => handleTagClick('${customerName}')}>Customer name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${businessName}')}>Business name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${serviceName}')}>Service name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${linkCoupon}')}>Link coupon</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${birthday}')}>Birthday</TagStyled>
                                </Box>
                                <Form.Item
                                    name="sendTime" // Tên của trường trong form
                                    label='Send Time'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Send time is requied'
                                        }
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        size="large"
                                        showTime={true}
                                        inputReadOnly={true}
                                        defaultValue={dayjs()} />
                                </Form.Item>

                                <Box style={{ display: 'flex' }}>
                                    <Form.Item name="isCoupon">
                                        <Switch style={{ textAlign: 'right' }} title='Coupon'
                                            defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                            onChange={onChangeIsCoupon} />
                                    </Form.Item>
                                    <Text variant="H6" style={{ fontWeight: 'bold' }} ml={0.5}>Coupon</Text>
                                </Box>

                                {isCoupon == true && (
                                    <Fragment>
                                        <AliceCarousel
                                            responsive={responsive}
                                            disableDotsControls={true}>
                                            {lstCouponTemplate?.map((item: any) => (
                                                <Fragment key={item.id} >
                                                    <img src={item.urlImage}
                                                        onClick={() => handAddTemplate(item.id)}
                                                    />
                                                    {templateCouponId === item.id && (<CheclTemplateStyled variant="PRIMARY"><CheckOutlined /></CheclTemplateStyled>)}
                                                </Fragment>
                                            ))}
                                        </AliceCarousel>
                                        <Row>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingRight: '5px' }}>
                                                <Form.Item
                                                    name={'couponTitle'}
                                                    label={'Title'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Coupon title is requied'
                                                        }
                                                    ]}>
                                                    <TextArea placeholder='Enter Title..'
                                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                                        showCount />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '5px' }}>
                                                <Form.Item
                                                    name={'description'}
                                                    label={'Text Coupon'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Coupon description is requied'
                                                        }
                                                    ]}>
                                                    <TextArea placeholder='Enter Title..'
                                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                                        showCount />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingRight: '5px' }}>
                                                <Form.Item
                                                    name="discountValue"
                                                    label="Promotion"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} placeholder='0' type='number' size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '5px' }}>
                                                <Form.Item
                                                    name="expiresDate"
                                                    label="Expires Date (Choose days)" rules={[
                                                        {
                                                            required: true,
                                                            message: 'Expires Date is requied'
                                                        }
                                                    ]}>
                                                    <Select
                                                        placeholder="Select Days" size="large"
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider style={{ margin: '8px 0' }} />
                                                                <Space style={{ padding: '0 8px 4px', textAlign: 'center' }}>
                                                                    <Input
                                                                        size="large"
                                                                        placeholder="Please enter item"
                                                                        ref={inputRef}
                                                                        value={newExpirDaysItems}
                                                                        onChange={onExpirDaysItems}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    <ButtonAnt className='btn-info' type="text" size="large" onClick={addItem}>
                                                                        <i className="fas fa-plus mr-2"></i>Add item
                                                                    </ButtonAnt>
                                                                </Space>
                                                            </>
                                                        )}>
                                                        <Select.Option key={''} value={''}>{''}</Select.Option>
                                                        {expirItems?.map((item: any) => (
                                                            <Select.Option key={item} value={item} label={item}>{item}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                )}
                            </Box>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Box p={1.5}>
                                <Text variant="H5">Group’s Campaign</Text>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Choose customer!'
                                        }
                                    ]}
                                >
                                    <Radio.Group style={{ width: '100%' }} onChange={selectedTypeCustomer}>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                <Radio value="uploadFile">Upload File</Radio>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                <Radio value="selectGroupCampaign">Select Group’s Campaign</Radio>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                                <Radio value="customer">Custom</Radio>
                                            </Col>
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>

                                {/* Upload File */}
                                {typeCustomer === 'uploadFile' && (
                                    <Form.Item name='campaignCustomerSelectFile'>
                                        <Dragger {...uploadProps}
                                            beforeUpload={handleBeforeUpload}
                                            maxCount={1}
                                            onRemove={() => setLstCustomer([])}
                                            name='campaignCustomerSelectFile'>
                                            <p className='ant-upload-drag-icon'>
                                                <UploadOutlined />
                                            </p>
                                            <p className="ant-upload-text">Upload file customer</p>
                                        </Dragger>
                                    </Form.Item>
                                )}

                                {/* Select Group’s Campaign */}
                                {typeCustomer === 'selectGroupCampaign' && (
                                    <Form.Item name='campaignCustomerSelectGroup'>
                                        <Select placeholder='Select' size='large'
                                            onChange={handleOnchangeSelectGroupCampaign}
                                        >
                                            <Select.Option key='' value=''>{''}</Select.Option>
                                            {lstGroup?.map((item: any) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.groupName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}

                                {/* Custom */}
                                {typeCustomer === 'customer' && (
                                    <Form.Item name='campaignCustomerSelectCustomer'>
                                        <AutoComplete
                                            size='large'
                                            onSearch={handleSearchAutoComplete}
                                            placeholder="Customer Phone/ Email"
                                            options={optionsAutoComplete}
                                            onSelect={addCustomerIfNotExists}
                                        />

                                    </Form.Item>
                                )}

                                <Table
                                    columns={columnsCustomerSelect}
                                    dataSource={lstCustomer}
                                    rowKey="id"
                                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
                                />
                            </Box>
                        </Col>
                    </Row>
                </ContentStyled>
            </Form>
        </CreateGiftCardPageStyled>
    );
};

const CreateGiftCardPageStyled = styled.div`
  padding: 1.5rem;

  && .btn-delete {
    background: transparent;
    border: none;
    padding: 8px;
  }

  && .btn-delete:hover {
    background: rgba(245, 118, 127, 1) !important;
    border: none !important;
  }
`;

const ContentStyled = styled.div`
  background: rgba(238, 238, 238, 1);
  margin-top: 1rem;
  
`;

const BoxTemplateStyled = styled(Box)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const TagStyled = styled(Tag)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    padding: 5px 13px;
    background: rgba(197, 221, 248, 1);
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    cursor: alias;
`;

const CheclTemplateStyled = styled(Button)`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    position: absolute;
    top: 1%;
    right: 15%;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 8px;
`;