import React from 'react';
import { AddIconProps } from '..';
const SplitIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_495_17549)">
        <path
          d="M7.79625 4.23377C7.92375 4.09877 8.11969 4.0833 8.29453 4.0833C8.63953 4.08471 8.985 4.08377 9.33047 4.0833C9.50297 4.0819 9.69516 4.10955 9.81375 4.24877C9.98203 4.42971 9.94453 4.69502 9.95016 4.92143C9.94734 9.71861 9.94969 14.5158 9.94922 19.313C9.94734 19.4667 9.92625 19.635 9.81375 19.7513C9.70734 19.8769 9.53578 19.913 9.37828 19.9139C9.03328 19.9172 8.68828 19.9111 8.34328 19.9158C8.16422 19.9139 7.96031 19.9191 7.82016 19.7883C7.68047 19.6744 7.64625 19.486 7.64813 19.3153C7.64719 17.2627 7.65234 15.2096 7.64578 13.1564C6.67547 13.1447 5.70516 13.1564 4.73484 13.1508C4.56938 13.1494 4.40344 13.1517 4.23844 13.1625C4.53047 13.5206 4.89422 13.8127 5.19797 14.1605C5.28422 14.2608 5.34469 14.3911 5.32969 14.5271C5.31938 14.7052 5.18859 14.8406 5.07047 14.9611C4.83422 15.1936 4.59984 15.4285 4.36734 15.6642C4.25063 15.781 4.11375 15.9033 3.93844 15.9052C3.77016 15.9211 3.62156 15.825 3.50859 15.7111C2.40656 14.6096 1.30547 13.5061 0.202031 12.406C0.099375 12.3052 0.03 12.1749 0 12.0342V11.9658C0.03 11.8252 0.099375 11.6944 0.202031 11.5936C1.30594 10.4939 2.40656 9.39049 3.50812 8.28893C3.61219 8.18815 3.73875 8.09486 3.89016 8.09486C4.0575 8.0808 4.20609 8.17549 4.31906 8.2894C4.57031 8.5383 4.81781 8.79143 5.07 9.03893C5.19891 9.16736 5.33906 9.32018 5.33109 9.51565C5.33016 9.75846 5.12297 9.91924 4.97062 10.08C4.72781 10.3341 4.45828 10.5628 4.23844 10.8375C4.40344 10.8478 4.56891 10.8506 4.73438 10.8492C5.70469 10.8436 6.67547 10.8558 7.64578 10.8431C7.65234 8.79049 7.64719 6.73736 7.64813 4.68471C7.64813 4.5244 7.67344 4.34908 7.79625 4.23377Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M14.4534 4.10149C14.7431 4.06259 15.0366 4.09352 15.3281 4.08462C15.5611 4.09165 15.8016 4.05696 16.0289 4.12212C16.2427 4.19384 16.3598 4.42118 16.3505 4.63821C16.3552 6.70633 16.3462 8.77493 16.3547 10.8435C17.137 10.8548 17.9203 10.8449 18.7031 10.8482C19.0556 10.844 19.4091 10.8595 19.7616 10.8374C19.463 10.4699 19.0866 10.1741 18.7814 9.81321C18.6534 9.66462 18.6309 9.4354 18.7364 9.26899C18.8456 9.10024 19.0036 8.97227 19.1419 8.82884C19.358 8.62305 19.5539 8.39618 19.7812 8.20259C19.9805 8.03102 20.2959 8.07462 20.467 8.26587C21.5789 9.37352 22.6856 10.4863 23.798 11.594C23.9002 11.6948 23.97 11.8246 24 11.9657V12.0341C23.9559 12.2957 23.7375 12.4635 23.5622 12.6402C22.5464 13.656 21.5297 14.6709 20.5158 15.6881C20.415 15.7841 20.302 15.8863 20.1567 15.9009C19.9875 15.9299 19.8225 15.8507 19.7053 15.7331C19.4747 15.5104 19.2539 15.2774 19.0237 15.0543C18.908 14.9362 18.7762 14.8246 18.7069 14.6713C18.6356 14.512 18.6689 14.3188 18.7814 14.1866C19.0866 13.8257 19.463 13.5299 19.7616 13.1624C19.4091 13.1404 19.0561 13.1559 18.7031 13.1516C17.9203 13.1549 17.137 13.1451 16.3542 13.1563C16.3477 15.2095 16.3528 17.2626 16.3519 19.3152C16.3537 19.4863 16.3195 19.6748 16.1794 19.7882C16.0402 19.9213 15.8353 19.9143 15.6562 19.9157C15.3272 19.9115 14.9986 19.9152 14.6695 19.9148C14.5078 19.9143 14.3288 19.8965 14.2092 19.7751C14.0803 19.6588 14.0508 19.4779 14.0508 19.3129C14.0527 14.4215 14.0489 9.53009 14.0527 4.63915C14.0391 4.39446 14.2003 4.13852 14.4534 4.10149Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_495_17549">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SplitIcon;
