import React from 'react';
import { AddIconProps } from '..';
const SkipIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_250_121631)">
        <path
          d="M5.25 18L11.25 12L5.25 6"
          stroke={active ? 'white' : '#1D2129'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.75 18L18.75 12L12.75 6"
          stroke={active ? 'white' : '#1D2129'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_250_121631">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SkipIcon;
