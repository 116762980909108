import React from 'react';
import { AddIconProps } from '..';
const EditV2Icon: React.FC<AddIconProps> = ({ active = false }) => {
  return (
    <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8335 1.3335H6.50016C3.16683 1.3335 1.8335 2.66683 1.8335 6.00016V10.0002C1.8335 13.3335 3.16683 14.6668 6.50016 14.6668H10.5002C13.8335 14.6668 15.1668 13.3335 15.1668 10.0002V8.66683" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1933 2.0135L5.93992 7.26684C5.73992 7.46684 5.53992 7.86017 5.49992 8.14684L5.21325 10.1535C5.10659 10.8802 5.61992 11.3868 6.34659 11.2868L8.35325 11.0002C8.63325 10.9602 9.02659 10.7602 9.23325 10.5602L14.4866 5.30684C15.3933 4.40017 15.8199 3.34684 14.4866 2.0135C13.1533 0.680168 12.0999 1.10684 11.1933 2.0135Z" stroke="white" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4399 2.7666C10.8866 4.35993 12.1333 5.6066 13.7333 6.05993" stroke="white" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditV2Icon;
