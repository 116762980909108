import React from 'react';
import styled from 'styled-components';
type IMailBoxSvgProps = {};
const MailBoxSvg: React.FC<IMailBoxSvgProps> = () => {
  return (
    <MailBoxSvgStyled>
      <svg
        width="138"
        height="138"
        viewBox="0 0 138 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3089_6165)">
          <rect
            x="23.0391"
            y="47.3516"
            width="41.5046"
            height="35.6988"
            fill="#E5E5E5"
          />
          <path
            d="M81.0216 0.476774C81.9508 0.476774 82.88 0.476776 83.8092 0.554382C83.8092 2.0289 83.7473 3.58102 83.7473 5.05554C87.4641 3.3482 91.1809 1.56326 94.8358 -0.22168C94.7739 3.19299 94.7739 6.60766 94.8358 10.0223C91.119 11.6521 87.5261 13.5146 83.8092 15.1443C83.7473 17.7053 83.7473 20.2664 83.7473 22.8274C83.7473 29.1911 83.6853 35.6324 83.8092 41.9961C86.9685 43.1602 88.8889 47.9718 87.2163 51.6968C85.6057 56.586 79.411 56.586 77.6764 51.8521C75.88 48.127 77.8003 43.1602 81.0216 41.9961C81.0835 35.6324 81.0216 29.2687 81.0835 22.8274C81.0216 15.3772 81.0835 7.92697 81.0216 0.476774Z"
            fill="#1D2129"
          />
          <path
            d="M33.6329 33.3821C36.6063 26.4752 42.801 22.4397 48.9338 22.7501L49.058 22.9054C49.5018 23.0608 49.6775 22.9055 49.6775 22.9055C51.8456 23.2935 51.4992 23.3165 52.8984 23.8366C58.5356 25.9319 63.1197 32.0628 64.1728 39.3578C64.7923 43.859 64.8543 48.5153 64.7923 53.1717H64.6065C64.3587 51.154 64.6065 48.981 64.4826 46.9632C53.456 47.0408 42.4913 46.9632 31.4647 46.9632C31.4028 42.3845 31.5886 37.4953 33.5709 33.5373C33.5709 33.5373 33.5709 33.4597 33.6329 33.3821Z"
            fill="#1D2129"
          />
          <path
            d="M48.9348 22.7374C59.5897 22.2718 70.3685 22.9837 81.0854 22.9061C81.0854 29.2698 81.1474 35.6335 81.0235 42.0748C77.8022 43.2389 75.8818 48.2057 77.6783 51.9308C79.4128 56.6648 85.6076 56.5872 87.2182 51.7756C88.9527 48.0505 86.9704 43.2389 83.8111 42.0748C83.6872 35.7111 83.8111 29.3474 83.7491 22.9061C90.8731 22.9061 97.935 22.8285 105.059 22.9061C114.041 22.9061 121.537 33.5382 120.979 44.6358C120.979 59.769 120.979 74.9023 120.979 90.1131C107.413 89.9579 94.2804 90.0352 80.3423 90.0352C61.4484 90.0352 46.2713 90.0352 27.0055 90.0355C22.9789 90.0355 18.8904 90.0355 14.8638 90.0355C14.8638 92.3636 14.8638 94.6142 14.8638 96.9424C13.0054 96.8648 11.147 96.8648 9.28857 96.9424C9.41246 92.286 9.04078 87.5521 9.10273 82.8957C13.8107 82.9733 18.4568 83.1285 23.1647 83.2837C36.979 83.4389 50.7932 83.2061 64.6074 83.2837C64.6074 73.2725 64.5455 63.2613 64.6074 53.2501C64.6694 48.6714 64.6074 44.015 63.988 39.4362C62.9349 32.1412 58.3508 26.0104 52.7136 23.915C51.4746 23.6046 50.7932 23.2943 48.9348 23.2943C48.9348 23.0819 48.9348 22.8926 48.9348 22.7374Z"
            fill="#FF8890"
          />
          <path
            d="M33.5098 33.538C33.5098 33.4604 33.5717 33.3828 33.5717 33.3828C33.5717 33.4604 33.5717 33.538 33.5098 33.538Z"
            fill="#FF8890"
          />
          <path
            d="M23.3496 47.584C24.1549 48.1272 25.0221 48.6705 25.7655 49.2913C32.8275 55.8102 40.1373 61.8635 47.1992 68.46C53.0223 63.4932 58.5975 57.9832 64.5445 53.2492H64.7303C64.6684 63.2604 64.7303 73.2716 64.7303 83.2828C50.9161 83.2052 37.1018 83.438 23.2876 83.2828C23.2876 71.3315 23.1018 59.4577 23.3496 47.584Z"
            fill="#FFE6E7"
          />
          <path
            d="M75.5078 89.9573C79.4105 90.0349 81.0835 89.9573 84.9862 89.9573C86.3856 89.9566 88.0836 89.9577 89.322 89.9574C89.384 105.556 89.1982 122.629 89.384 138.228C84.738 137.996 80.1539 138.073 75.5078 138.151C75.5078 122.164 75.5698 106.022 75.5078 89.9573Z"
            fill="#767676"
          />
        </g>
        <defs>
          <clipPath id="clip0_3089_6165">
            <rect width="138" height="138" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </MailBoxSvgStyled>
  );
};

export default MailBoxSvg;
const MailBoxSvgStyled = styled.div``;
