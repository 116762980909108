import React from 'react';
import { AddIconProps } from '..';

const MenuLicensesIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={18} height={20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6923 20H5.30769C1.93846 20 0 18.0465 0 14.6512V5.34884C0 1.95349 1.93846 0 5.30769 0H12.6923C16.0615 0 18 1.95349 18 5.34884V14.6512C18 18.0465 16.0615 20 12.6923 20ZM5.30769 1.39535C2.66769 1.39535 1.38462 2.68837 1.38462 5.34884V14.6512C1.38462 17.3116 2.66769 18.6047 5.30769 18.6047H12.6923C15.3323 18.6047 16.6154 17.3116 16.6154 14.6512V5.34884C16.6154 2.68837 15.3323 1.39535 12.6923 1.39535H5.30769Z" fill="#566A7F" />
                <path d="M15.0001 7.44196H13.1539C11.7509 7.44196 10.6155 6.29778 10.6155 4.88382V3.02336C10.6155 2.64196 10.9293 2.32568 11.3078 2.32568C11.6862 2.32568 12.0001 2.64196 12.0001 3.02336V4.88382C12.0001 5.52568 12.517 6.04661 13.1539 6.04661H15.0001C15.3786 6.04661 15.6924 6.36289 15.6924 6.74429C15.6924 7.12568 15.3786 7.44196 15.0001 7.44196Z" fill="#566A7F" />
                <path d="M8.99997 11.6278H5.30766C4.9292 11.6278 4.61536 11.3115 4.61536 10.9301C4.61536 10.5487 4.9292 10.2324 5.30766 10.2324H8.99997C9.37843 10.2324 9.69228 10.5487 9.69228 10.9301C9.69228 11.3115 9.37843 11.6278 8.99997 11.6278Z" fill="#566A7F" />
                <path d="M12.6923 15.349H5.30766C4.9292 15.349 4.61536 15.0327 4.61536 14.6513C4.61536 14.2699 4.9292 13.9536 5.30766 13.9536H12.6923C13.0707 13.9536 13.3846 14.2699 13.3846 14.6513C13.3846 15.0327 13.0707 15.349 12.6923 15.349Z" fill="#566A7F" />
            </svg>
        </span>
    );
};
export default MenuLicensesIcon;
