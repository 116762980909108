import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ILoginResData } from './auth/services/types/login';

type PrivateRouteProps = { children: React.ReactNode | null };
export const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
    const userLogin = useSelector((state: RootState) => state.auth) as ILoginResData;
    return userLogin && userLogin.accessToken ? (children ? <>{children}</> : <Navigate to="/accounts" />) : <Navigate to="/login" />;
};
