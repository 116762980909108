import React from 'react';
import { AddIconProps } from '..';
const CouponIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_546_39168)">
        <path
          d="M1.41281 4.23748C2.82281 4.23795 4.23234 4.23701 5.64234 4.23795C5.64516 4.47982 5.63766 4.7217 5.64422 4.96357C5.65031 5.25889 5.86312 5.53264 6.14578 5.61607C6.50531 5.73889 6.92719 5.50639 7.02891 5.14357C7.09594 4.84732 7.04484 4.53889 7.06078 4.23795C12.2362 4.23654 17.4117 4.23795 22.5867 4.23748C22.5933 4.49764 22.5773 4.75826 22.5942 5.01795C22.6238 5.34889 22.9177 5.62685 23.2481 5.64514C23.4984 5.65779 23.7492 5.64607 24 5.65029V18.3501C23.7492 18.3534 23.498 18.3426 23.2477 18.3544C22.9177 18.3731 22.6228 18.6511 22.5942 18.982C22.5773 19.2417 22.5933 19.5023 22.5867 19.7625C17.4117 19.7615 12.2362 19.7634 7.06078 19.762C7.04109 19.4484 7.10625 19.1231 7.01578 18.8179C6.89437 18.4617 6.46078 18.2531 6.10922 18.3961C5.84344 18.4912 5.64937 18.7551 5.64422 19.0383C5.63812 19.2792 5.64469 19.5201 5.64281 19.7615C4.23281 19.7639 2.82281 19.7615 1.41281 19.7625C1.40672 19.5023 1.42313 19.2422 1.40531 18.9825C1.37625 18.6511 1.08281 18.3703 0.750469 18.3544C0.500625 18.3426 0.250313 18.3539 0 18.3497V5.64982C0.262031 5.64467 0.524531 5.66107 0.785625 5.64185C1.10344 5.60998 1.37531 5.33717 1.40531 5.01935C1.42266 4.7592 1.40672 4.4981 1.41281 4.23748ZM2.69766 5.6442C2.48484 6.23811 2.00063 6.72326 1.40672 6.93514C1.40578 10.312 1.40625 13.6884 1.40625 17.0648C2.00156 17.2753 2.48484 17.7619 2.69766 18.3553C3.24984 18.3572 3.80203 18.3553 4.35422 18.3567C4.53937 17.8481 4.91766 17.4112 5.40422 17.1708C5.99906 16.8651 6.74109 16.8726 7.33125 17.1867C7.80328 17.43 8.16609 17.8608 8.34984 18.3567C12.667 18.3548 16.9847 18.3576 21.3023 18.3553C21.5138 17.7609 21.9998 17.2781 22.5933 17.0648C22.5938 13.6884 22.5938 10.312 22.5933 6.93514C22.0008 6.72092 21.5133 6.23998 21.3028 5.64467C16.9852 5.64232 12.667 5.64467 8.34891 5.64326C8.17781 6.10639 7.85109 6.51279 7.42313 6.7617C6.84984 7.10482 6.10969 7.14889 5.50078 6.8742C4.96969 6.64451 4.55156 6.18514 4.35375 5.64326C3.80156 5.64467 3.24984 5.64279 2.69766 5.6442Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M11.5523 7.10902C12.0909 6.99277 12.6754 7.09355 13.1381 7.39543C13.6856 7.74184 14.0564 8.35121 14.1056 8.99762C14.1572 9.56762 13.9612 10.154 13.5787 10.5796C13.154 11.0643 12.4987 11.3325 11.8561 11.2842C11.2439 11.2467 10.6594 10.9223 10.2979 10.4273C9.92623 9.93137 9.79638 9.26668 9.94826 8.66621C10.1306 7.8984 10.7803 7.27121 11.5523 7.10902ZM11.8209 8.49137C11.4459 8.5809 11.197 9.01168 11.3231 9.38012C11.4267 9.75324 11.8711 9.98574 12.2344 9.8423C12.592 9.72652 12.8039 9.29434 12.667 8.9423C12.5564 8.60387 12.164 8.39059 11.8209 8.49137Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M10.0889 15.7388C12.9127 12.9151 15.7364 10.0913 18.5602 7.26807C18.8921 7.59807 19.2225 7.92994 19.5535 8.26088C16.7325 11.0874 13.9074 13.9098 11.0827 16.7331C10.7513 16.4017 10.4199 16.0707 10.0889 15.7388Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M5.64889 8.47262C6.11717 8.47262 6.58592 8.47215 7.0542 8.47262C7.05514 8.94137 7.05514 9.41012 7.0542 9.87887C6.58733 9.88075 6.12045 9.87512 5.65358 9.88215C5.64186 9.41246 5.65077 8.94278 5.64889 8.47262Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M5.64703 11.2991C6.11672 11.2949 6.58641 11.2954 7.0561 11.2991C7.05422 11.766 7.05375 12.2329 7.05656 12.7002C6.58688 12.7059 6.11719 12.7035 5.6475 12.7012C5.64891 12.2338 5.64938 11.7665 5.64703 11.2991Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M17.4066 12.7243C17.9752 12.6572 18.5695 12.8321 19.0059 13.2047C19.5009 13.6154 19.7897 14.2618 19.7602 14.9049C19.7409 15.5241 19.4283 16.1232 18.937 16.4986C18.4903 16.8483 17.8978 17.0002 17.3381 16.9149C16.7053 16.8249 16.1278 16.4269 15.8133 15.8719C15.5133 15.3558 15.4528 14.7099 15.6459 14.146C15.8967 13.3866 16.6102 12.8072 17.4066 12.7243ZM17.5416 14.1235C17.1619 14.1755 16.8727 14.5716 16.9514 14.9489C17.0133 15.3352 17.4342 15.6174 17.8144 15.5114C18.1763 15.4318 18.4294 15.0352 18.3399 14.6738C18.2691 14.3194 17.8983 14.0616 17.5416 14.1235Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M5.64514 14.1262C6.11342 14.1121 6.58217 14.1271 7.05092 14.1187C7.05936 14.5879 7.0528 15.0576 7.0542 15.5268C6.58592 15.5277 6.11717 15.5272 5.64889 15.5272C5.64561 15.0599 5.65264 14.593 5.64514 14.1262Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_546_39168">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CouponIcon;
