import React from 'react';
import { AddIconProps } from '..';
const EditIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_316_30724)">
        <path
          d="M21.1607 0H21.3534C22.0265 0.031875 22.6917 0.304219 23.1703 0.782813C23.6751 1.26656 23.9695 1.94953 23.9999 2.64609V2.83781C23.9765 3.35672 23.8162 3.87094 23.5185 4.29844C23.3254 4.58484 23.0662 4.81594 22.8257 5.06109C19.5065 8.38125 16.1854 11.7005 12.8662 15.0211C12.719 15.1856 12.5034 15.2545 12.292 15.2887C11.1571 15.5123 10.0237 15.7444 8.88838 15.9652C8.40792 16.0622 7.93542 15.5878 8.03339 15.1073C8.26307 13.9322 8.5026 12.758 8.73557 11.5833C8.7651 11.4122 8.84854 11.2533 8.97464 11.1337C12.374 7.73578 15.7729 4.33687 19.1718 0.938906C19.6748 0.376406 20.4042 0.0248437 21.1607 0ZM20.3742 1.85766C20.0756 2.15203 19.7821 2.45062 19.484 2.745C20.0732 3.33609 20.6596 3.93 21.2564 4.51312C21.562 4.19766 21.8817 3.89531 22.1845 3.57609C22.5449 3.18328 22.6012 2.55328 22.319 2.10188C22.1057 1.74141 21.7012 1.50516 21.2821 1.5C20.9474 1.48922 20.6118 1.62234 20.3742 1.85766ZM10.1751 12.0614C10.0157 12.7945 9.87932 13.5333 9.72932 14.2687C10.4123 14.1436 11.0901 13.9917 11.7726 13.8619C11.849 13.8436 11.9399 13.8431 11.9929 13.7752C14.7239 11.0409 17.4581 8.31094 20.1895 5.57719C19.6017 4.98656 19.0124 4.39734 18.4223 3.80859C15.674 6.56016 12.9178 9.30469 10.1751 12.0614Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M0.720469 4.89612C1.23563 4.32659 1.99734 3.9919 2.76516 3.99752C5.57859 3.99612 8.3925 3.99752 11.2059 3.99705C11.4248 3.98955 11.648 4.07159 11.7952 4.23659C11.9991 4.44705 12.0577 4.78034 11.9372 5.04705C11.8209 5.3133 11.5425 5.50268 11.25 5.49659C8.42109 5.49799 5.59219 5.49518 2.76328 5.49799C2.24859 5.48674 1.75266 5.8294 1.57875 6.31315C1.48734 6.54096 1.49906 6.79034 1.5 7.0308C1.50047 11.7661 1.49906 16.5014 1.50047 21.2372C1.47937 21.8953 2.05734 22.4963 2.71688 22.4953C7.49953 22.4991 12.2822 22.4953 17.0648 22.4977C17.2856 22.5028 17.513 22.4944 17.7192 22.4053C18.1856 22.2221 18.5095 21.7374 18.4992 21.2358C18.5006 18.5016 18.4997 15.7674 18.4997 13.0331C18.5016 12.8222 18.4777 12.5953 18.5841 12.4031C18.7247 12.1228 19.0528 11.9546 19.3627 12.0066C19.7184 12.0539 20.0058 12.39 19.9992 12.7491C20.0011 15.4988 19.9992 18.2489 20.0002 20.9986C20.0508 21.7969 19.7728 22.6177 19.1995 23.1835C18.7289 23.6658 18.0759 23.9475 17.407 24H2.61234C1.97438 23.9546 1.34813 23.7014 0.880781 23.2603C0.36 22.7841 0.046875 22.1011 0 21.3989V6.58924C0.0459375 5.96627 0.294375 5.35596 0.720469 4.89612Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_316_30724">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
