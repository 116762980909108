import type { MenuProps } from 'antd';
import { Col, Dropdown, Layout, Menu, Row, theme } from 'antd';
import Icon from 'components/Icon';
import { ILoginResData } from 'features/auth/services/types/login';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import styled from 'styled-components';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
const { Header, Content, Footer, Sider } = Layout;
import authActions from 'features/auth/services/actions';
import { useAppDispatch } from 'store/hooks';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items: MenuItem[] = [
  getItem('Dashboard', '0', <Icon type='menuDashboard' />),
  getItem('Accounts', '1', <Icon type='menuAccounts' />),
  { type: 'divider' },

  getItem('PROMOTION', 'label', null),
  getItem('Coupons', '2', <Icon type='menuCoupons' />),
  getItem('SMS', '3', <Icon type='menuSms' />),
  getItem('Email', '4', <Icon type='menuEmail' />),
  getItem('Social Media', '5', <Icon type='menuSocialMedia' />),
  getItem('Loyalty Rewards', '6', <Icon type='menuLoyaltyRewards' />),
  getItem('Gift Card', '7', <Icon type='menuGiftCard' />),
  getItem('Vip', '8', <Icon type='menuVip' />),
  { type: 'divider' },

  getItem('PAYMENTS', 'label', null),
  getItem('Credit Card', '9', <Icon type='menuCreditCard' />),
  { type: 'divider' },

  getItem('SYSTEM', 'label', null),
  getItem('Password', '10', <Icon type='menuPw' />),
  getItem('Licenses', '11', <Icon type='menuLicenses' />),
  getItem('Acount User', '12', <Icon type='menuAccountEdit' />),
];

const PrivateLayout: React.FC = () => {

  const [collapsed, setCollapsed] = useState(false);
  const userLogin = useSelector((state: RootState) => state.auth) as ILoginResData;

  const [itemMenuSelect, setItemMenuSelect] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (!itemMenuSelect) {
      if (location.pathname.includes('accounts')) {
        setItemMenuSelect('1');
      } else if (location.pathname.includes('coupons')) {
        setItemMenuSelect('2');
      } else if (location.pathname.includes('gift-card')) {
        setItemMenuSelect('7');
      }
    }
  }, [location.pathname, itemMenuSelect]);

  const handleMenuItemClick = (key: React.Key) => {
    setItemMenuSelect(key.toString());
    switch (key) {
      case '0':
        break;
      case '1':
        navigate('/accounts');
        break;
      case '2':
        navigate('/coupons');
        break;
      case '7':
        navigate('/gift-card');
        break;
      default:
    }
  };

  const items1 = [
    {
      label: userLogin.adminAccountDto?.email,
      key: '0',
      onClick: () => handleItemClick(items1[0]),
    },
    {
      label: 'Logout',
      key: '1',
      icon: <LogoutOutlined />,
      onClick: () => handleItemClick(items1[1]),
    }
  ];

  const handleItemClick = async (item: any) => {
    if (item.key === '1') {
      const result = await Swal.fire({
        title: 'Confirm the end of the session',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Logout',
        confirmButtonColor: '#d42b3c',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        dispatch(authActions.afterLogout.success(null));
      }
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <MainHeaderStyled>
        <Row>
          <HeaderColStyled>
            <img style={{ width: '100px' }} src="/images/logo_w.png" alt="Logo" />
          </HeaderColStyled>
          <Col flex={'auto'} />

          <HeaderColStyled>
            <HeaderNotificationStyled>
              <img src="/images/notification.png" alt="Notification" />
              <NumberNotificationStyled>
                <span>4</span>
              </NumberNotificationStyled>
            </HeaderNotificationStyled>
          </HeaderColStyled>

          <HeaderColStyled>
            <HeaderAccountStyled>
              <Dropdown menu={{ items: items1 }}>
                <span>
                  <UserOutlined style={{ fontSize: '150%' }} /><DownOutlined style={{ fontSize: '80%' }} />
                </span>
              </Dropdown>
            </HeaderAccountStyled>
          </HeaderColStyled>
        </Row>
      </MainHeaderStyled>
      <Content style={{ display: 'flex' }}>
        <Layout style={{ flexGrow: 1 }}>
          <Sider
            style={{
              padding: 0,
              background: colorBgContainer,
              position: 'relative',
            }}
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapse}
            trigger={null}
          >
            <div className="demo-logo-vertical" />
            <IconCollapseSideBar onClick={toggleCollapse}>
              <Icon type={collapsed ? 'arrowRight' : 'arrowLeft'} />
            </IconCollapseSideBar>
            <CustomMenu
              style={{ padding: '0.4rem', background: colorBgContainer }}
              theme="light"
              selectedKeys={[itemMenuSelect]}
              mode="inline"
              items={items.filter(i => !collapsed || i?.key !== 'label')}
              onClick={({ key }) => handleMenuItemClick(key)}
            />
          </Sider>
          <MainLayoutStyled>
            <MainContentStyled>
              <Outlet />
            </MainContentStyled>
            <Footer style={{ textAlign: 'center' }}>
              Fozito ©2023 Created by WallkaTech
            </Footer>
          </MainLayoutStyled>
        </Layout>
      </Content>
    </Layout>
  );
};

export default memo(PrivateLayout);

const MainHeaderStyled = styled(Header)`
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderColStyled = styled(Col)`
  display: flex;
  align-items: center;
`;

const HeaderNotificationStyled = styled(Content)`
  position: relative;
    && img {
      vertical-align: middle;
    }
`;

const HeaderAccountStyled = styled(Content)`
  margin-left: 20px;
  border-radius: 67px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
    && span {
      color: rgba(255, 136, 144, 1);
      position: relative;
      top: -30%
    }
`;

const NumberNotificationStyled = styled(Content)`
  position: absolute;
  top: 10px;
  right: -8px;
  width: 18px;
  height: 18px;
  background: rgba(255, 136, 144, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
    && span {
      color: white;
      font-size: 14px;
    }
`;

const MainLayoutStyled = styled(Layout)`
  
`;

const MainContentStyled = styled(Content)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const IconCollapseSideBar = styled(Content)`
  position: absolute;
  top: 0.75rem;
  right: -1.25rem;
  border-radius: 50%;
`;

const CustomMenu = styled(Menu)`
    && .ant-menu-item {
      height: 40px;
      line-height: 45px;
    }

    && .ant-menu-item-selected {
        background-color: rgba(255, 229, 231, 1);
        color: rgba(255, 136, 144, 1);
    }

    && .ant-menu-item-selected svg path {
      stroke: rgba(255, 136, 144, 1);
      fill: rgba(255, 136, 144, 1);
    }

    && .ant-menu-item-divider {
      border-color: rgba(218, 218, 218, 1);
      margin-top: 15px;
    }

    && .ant-menu-item-only-child {
      padding-left: 0px !important;
      pointer-events: none; /* Chặn các sự kiện click và hover */
      user-select: none; /* Chặn việc chọn nội dung bằng chuột */
    }
`;
