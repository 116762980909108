import React from 'react';
import { AddIconProps } from '..';
const ShowIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="12" r="4.25" stroke={active ? 'white' : '#1D2129'} strokeWidth="1.5" />
      <path
        d="M14 19C8.78781 19 4.90393 14.9573 3.31593 12.516C3.11267 12.2035 3.12365 11.8027 3.34031 11.4993C5.08285 9.05967 9.14996 5 14 5C18.8787 5 22.6717 9.10774 24.223 11.5424C24.4027 11.8243 24.4129 12.181 24.2468 12.4711C22.8515 14.9074 19.2443 19 14 19Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ShowIcon;
