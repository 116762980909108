import React from 'react';
import { AddIconProps } from '..';

const ArrowRightIcon: React.FC<AddIconProps> = ({ active = false }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5ZM10.625 14.0625L17.1125 14.0625L14.9625 11.9125C14.6 11.55 14.6 10.95 14.9625 10.5875C15.15 10.4 15.3875 10.3125 15.625 10.3125C15.8625 10.3125 16.1 10.4 16.2875 10.5875L20.0375 14.3375C20.4 14.7 20.4 15.3 20.0375 15.6625L16.2875 19.4125C15.925 19.775 15.325 19.775 14.9625 19.4125C14.6 19.05 14.6 18.45 14.9625 18.0875L17.1125 15.9375L10.625 15.9375C10.1125 15.9375 9.6875 15.5125 9.6875 15C9.6875 14.4875 10.1125 14.0625 10.625 14.0625Z"
        fill={active ? 'white' : '#8E97A8'}
      />
    </svg>
  );
};

export default ArrowRightIcon;
