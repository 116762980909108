import PrivateLayout from 'components/layout/PrivateLayout';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './NotFound';
import SignInPage from './auth/pages/SignIn';
import AccountPage from './account/pages';
import { PrivateRoute } from './PrivateRoute';
import CouponsPage from './coupons/pages';
import CreateCouponPage from './coupons/pages/createCoupon';
import { DetailCouponPage } from './coupons/pages/detailCoupon';
import { GiftCardPage } from './giftCard/pages';
import { SalonSettingPage } from './account/pages/salonSetting';
import { SalonCreateGiftCardPage } from './account/pages/salonCreateGiftCard';
import { SalonCreateCampaignSmsPage } from './account/pages/salonCreateCampaignSms';
import { SalonUpdateCampaignSmsPage } from './account/pages/salonUpdateCampaignSms';

const RootScreens = () => {
  return (
    <React.Fragment>
      <Routes>
      <Route path='/' element={<PrivateRoute>{null}</PrivateRoute>} />
        <Route path='/login' element={<SignInPage />} />

        <Route element={<PrivateLayout />}>
          <Route path='accounts' element={<PrivateRoute> <AccountPage /> </PrivateRoute>} />
          <Route path='accounts/salon/:id' element={<PrivateRoute> <SalonSettingPage /> </PrivateRoute>} />
          <Route path='accounts/salon/campaign-sms/:id/create' element={<PrivateRoute> <SalonCreateCampaignSmsPage /> </PrivateRoute>} />
          <Route path='accounts/salon/gift-card/:id/create' element={<PrivateRoute> <SalonCreateGiftCardPage /> </PrivateRoute>} />
          <Route path='accounts/salon/gift-card/:id/update/:campaignId' element={<PrivateRoute> <SalonUpdateCampaignSmsPage /> </PrivateRoute>} />
          <Route path='coupons' element={<PrivateRoute> <CouponsPage /> </PrivateRoute>} />
          <Route path='coupons/create' element={<PrivateRoute> <CreateCouponPage /> </PrivateRoute>} />
          <Route path='coupons/detail/:id' element={<PrivateRoute> <DetailCouponPage /> </PrivateRoute>} />
          <Route path='gift-card' element={<PrivateRoute> <GiftCardPage /> </PrivateRoute>} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
};

export default RootScreens;
