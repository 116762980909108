import React, { CSSProperties } from 'react';
import boxShadowTheme from 'constant/boxShadows';
import colorTheme from 'constant/colors';
import styled from 'styled-components';

type SpacingProps = {
  m?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  mx?: number;
  my?: number;
  p?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  px?: number;
  py?: number;
};

type BorderProps = {
  b?: keyof typeof colorTheme;
  bt?: keyof typeof colorTheme;
  bb?: keyof typeof colorTheme;
  br?: keyof typeof colorTheme;
  bl?: keyof typeof colorTheme;
  borderStyle?: CSSProperties['borderStyle'];
};

type DimensionProps = {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  top?: CSSProperties['top'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
};

type VisualProps = {
  borderRadius?: CSSProperties['borderRadius'];
  bgColor?: keyof typeof colorTheme;
  opacity?: number;
};

type LayoutProps = {
  display?: CSSProperties['display'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  overflow?: CSSProperties['overflow'];
  position?: CSSProperties['position'];
  gap?: number;
};

type ShadowProps = {
  shadow?: keyof typeof boxShadowTheme;
};

interface IBoxOGProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}
type IBoxCusProps = IBoxOGProps &
  BorderProps &
  SpacingProps &
  LayoutProps &
  DimensionProps &
  ShadowProps &
  VisualProps;

const getSpacingValue = (key: number) => `${key}rem`;
const getColorValue = (key: keyof typeof colorTheme) => colorTheme[key];
const getShadowValue = (key: keyof typeof boxShadowTheme) =>
  boxShadowTheme[key];

const Box: React.FC<IBoxCusProps> = (props) => {
  return <BoxCusStyled {...props} />;
};

export default Box;

const BoxCusStyled = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'flexDirection',
      'bgColor',
      'borderRadius',
      'flexWrap',
      'justifyContent',
      'alignItems',
      'borderStyle',
      'shadow',
    ].includes(prop),
})<IBoxCusProps>`
  ${(props) => props.m && `margin: ${getSpacingValue(props.m)};`}
  ${(props) => props.mt && `margin-top: ${getSpacingValue(props.mt)};`}
  ${(props) => props.mb && `margin-bottom: ${getSpacingValue(props.mb)};`}
  ${(props) => props.ml && `margin-left: ${getSpacingValue(props.ml)};`}
  ${(props) => props.mr && `margin-right: ${getSpacingValue(props.mr)};`}
  ${(props) =>
    props.mx &&
    `
    margin-left: ${getSpacingValue(props.mx)};
    margin-right: ${getSpacingValue(props.mx)};
  `}
  ${(props) =>
    props.my &&
    `
    margin-top: ${getSpacingValue(props.my)};
    margin-bottom: ${getSpacingValue(props.my)};
  `}
  ${(props) => props.p && `padding: ${getSpacingValue(props.p)};`}
  ${(props) => props.pt && `padding-top: ${getSpacingValue(props.pt)};`}
  ${(props) => props.pb && `padding-bottom: ${getSpacingValue(props.pb)};`}
  ${(props) => props.pl && `padding-left: ${getSpacingValue(props.pl)};`}
  ${(props) => props.pr && `padding-right: ${getSpacingValue(props.pr)};`}
  ${(props) =>
    props.px &&
    `
    padding-left: ${getSpacingValue(props.px)};
    padding-right: ${getSpacingValue(props.px)};
  `}
  ${(props) =>
    props.py &&
    `
    padding-top: ${getSpacingValue(props.py)};
    padding-bottom: ${getSpacingValue(props.py)};
  `}
  ${(props) => props.b && `border: 1px solid ${getColorValue(props.b)};`}
  ${(props) => props.bt && `border-top: 1px solid ${getColorValue(props.bt)};`}
  ${(props) =>
    props.bb && `border-bottom: 1px solid ${getColorValue(props.bb)};`}
  ${(props) =>
    props.br && `border-right: 1px solid ${getColorValue(props.br)};`}
  ${(props) => props.bl && `border-left: 1px solid ${getColorValue(props.bl)};`}

  
  ${(props) => props.borderStyle && `border-style: ${props.borderStyle};`}

  ${(props) => props.display && `display: ${props.display};`}
  ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${(props) => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.position && `position: ${props.position};`}

  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${(props) =>
    props.bgColor && `background-color: ${colorTheme[props.bgColor]};`}

  ${(props) => props.width && `width: ${props.width};`}

  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.opacity && `opacity: ${props.opacity};`}
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.right && `right: ${props.right};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.shadow && `box-shadow: ${getShadowValue(props.shadow)};`}
  ${(props) => props.gap && `gap: ${getSpacingValue(props.gap)};`}
`;
