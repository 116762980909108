import React from 'react';
import { AddIconProps } from '..';
const CategoryIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5017 2.41675C16.3443 2.41675 15.3959 3.36409 15.3959 4.54671V8.38809C15.3959 9.57071 16.3443 10.518 17.5017 10.518H21.3109C22.4683 10.518 23.4168 9.57071 23.4168 8.38809V4.54671C23.4168 3.36409 22.4683 2.41675 21.3109 2.41675H17.5017ZM13.8959 4.54671C13.8959 2.54758 15.504 0.916748 17.5017 0.916748H21.3109C23.3086 0.916748 24.9168 2.54758 24.9168 4.54671V8.38809C24.9168 10.3872 23.3086 12.018 21.3109 12.018H17.5017C15.504 12.018 13.8959 10.3872 13.8959 8.38809V4.54671Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.916748 4.54671C0.916748 2.54758 2.52489 0.916748 4.52259 0.916748H8.3318C10.3295 0.916748 11.9376 2.54758 11.9376 4.54671V8.38809C11.9376 10.3872 10.3295 12.018 8.3318 12.018H4.52259C2.52489 12.018 0.916748 10.3872 0.916748 8.38809V4.54671ZM4.52259 2.41675C3.36518 2.41675 2.41675 3.36409 2.41675 4.54671V8.38809C2.41675 9.57071 3.36518 10.518 4.52259 10.518H8.3318C9.4892 10.518 10.4376 9.57071 10.4376 8.38809V4.54671C10.4376 3.36409 9.4892 2.41675 8.3318 2.41675H4.52259ZM0.916748 17.4453C0.916748 15.4462 2.52489 13.8154 4.52259 13.8154H8.3318C10.3295 13.8154 11.9376 15.4462 11.9376 17.4453V21.2867C11.9376 23.2846 10.3297 24.9167 8.3318 24.9167H4.52259C2.52471 24.9167 0.916748 23.2846 0.916748 21.2867V17.4453ZM4.52259 15.3154C3.36518 15.3154 2.41675 16.2627 2.41675 17.4453V21.2867C2.41675 22.4684 3.36537 23.4167 4.52259 23.4167H8.3318C9.48902 23.4167 10.4376 22.4684 10.4376 21.2867V17.4453C10.4376 16.2627 9.4892 15.3154 8.3318 15.3154H4.52259ZM17.5017 15.3154C16.3443 15.3154 15.3958 16.2627 15.3958 17.4453V21.2867C15.3958 22.4684 16.3445 23.4167 17.5017 23.4167H21.3109C22.4681 23.4167 23.4167 22.4684 23.4167 21.2867V17.4453C23.4167 16.2627 22.4683 15.3154 21.3109 15.3154H17.5017ZM13.8958 17.4453C13.8958 15.4462 15.504 13.8154 17.5017 13.8154H21.3109C23.3086 13.8154 24.9167 15.4462 24.9167 17.4453V21.2867C24.9167 23.2846 23.3088 24.9167 21.3109 24.9167H17.5017C15.5038 24.9167 13.8958 23.2846 13.8958 21.2867V17.4453Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default CategoryIcon;
