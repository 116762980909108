import React from 'react';
import { AddIconProps } from '..';
const CheckMaskIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1655 1.88086C22.4305 1.74443 22.7483 1.81372 22.9305 2.05014C21.9619 3.02372 20.9262 3.92872 20.0069 4.95157C17.9755 7.058 16.1747 9.38586 14.6276 11.8687C14.1605 12.7616 13.564 13.578 13.0912 14.4673C12.5426 15.3873 11.9755 16.2959 11.4119 17.2066C11.3262 17.408 11.0819 17.343 10.9112 17.3337C10.699 17.063 10.5547 16.7487 10.3847 16.4523C10.039 15.7273 9.65974 15.0151 9.39617 14.2551C9.15974 13.5437 8.80617 12.8794 8.46403 12.2151C8.21045 11.7251 7.86903 11.2609 7.39474 10.9651C7.09688 10.7723 6.72902 10.823 6.39331 10.8251C6.47902 10.2094 6.98545 9.75157 7.53617 9.52443C7.91688 9.34514 8.35188 9.36729 8.75974 9.40729C9.54403 9.59372 10.1297 10.2101 10.5912 10.8359C10.8476 11.2009 11.099 11.5716 11.3033 11.9687C11.339 11.9687 11.4105 11.9701 11.4455 11.9701C12.0319 11.0709 12.7119 10.2366 13.3483 9.37229C13.9969 8.54443 14.6797 7.74372 15.3905 6.96872C16.0212 6.31657 16.6105 5.62086 17.2997 5.02729C18.7069 3.68657 20.3826 2.64372 22.1655 1.88086Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M3.84116 5.42523C5.79902 3.37165 8.65474 2.21951 11.4876 2.32308C13.5219 2.40951 15.534 3.11023 17.1633 4.33522C17.2362 4.45237 17.0697 4.50665 17.009 4.58165C16.7119 4.80308 16.4904 5.11237 16.1976 5.3338C16.0412 5.27308 15.9112 5.16165 15.7676 5.07737C13.5604 3.6638 10.7012 3.33737 8.23473 4.22451C6.25045 4.91094 4.55331 6.35523 3.51545 8.17451C2.56473 9.85594 2.17473 11.8602 2.48402 13.7702C2.76259 15.6802 3.72116 17.4724 5.11473 18.8024C6.72045 20.3167 8.91331 21.2359 11.1312 21.1859C13.1604 21.2281 15.1726 20.4524 16.7226 19.1559C18.2512 17.8774 19.3269 16.0638 19.6933 14.1031C19.874 13.3024 19.8262 12.4752 19.8097 11.6609C19.6904 10.2067 19.1555 8.8088 18.3733 7.58451C18.6347 7.18808 18.9747 6.85165 19.2912 6.49951C20.2276 7.76451 20.8704 9.2488 21.1126 10.8059C21.5526 13.3595 20.9469 16.0674 19.4719 18.1974C18.279 19.9359 16.5333 21.2924 14.5476 22.0059C12.2683 22.8395 9.69045 22.7988 7.43259 21.9124C6.19831 21.4102 5.03545 20.7002 4.09259 19.7524C2.74331 18.4781 1.77759 16.8081 1.30902 15.0152C0.97902 13.8217 0.955449 12.5695 1.04473 11.3424C1.28759 9.13308 2.28188 7.01094 3.84116 5.42523Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default CheckMaskIcon;
