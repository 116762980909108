import styled from 'styled-components';
import CouponsPageStyled from './styles';
import Box from 'components/Box';
import Text from 'components/Text';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Divider, Form, Input, Row, Select, Switch, message } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { generateRandomNumberString } from 'utils/FunctionUtils';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import TextArea from 'antd/es/input/TextArea';
import SelectOrDatePicker from 'components/Modal/SelectOrDatePicker';
import FormItemNoMarginStyled from 'components/FormItem/FormItemNoMarginStyled';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import accountApis from 'features/account/services/apis';
import dayjs from 'dayjs';
import couponApis from '../services/apis';
import giftCardApis from 'features/giftCard/services/apis';

const CreateCouponPage = () => {
    const onClose = () => {
        navigate('/coupons');
    };
    const navigate = useNavigate();
    const { Option } = Select;
    const setPageLoading = useSetLoadingPage();
    const [formCreate] = Form.useForm();
    const [lstShop, setLstShop] = useState<any[]>([]);
    const [lstTemplate, setLstTemplate] = useState<any[]>([]);
    const [templateSelected, setTemplateSelected] = useState<any>();
    const [lstGiftCardOfShop, setLstGiftCardOfShop] = useState<any[]>([]);

    useEffect(() => {
        setPageLoading(true);
        const initData = async () => {
            try {
                const response = await accountApis.filterSalon(null, null, null, null);
                if (response.status === 200) {
                    setLstShop(response.data.data.content);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }

                const response1 = await couponApis.getLstCouponTemplate();
                if (response1.status === 200) {
                    setLstTemplate(response1.data.data);
                    setTemplateSelected(response1.data.data[0].templateType);
                } else {
                    message.error({ content: response1.data.message, duration: 2 });
                }

                setStartDateEntered(dayjs().startOf('day'));
                const numberString: string = generateRandomNumberString(8);
                setCouponCode(numberString);
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setPageLoading(false); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };

    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };

    const [title, setTitle] = useState('');
    const handleChangeTitle = (event: any) => {
        const value = event.target.value;
        setTitle(value);
    };

    const [couponCode, setCouponCode] = useState<any>();
    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value='MONEY'>Dollar</Option>
            <Option value='PERCENT'>Percent</Option>
        </Select>
    );

    const [description, setDescription] = useState('');
    const handleChangeDescription = (event: any) => {
        const value = event.target.value;
        setDescription(value);
    };

    const [startDate, setStartDateEntered] = useState<any>(dayjs().day());
    const handleStartDateChange = (date: any) => {
        setStartDateEntered(date);
    };

    const [expireDate, setExpireDate] = useState('mm-dd-yyyy');
    const handleChangeExpireDate = (value: any) => {
        if (!isNaN(value)) {
            value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
        }
        setExpireDate(value);
    };

    const changeTemplateType = async (templateType: string) => {
        setTemplateSelected(templateType);
        if (templateType === 'GIFT_CARD') {
            formCreate.setFieldsValue({
                shopIds: [],
                giftCardTemplateId: []
            });
            setLstGiftCardOfShop([]);
        }
    };

    const getListCardOffShop = async (shopId: any) => {
        const response = await giftCardApis.getCardByShop(shopId);
        if (response.status === 200) {
            setLstGiftCardOfShop(response.data.data);
        }
    };

    const handleShopChange = async (value: any) => {
        await getListCardOffShop(value);
    };

    const onSubmit = async (values: any) => {
        try {
            const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
            if (dateCheck.isBefore(startDate) && templateSelected !== 'LOYALTY_POINT') {
                message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
                return;
            }
            setPageLoading(true);
            const response = await couponApis.create(
                null, values.shopIds, title, discountType, discountValue, description, title, null,
                null, values.couponQuantity, couponCode, values.activePrint ? values.activePrint : false, startDate.format('MM-DD-YYYY'),
                templateSelected === 'LOYALTY_POINT' ? '' : expireDate, templateSelected, values.amountSpend, values.giftCardTemplateId);
            if (response.status === 200) {
                message.success('Create successful', onClose);
            } else {
                setPageLoading(false);
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            setPageLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <CouponsPageStyled>
            <Form form={formCreate}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    startDate: dayjs().startOf('day')
                }}>
                <Box className="d-between">
                    <Text variant="H5">Coupons List</Text>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate('/coupons')}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formCreate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <CouponsContentStyled>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                            <Text p={1} style={{ textAlign: 'center', background: 'rgba(226, 229, 236, 1)' }} variant="H5">Coupon’s Info</Text>
                            <Box style={{ background: 'white', borderRadius: '5px', textAlign: 'left' }} p={1.5}>
                                <FormItemNoMarginStyled name="activePrint"
                                    valuePropName="checked" label="Activate Print Coupon">
                                    <Switch style={{ textAlign: 'right' }} title='Activate Print Coupon' defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </FormItemNoMarginStyled>
                                <Row>
                                    {templateSelected !== 'LOYALTY_POINT' && (
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <FormItemNoMarginStyled
                                                name="title" // Tên của trường trong form
                                                label='Title'
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}>
                                                <Input size="large" placeholder='Please enter..' onChange={handleChangeTitle} />
                                            </FormItemNoMarginStyled>
                                        </Col>
                                    )}
                                    {templateSelected !== 'LOYALTY_POINT' && (
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <FormItemNoMarginStyled
                                                name="expiresDate" // Tên của trường trong form
                                                label='Expires Date'
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}>
                                                <SelectOrDatePicker disabled={startDate ? false : true} onChange={handleChangeExpireDate} valueDf='' />
                                            </FormItemNoMarginStyled>
                                        </Col>
                                    )}
                                    {templateSelected === 'GIFT_CARD' && (
                                        <Fragment>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                                <FormItemNoMarginStyled
                                                    name="amountSpend"
                                                    label="Amount Spend"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            min: 1
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} size='large' />
                                                </FormItemNoMarginStyled>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                                <FormItemNoMarginStyled name='giftCardTemplateId'
                                                    label="Gift Card Template"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Select size='large' style={{ width: '100%' }}
                                                        placeholder="Select"
                                                        showSearch optionFilterProp="label">
                                                        {lstGiftCardOfShop.map((item: any) => (
                                                            <Select.Option key={item.id} value={item.id} label={`${item.giftName}`}>
                                                                <span>
                                                                    <img src={item.urlImage} width={'20px'} />
                                                                    {item.giftName} - Expires Date: {item.numberDayExpired} Days
                                                                </span>

                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </FormItemNoMarginStyled>
                                            </Col>

                                        </Fragment>

                                    )}
                                    {templateSelected !== 'LOYALTY_POINT' && (
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <FormItemNoMarginStyled
                                                name="promotionValue"
                                                label="Promotion"
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}>
                                                <Input onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} placeholder='0' type='number' min={0} size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                            </FormItemNoMarginStyled>
                                        </Col>
                                    )}
                                    {templateSelected === 'DISCOUNT' && (
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <FormItemNoMarginStyled
                                                name="description"
                                                label="Text">
                                                <TextArea placeholder='Please enter..' showCount maxLength={100} onChange={handleChangeDescription} value={description} />
                                            </FormItemNoMarginStyled>
                                        </Col>
                                    )}
                                </Row>
                            </Box>
                            <Box p={0.5} style={{ backgroundColor: 'rgba(246, 247, 252, 1)' }}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold', borderRadius: '5px' }}>Salon</Text>
                                                <DividerStyled />
                                                <Form.Item name="shopIds"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Salon is required'
                                                        }
                                                    ]}
                                                >
                                                    {
                                                        templateSelected === 'GIFT_CARD' ?
                                                            (
                                                                <Select size='large' style={{ width: '100%' }}
                                                                    placeholder="Salon"
                                                                    showSearch optionFilterProp="label"
                                                                    onChange={handleShopChange}
                                                                >
                                                                    {lstShop.map((item: any) => (
                                                                        <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.address} ${item.city}`}>
                                                                            {item.shopName} - {item.address} {item.city}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            )
                                                            :
                                                            (
                                                                <Select size='large' style={{ width: '100%' }}
                                                                    placeholder="Salon"
                                                                    showSearch optionFilterProp="label"
                                                                    mode="multiple">
                                                                    {lstShop.map((item: any) => (
                                                                        <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.address} ${item.city}`}>
                                                                            {item.shopName} - {item.address} {item.city}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            )
                                                    }
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold' }}>Start Date</Text>
                                                <DividerStyled />
                                                <Form.Item name="startDate"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Start Date is required'
                                                        }
                                                    ]}
                                                >
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        onChange={handleStartDateChange}
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        showTime={false}
                                                        inputReadOnly={true} />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold' }}>Coupon Quantity</Text>
                                                <DividerStyled />
                                                <Form.Item
                                                    name="couponQuantity"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} placeholder='0' min={0} type='number' size="large" />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Box style={{ textAlign: 'center', padding: '15px' }}>
                                <img style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} width={'100%'} src={lstTemplate?.find((item) => item.templateType === templateSelected)?.urlImage} />
                                <Row gutter={10} style={{ marginTop: '15px' }}>
                                    {lstTemplate?.map((item: any, index) => (
                                        <Col key={index} span={8}>
                                            <img className={templateSelected === item.templateType ? 'active' : ''}
                                                width={'100%'}
                                                src={item.urlImage} onClick={() => changeTemplateType(item.templateType)} />
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                        </Col>
                    </Row>
                </CouponsContentStyled>
            </Form>
        </CouponsPageStyled >
    );
};

const CouponsContentStyled = styled.div`
  background: rgba(238, 238, 238, 1);
  margin-top: 1rem;
  padding: 1.5rem;

  && .ant-qrcode-borderless {
    margin-bottom: -8px;
    padding-bottom: 0!important;
    padding-top: 0!important;
  }

  && .active {
    border: 2px solid rgba(255, 136, 144, 1);
  }
`;

const DividerStyled = styled(Divider)`
    border: 0.5px solid rgba(218, 218, 218, 1);
    margin: 16px 0;
`;

const BoxInfoFix = styled(Box)`
    background: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(218, 218, 218, 1);
`;

export default CreateCouponPage;