import React from 'react';
import { AddIconProps } from '..';
const ReportIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17_4693)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 0.75C1.91421 0.75 2.25 1.08579 2.25 1.5V21.75H22.5C22.9142 21.75 23.25 22.0858 23.25 22.5C23.25 22.9142 22.9142 23.25 22.5 23.25H2.25C1.85218 23.25 1.47065 23.092 1.18934 22.8107C0.908035 22.5294 0.75 22.1478 0.75 21.75V1.5C0.75 1.08579 1.08579 0.75 1.5 0.75Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.4375 11.25C5.33395 11.25 5.25 11.3339 5.25 11.4375V18.5625C5.25 18.6661 5.33395 18.75 5.4375 18.75H7.3125C7.41605 18.75 7.5 18.6661 7.5 18.5625V11.4375C7.5 11.3339 7.41605 11.25 7.3125 11.25H5.4375ZM3.75 11.4375C3.75 10.5055 4.50552 9.75 5.4375 9.75H7.3125C8.24448 9.75 9 10.5055 9 11.4375V18.5625C9 19.4945 8.24448 20.25 7.3125 20.25H5.4375C4.50552 20.25 3.75 19.4945 3.75 18.5625V11.4375Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1875 9C12.0839 9 12 9.08395 12 9.1875V18.5625C12 18.6661 12.0839 18.75 12.1875 18.75H14.0625C14.1661 18.75 14.25 18.6661 14.25 18.5625V9.1875C14.25 9.08395 14.1661 9 14.0625 9H12.1875ZM10.5 9.1875C10.5 8.25552 11.2555 7.5 12.1875 7.5H14.0625C14.9945 7.5 15.75 8.25552 15.75 9.1875V18.5625C15.75 19.4945 14.9945 20.25 14.0625 20.25H12.1875C11.2555 20.25 10.5 19.4945 10.5 18.5625V9.1875Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9207 6C18.8171 6 18.7332 6.08395 18.7332 6.1875V18.5625C18.7332 18.6661 18.8171 18.75 18.9207 18.75H20.7957C20.8992 18.75 20.9832 18.6661 20.9832 18.5625V6.1875C20.9832 6.08395 20.8992 6 20.7957 6H18.9207ZM17.2332 6.1875C17.2332 5.25552 17.9887 4.5 18.9207 4.5H20.7957C21.7276 4.5 22.4832 5.25552 22.4832 6.1875V18.5625C22.4832 19.4945 21.7276 20.25 20.7957 20.25H18.9207C17.9887 20.25 17.2332 19.4945 17.2332 18.5625V6.1875Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4693">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportIcon;
