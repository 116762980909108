import { fastApi } from 'utils/fastApi';

const getAll = async () => {
  return await fastApi.get('/api/v1/admin/account').then((response) => {
    return response;
  });
};

const addAccount = async (businessName: string, timezone: string, phone: string, firstName: string,
  lastName: string, email: string, address: string, city: string, state: string, zip: string, password: string,
  bookingOnline: boolean, giftOnline: boolean, deviceFeatures: any) => {
  const payLoad = {
    businessName,
    timezone,
    phone,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zip,
    password,
    bookingOnline,
    giftOnline,
    deviceFeatures
  };
  return await fastApi.post('/api/v1/admin/account', payLoad).then((response) => {
    return response;
  });
};

const filterSalon = async (
  accountId: string | null,
  shopName: string | null,
  page: number | null,
  size: number | null) => {
  const params = { accountId, shopName, size, page };
  return await fastApi.get('/api/v1/admin/account/shop', { params }).then((response: any) => {
    return response;
  });
};

const deleteShop = async (id: any) => {
  return await fastApi.delete(`/api/v1/admin/account/shop/${id}`).then((response: any) => {
    return response;
  });
};

const addSalon = async (accountId: string, businessName: string, timezone: string, phone: string,
  email: string, address: string, city: string, state: string, zip: string, password: string,
  bookingOnline: boolean, giftOnline: boolean, deviceFeatures: any) => {
  const payLoad = {
    accountId,
    businessName,
    timezone,
    phone,
    email,
    address,
    city,
    state,
    zip,
    password,
    bookingOnline,
    giftOnline,
    deviceFeatures
  };
  return await fastApi.post('/api/v1/admin/account/shop', payLoad).then((response) => {
    return response;
  });
};

const genLinkShop = async (shopId: string, type: string) => {
  const payLoad = {
    shopId,
    type,
  };
  return await fastApi.post('/api/v1/admin/account/shop/gen-link', payLoad).then((response: any) => {
    return response;
  });
};

const genShopById = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/account/shop/${shopId}`).then((response: any) => {
    return response;
  });
};

const updateSalon = async (shopId: string, shopName: string, timezone: string, phone: string, email: string,
  address: string, city: string, state: string, zip: string, deviceFeatures: any, giftOnline: boolean, bookingOnline: boolean) => {
  const payLoad = {
    shopId, shopName, timezone, phone, email, address, city, deviceFeatures, giftOnline, bookingOnline, state, zip
  };
  return await fastApi.put('/api/v1/admin/account/shop-update', payLoad).then((response) => {
    return response;
  });
};

const getTimeZone = async () => {
  return await fastApi.get('/api/v1/admin/account/timezone').then((response) => {
    return response;
  });
};

const resetPwdShop = async (shopId: any, password: string) => {
  const payLoad = {
    shopId,
    password,
  };
  return await fastApi.post('/api/v1/admin/account/reset-password', payLoad).then((response: any) => {
    return response;
  });
};


const allCustomerGroupListByShopId = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/customer/group-list/${shopId}`).then((response: any) => {
    return response;
  });
};

const filterCustomerByShopId = async (shopId: any, groupId: string | null, pointFrom: string | null, pointTo: string | null,
  sendingAmountFrom: string | null, sendingAmountTo: string | null, types: any, lastPhoneNumber: string | null, page: number, size: number) => {
  const params: Record<string, string | any> = {
    shopId, groupId, pointFrom, pointTo,
    sendingAmountFrom, sendingAmountTo, lastPhoneNumber, page, size
  };
  if (types && types.length > 0) {
    params.types = types.join(',');
  }
  return await fastApi.get(`/api/v1/admin/customer/${shopId}/list`, { params }).then((response: any) => {
    return response;
  });
};

const addGroupCustomerByShopId = async (shopId: any, customerIds: any, groupName: string) => {
  const payLoad = { shopId, customerIds, groupName };
  return await fastApi.post('/api/v1/admin/customer/create-group', payLoad).then((response: any) => {
    return response;
  });
};

const filterSmsCampaignByShopId = async (shopId: string | null, status: string | null, type: string | null, title: string | null, page: number, size: number) => {
  const params = {
    shopId, status, type, title, page, size
  };
  return await fastApi.get(`/api/v1/admin/marketing/${shopId}/campaigns`, { params }).then((response: any) => {
    return response;
  });
};

const updateCampaignStatus = async (shopId: any, status: any, campaignId: string) => {
  const payLoad = { shopId, status, campaignId };
  return await fastApi.post(`/api/v1/admin/marketing/update-status/${status}`, payLoad).then((response: any) => {
    return response;
  });
};

const deleteMultipleCampaign = async (campaignIds: any[]) => {
  const payload = campaignIds.map(campaignId => ({ campaignId }));
  return await fastApi.post('/api/v1/admin/marketing/delete', campaignIds).then((response: any) => {
    return response;
  });
};

const getLstCouponTemplateByType = async (type: string) => {
  return await fastApi.get(`/api/v1/admin/coupon/template/${type}`).then((response: any) => {
    return response;
  });
};

const getLstCustomerByGroup = async (groupId: string) => {
  return await fastApi.get(`/api/v1/admin/customer/list-by-group/${groupId}`).then((response: any) => {
    return response;
  });
};

const getLstGroupByShopId = async (shopId: string) => {
  return await fastApi.get(`/api/v1/admin/customer/group-list/${shopId}`).then((response: any) => {
    return response;
  });
};

const exportExcelCustomer = async (shopId: any, groupId: any, pointFrom: any, pointTo: any,
  sendingAmountFrom: any, sendingAmountTo: any,
  types: any, lastPhoneNumber: any) => {
  const params: Record<string, string | any> = {
    shopId, groupId, pointFrom, pointTo,
    sendingAmountFrom, sendingAmountTo, lastPhoneNumber
  };
  if (types && types.length > 0) {
    params.types = types.join(',');
  }
  return await fastApi.get(`/api/v1/admin/customer/exportExcel/${shopId}`, { params, responseType: 'blob' }).then((response: any) => {
    return response;
  });
};

const createSmsCampaign = async (shopId: any, title: any, message: any, sendTime: any, coupon: any, customers: any) => {
  const payLoad = { shopId, title, message, sendTime, coupon, customers };
  return await fastApi.post('/api/v1/admin/marketing/createSmsCampaign', payLoad).then((response: any) => {
    return response;
  });
};

const getCampaignById = async (campaignId: string) => {
  return await fastApi.post(`/api/v1/admin/marketing/detail/${campaignId}`).then((response: any) => {
    return response;
  });
};

const updateSmsCampaign = async (campaignId: any, shopId: any, title: any, message: any, sendTime: any, coupon: any, customers: any) => {
  const payLoad = { campaignId, shopId, title, message, sendTime, coupon, customers };
  return await fastApi.post('/api/v1/admin/marketing/update-campaign', payLoad).then((response: any) => {
    return response;
  });
};

const accountApis = {
  getAll,
  addAccount,
  filterSalon,
  deleteShop,
  addSalon,
  genLinkShop,
  genShopById,
  updateSalon,
  getTimeZone,
  resetPwdShop,
  allCustomerGroupListByShopId,
  filterCustomerByShopId,
  addGroupCustomerByShopId,
  filterSmsCampaignByShopId,
  updateCampaignStatus,
  deleteMultipleCampaign,
  getLstCouponTemplateByType,
  getLstCustomerByGroup,
  getLstGroupByShopId,
  exportExcelCustomer,
  createSmsCampaign,
  getCampaignById,
  updateSmsCampaign
};

export default accountApis;
