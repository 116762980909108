import React from 'react';
import { AddIconProps } from '..';

const MenuAccountsIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">

            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path style={{ fill: 'none' }} d="M14.9125 17.6966C14.2085 17.9046 13.3765 18.0006 12.4005 18.0006H7.6004C6.62439 18.0006 5.79239 17.9046 5.08838 17.6966C5.26438 15.6166 7.4004 13.9766 10.0005 13.9766C12.6005 13.9766 14.7365 15.6166 14.9125 17.6966Z" stroke="#566A7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path style={{ fill: 'none' }} d="M12.4001 2H7.60008C3.60002 2 2 3.60002 2 7.60008V12.4001C2 15.4242 2.91201 17.0802 5.08804 17.6962C5.26404 15.6162 7.40007 13.9762 10.0001 13.9762C12.6001 13.9762 14.7362 15.6162 14.9122 17.6962C17.0882 17.0802 18.0002 15.4242 18.0002 12.4001V7.60008C18.0002 3.60002 16.4002 2 12.4001 2ZM10.0001 11.7361C8.41609 11.7361 7.13607 10.4481 7.13607 8.86409C7.13607 7.28009 8.41609 6.00005 10.0001 6.00005C11.5841 6.00005 12.8641 7.28009 12.8641 8.86409C12.8641 10.4481 11.5841 11.7361 10.0001 11.7361Z" stroke="#566A7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path style={{ fill: 'none' }} d="M12.8638 8.86404C12.8638 10.4481 11.5838 11.7361 9.99975 11.7361C8.41573 11.7361 7.13574 10.4481 7.13574 8.86404C7.13574 7.28003 8.41573 6 9.99975 6C11.5838 6 12.8638 7.28003 12.8638 8.86404Z" stroke="#566A7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>


        </span>
    );
};
export default MenuAccountsIcon;
