import React, { useState } from 'react';
import { Select, Modal, DatePicker } from 'antd';
import styled from 'styled-components';
const { Option } = Select;
interface SelectOrDatePickerProps {
    onChange: (value: string | null, date: Date | null) => void;
    disabled: boolean,
    valueDf: string
}

const SelectOrDatePicker: React.FC<SelectOrDatePickerProps> = ({ onChange, disabled, valueDf }) => {
    const [options, setOptions] = useState<string[]>([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | undefined>(valueDf);

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
        if (value === 'addDate') {
            setShowDatePicker(true);
        } else {
            onChange(value, null); // Gọi prop onChange khi giá trị Select thay đổi
        }
    };

    const handleDatePickerChange = (date: any) => {
        if (date) {
            setOptions([...options, date.format('MM-DD-YYYY')]);
            setSelectedOption(date.format('MM-DD-YYYY'));
            onChange(date.format('MM-DD-YYYY'), date.toDate()); // Gọi prop onChange khi giá trị DatePicker thay đổi
        }
        setShowDatePicker(false);
    };

    const handleCloseEditorModal = () => {
        setShowDatePicker(false);
    };

    return (
        <div>
            <Select
                onChange={handleSelectChange}
                value={selectedOption}
                size="large"
                disabled={disabled}>
                {options.map((option) => (
                    <Option key={option} value={option}>{option}</Option>
                ))}
                {valueDf && (<Option value={valueDf}>{valueDf}</Option>)}
                <Option value="30">30 Days</Option>
                <Option value="60">60 Days</Option>
                <Option value="90">90 Days</Option>
                <Option value="addDate">
                    <IconContainer>
                        <ImageIconStyled src="/images/icon-cala.svg" alt="Icon" />
                        <span>Select Date</span>
                    </IconContainer>
                </Option>
            </Select>
            <Modal
                closable={false}
                maskClosable={false}
                title="Select Date"
                open={showDatePicker} // Sử dụng visible thay vì open
                onCancel={handleCloseEditorModal}
                footer={null}>
                <DatePicker size='large' style={{ width: '100%' }} className={'datePicker'} onChange={handleDatePickerChange} />
            </Modal>
        </div>
    );
};

export default SelectOrDatePicker;

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const ImageIconStyled = styled.img`
 width: 20px;
 text-align: center;
 margin-right: 0.5rem;
 filter: invert(53%) sepia(5%) saturate(10%) hue-rotate(61deg) brightness(90%) contrast(84%);
`;
