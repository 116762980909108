import React from 'react';
import ContainerBox from './components/ContainerBox';
import LineBehind from './components/LineBehind';
import SignInPageStyled from './styles';
type ISignInPageProps = any;
const SignInPage: React.FC<ISignInPageProps> = () => {
  return (
    <SignInPageStyled className="d-center">
      <LineBehind />
      <ContainerBox />
    </SignInPageStyled>
  );
};

export default SignInPage;
