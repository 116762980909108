import React from 'react';
import { AddIconProps } from '..';
const ChangeIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69816 6.69538C7.81664 5.57691 9.19776 4.8867 10.6424 4.62205L10.282 2.65479C8.45093 2.99023 6.69852 3.8666 5.28395 5.28117C1.57397 8.99115 1.57397 15.0062 5.28395 18.7162L3.57684 20.4233H8.40527V15.5949L6.69816 17.302C3.76923 14.3731 3.76923 9.62431 6.69816 6.69538ZM20.4243 3.57583H15.5959V8.40426L17.303 6.69715C20.2319 9.62609 20.2319 14.3748 17.303 17.3038C16.1845 18.4222 14.8034 19.1124 13.3587 19.3771L13.7191 21.3444C15.5502 21.0089 17.3026 20.1325 18.7172 18.718C22.4272 15.008 22.4272 8.99292 18.7172 5.28294L20.4243 3.57583Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default ChangeIcon;
