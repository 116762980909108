const colorTheme = {
    // primary
    primary_active: '#FF8890',
    primary_hover: '#F5767F',
    primary_inactive: '#FFC3C7',
    primary_button: '#FFE5E7',
    primary_bg: '#FFF1F6',
  
    // Neutral color
    line_3: '#86909C',
    line_2: '#C9CDD4',
    line_1: '#DADADA',
  
    fill_5: '#EAEDED',
    fill_4: '#1D2129',
    fill_3: '#E5E5E5',
    fill_2: '#E3E9ED',
    fill_1: '#F6F7FC',
    fill_0: '#FFFFFF',
  
    text_3: '#1D2129',
    text_2: '#566A7F',
    text_1: '#FFFFFF',
  
    // Functional color
    info_orange: '#FF7D00',
    info_bg_orange: '#F8D9BB',
    info_3: '#6FABB6',
    info_bg_3: '#C6E9EF',
    info_2: '#FFDC7C',
    info_bg_2: '#FEEDBF',
    info_1: '#777D7C',
  
    error_6: '#F53F3F',
    error_5: '#F76560',
    error_4: '#CB2634',
    error_3: '#FBACA3',
    error_2: '#FDCDC5',
    error_1: '#FFECE8',

    blue: '#008BFF',
  };
  
  export default colorTheme;
  