import React from 'react';
import { AddIconProps } from '..';

const MenuAccountEditIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.29813 10.6977C6.14224 10.6977 3.57373 8.29767 3.57373 5.34884C3.57373 2.4 6.14224 0 9.29813 0C12.454 0 15.0225 2.4 15.0225 5.34884C15.0225 8.29767 12.454 10.6977 9.29813 10.6977ZM9.29813 1.39535C6.96855 1.39535 5.06705 3.17209 5.06705 5.34884C5.06705 7.52558 6.96855 9.30233 9.29813 9.30233C11.6277 9.30233 13.5292 7.52558 13.5292 5.34884C13.5292 3.17209 11.6277 1.39535 9.29813 1.39535Z" fill="#566A7F" />
                <path d="M13.1009 20.0003C12.7226 20.0003 12.3642 19.8701 12.1054 19.6282C11.7968 19.3398 11.6574 18.9212 11.7271 18.484L11.9162 17.2282C11.966 16.9026 12.1751 16.5212 12.424 16.2794L15.9482 12.9864C17.3619 11.6654 18.6063 12.4189 19.2136 12.9864C19.7313 13.4701 20.0001 13.991 20.0001 14.5119C20.0001 15.0422 19.7412 15.5352 19.2136 16.0282L15.6893 19.3212C15.4404 19.5538 15.0223 19.7492 14.6739 19.7957L13.3298 19.9723C13.2502 19.9909 13.1806 20.0003 13.1009 20.0003ZM17.571 13.6468C17.3918 13.6468 17.2325 13.7585 17.0035 13.9724L13.4792 17.2654C13.4494 17.2933 13.3996 17.3864 13.3996 17.4236L13.2204 18.5864L14.4648 18.4189C14.5047 18.4096 14.6041 18.3631 14.634 18.3352L18.1583 15.0422C18.3176 14.8933 18.5068 14.6794 18.5068 14.5119C18.5068 14.3724 18.3873 14.1771 18.1583 13.9724C17.9194 13.7492 17.7402 13.6468 17.571 13.6468Z" fill="#566A7F" />
                <path d="M18.1793 16.7158C18.1097 16.7158 18.04 16.7066 17.9802 16.688C16.6661 16.3438 15.6208 15.3671 15.2524 14.1392C15.1429 13.7671 15.3719 13.3857 15.7701 13.2834C16.1683 13.181 16.5765 13.395 16.686 13.7671C16.915 14.5299 17.5621 15.1345 18.3785 15.3485C18.7767 15.4508 19.0056 15.8415 18.8961 16.2043C18.8065 16.5113 18.5079 16.7158 18.1793 16.7158Z" fill="#566A7F" />
                <path d="M0.746661 19.9998C0.338486 19.9998 0 19.6835 0 19.3021C0 15.33 4.17137 12.0928 9.29846 12.0928C10.3836 12.0928 11.4588 12.2416 12.4643 12.5207C12.8625 12.6323 13.0815 13.023 12.9621 13.3858C12.8426 13.7579 12.4245 13.9625 12.0362 13.8509C11.1601 13.6091 10.2442 13.4788 9.29846 13.4788C4.99767 13.4788 1.49332 16.0835 1.49332 19.2928C1.49332 19.6835 1.15484 19.9998 0.746661 19.9998Z" fill="#566A7F" />
            </svg>
        </span>
    );
};
export default MenuAccountEditIcon;
