import React from 'react';
import { AddIconProps } from '..';

const MenuSmsIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.92061C0.0444784 1.42856 1.31953 0.10769 2.80753 0.00795042C7.47641 0.0025591 12.1439 0.0106465 16.8128 0.00525522C18.0582 -0.0729189 19.2915 0.729039 19.7619 1.87874C20.022 2.45965 19.9977 3.11335 19.9977 3.73605C19.9897 6.43036 20.0004 9.12467 19.9924 11.8203C19.9924 12.3217 20.0153 12.842 19.8347 13.3205C19.4397 14.4729 18.3089 15.3489 17.0797 15.3705C14.6091 15.3853 12.1386 15.3678 9.66798 15.3786C9.49681 15.353 9.38359 15.5026 9.26228 15.5956C7.62871 17.0162 5.97493 18.4126 4.32923 19.8197C3.89523 20.2213 3.13101 19.906 3.0717 19.3264C3.04744 18.0136 3.07036 16.6981 3.06227 15.3853C1.49205 15.4136 0.071435 14.0793 0.00269572 12.5118C-0.00134777 9.31471 0.00269566 6.11766 0 2.92061ZM2.27783 1.74395C1.76566 2.03509 1.4934 2.62409 1.51496 3.20365C1.53922 6.25918 1.50148 9.31471 1.53113 12.3702C1.52979 12.9997 1.9867 13.5806 2.57705 13.776C3.07979 13.9202 3.61757 13.7612 4.12031 13.9108C4.41818 14.0213 4.62575 14.3354 4.60149 14.6562C4.60149 15.6266 4.58801 16.597 4.60014 17.5675C5.97358 16.4151 7.32815 15.2398 8.68676 14.0726C8.81211 13.9769 8.93072 13.8219 9.10998 13.8542C11.7275 13.8259 14.3436 13.8529 16.9611 13.8407C17.755 13.8771 18.4639 13.1452 18.4747 12.3622C18.4774 9.48858 18.4814 6.61501 18.4747 3.74144C18.4693 3.33844 18.5219 2.92196 18.383 2.53513C18.1714 1.94882 17.5757 1.52695 16.953 1.52426C12.5025 1.51887 8.05058 1.52561 3.60005 1.52156C3.15392 1.52291 2.66601 1.48113 2.27783 1.74395Z" fill="#566A7F" />
                <path d="M4.33583 6.08288C5.21461 5.65023 6.4061 6.26618 6.50314 7.25549C6.67836 8.2111 5.77801 9.1721 4.80757 9.01576C3.92609 8.93893 3.25218 8.00893 3.47457 7.14766C3.58509 6.6894 3.90587 6.28236 4.33583 6.08288Z" fill="#566A7F" />
                <path d="M9.44626 6.04089C10.2388 5.72685 11.2389 6.1932 11.472 7.02076C11.8211 7.93324 11.0353 9.02903 10.0663 9.02364C9.38426 9.07755 8.71573 8.58829 8.52434 7.93729C8.26825 7.19463 8.72112 6.3145 9.44626 6.04089Z" fill="#566A7F" />
                <path d="M14.4287 6.05979C15.3411 5.63252 16.5245 6.36574 16.5623 7.36718C16.6472 8.21092 15.9113 9.03579 15.0594 9.02636C14.182 9.07623 13.3922 8.23788 13.4838 7.36583C13.5243 6.7984 13.9003 6.27274 14.4287 6.05979Z" fill="#566A7F" />
                <path d="M8.08841 18.481C11.1278 18.4446 14.1698 18.4891 17.2105 18.4635C17.5407 18.45 17.8817 18.6616 17.9815 18.9824C18.189 19.4663 17.7213 20.0364 17.2132 19.9933C14.1199 19.9744 11.0253 20.0081 7.93071 19.9758C7.29993 19.9825 7.06001 19.0741 7.47919 18.6738C7.63149 18.4945 7.8768 18.5039 8.08841 18.481Z" fill="#566A7F" />
            </svg>
        </span>
    );
};
export default MenuSmsIcon;
