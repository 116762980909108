import { fastApi } from 'utils/fastApi';

const filterCoupon = async (
  shopId: string | null,
  type: string | null,
  status: string | null,
  couponName: string | null,
  page: number,
  size: number) => {
  const params = { shopId, type, status, couponName, size, page };
  return await fastApi.get('/api/v1/admin/coupon/list', { params }).then((response: any) => {
    return response;
  });
};

const create = async (couponId: string | null, shopIds: any, couponName: string | null,
  discountType: string, discountValue: number, description: string, title: string, totalExpireDate: string | null,
  totalPrint: number | null, quantity: number, couponCode: string, activePrint: boolean, startTime: any, endTime: any,
  templateType: any, amountSpend: any, giftCardTemplateId: any) => {
  const payLoad = {
    couponId, shopIds, couponName, discountType, discountValue, description, title,
    totalExpireDate, totalPrint, quantity, couponCode, activePrint, startTime, endTime, templateType, amountSpend, giftCardTemplateId
  };
  return await fastApi.post('/api/v1/admin/coupon/create', payLoad).then((response: any) => {
    return response;
  });
};

const deleteMultiple = async (couponIds: any[]) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': '*/*',
    },
    data: couponIds,
  };

  return await fastApi.delete('/api/v1/admin/coupon/delete-multiple', config).then((response: any) => {
    return response;
  });
};

const getCouponDetail = async (couponId: any) => {
  return await fastApi.get(`/api/v1/admin/coupon/${couponId}`).then((response: any) => {
    return response;
  });
};

const updateCoupon = async (couponId: string | null, shopId: any, discountType: string | null,
  discountValue: number, description: string, title: string, totalExpireDate: string | null,
  quantity: number | null, activePrint: boolean, startTime: any, endTime: any) => {
  const payLoad = {
    couponId, shopId, discountType, discountValue, description, title, totalExpireDate,
    quantity, activePrint, startTime, endTime
  };
  return await fastApi.post('/api/v1/admin/coupon/update', payLoad).then((response: any) => {
    return response;
  });
};

const updateStatusCoupon = async (couponId: string | null, status: string) => {
  const payLoad = {
    couponId,
    status
  };
  return await fastApi.post('/api/v1/admin/coupon/update-status', payLoad).then((response: any) => {
    return response;
  });
};

const updateTime = async (couponId: string, startTime: string, endTime: string) => {
  const payLoad = {
    couponId,
    startTime,
    endTime
  };
  return await fastApi.post('/api/v1/admin/coupon/update-time', payLoad).then((response: any) => {
    return response;
  });
};

const getLstCouponTemplate = async () => {
  return await fastApi.get('/api/v1/admin/coupon/template').then((response: any) => {
    return response;
  });
};

const couponApis = {
  filterCoupon,
  create,
  deleteMultiple,
  getCouponDetail,
  updateCoupon,
  updateStatusCoupon,
  updateTime,
  getLstCouponTemplate
};

export default couponApis;
