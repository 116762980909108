import React from 'react';
import { AddIconProps } from '..';

const MenuPwIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.12999 18C4.04622 18 3.95407 17.9916 3.87867 17.9832L2.06076 17.7319C1.18951 17.6146 0.402025 16.8355 0.267985 15.9475L0.0166617 14.1129C-0.0671129 13.5264 0.184211 12.7641 0.603084 12.3368L4.28079 8.65913C3.68599 6.27995 4.37294 3.76671 6.12383 2.03258C8.83816 -0.673336 13.2531 -0.681714 15.9758 2.03258C17.291 3.34784 18.0115 5.09873 18.0115 6.95853C18.0115 8.8183 17.291 10.5692 15.9758 11.8845C14.2165 13.627 11.7116 14.3139 9.34918 13.7107L5.66307 17.3884C5.31122 17.7571 4.68291 18 4.12999 18ZM11.0414 1.25348C9.57538 1.25348 8.1177 1.80639 7.00346 2.92059C5.49552 4.42016 4.95098 6.61505 5.57929 8.65913C5.64631 8.88532 5.58767 9.11989 5.42012 9.28744L1.48272 13.2248C1.3403 13.3673 1.22302 13.7359 1.24815 13.9286L1.49947 15.7632C1.54974 16.0816 1.89321 16.4418 2.21156 16.4837L4.03784 16.735C4.2389 16.7685 4.60751 16.6512 4.74993 16.5088L8.70412 12.563C8.87167 12.3955 9.11462 12.3452 9.33243 12.4122C11.3514 13.0489 13.5547 12.5044 15.0626 10.9964C16.1349 9.92413 16.7297 8.48321 16.7297 6.95853C16.7297 5.42545 16.1349 3.99291 15.0626 2.92059C13.9735 1.81477 12.5075 1.25348 11.0414 1.25348Z" fill="#566A7F" />
                <path d="M6.65227 16.1486C6.4931 16.1486 6.33393 16.09 6.20827 15.9643L4.28145 14.0375C4.03851 13.7946 4.03851 13.3924 4.28145 13.1495C4.5244 12.9065 4.92652 12.9065 5.16946 13.1495L7.09628 15.0763C7.33924 15.3193 7.33924 15.7214 7.09628 15.9643C6.97062 16.09 6.81145 16.1486 6.65227 16.1486Z" fill="#566A7F" />
                <path d="M11.1007 8.78499C10.0619 8.78499 9.21582 7.93887 9.21582 6.90006C9.21582 5.86126 10.0619 5.01514 11.1007 5.01514C12.1396 5.01514 12.9857 5.86126 12.9857 6.90006C12.9857 7.93887 12.1396 8.78499 11.1007 8.78499ZM11.1007 6.27176C10.7573 6.27176 10.4724 6.55659 10.4724 6.90006C10.4724 7.24354 10.7573 7.52837 11.1007 7.52837C11.4442 7.52837 11.7291 7.24354 11.7291 6.90006C11.7291 6.55659 11.4442 6.27176 11.1007 6.27176Z" fill="#566A7F" />
            </svg>
        </span>
    );
};
export default MenuPwIcon;
