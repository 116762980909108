import React from 'react';
import styled from 'styled-components';
type ILogoSvgProps = {};
const LogoSvg: React.FC<ILogoSvgProps> = () => {
  return (
    <LogoSvgStyled>
      <svg
        width="161"
        height="43"
        viewBox="0 0 161 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M98.5632 5.21571C99.2208 5.1415 99.8924 5.14237 100.548 5.24016C101.453 5.44797 102.354 5.91945 102.845 6.72796C103.341 7.47885 103.384 8.46722 103.051 9.28971C102.743 9.93582 102.226 10.4964 101.57 10.8028C100.705 11.2647 99.6891 11.2988 98.732 11.2149C97.668 11.0822 96.6039 10.533 96.0525 9.59792C95.4922 8.66455 95.5726 7.41686 96.216 6.54286C96.7692 5.80682 97.6609 5.36764 98.5632 5.21571Z"
          fill="#F38375"
        />
        <path
          d="M96.5308 14.3303C98.4839 14.326 100.436 14.3164 102.389 14.3338C102.4 21.9623 102.4 29.5908 102.389 37.2192C100.437 37.207 98.4839 37.2166 96.5316 37.2131C96.552 31.9412 96.5352 26.6684 96.5405 21.3956C96.5343 19.0399 96.5529 16.6851 96.5308 14.3303Z"
          fill="#F38375"
        />
        <path
          d="M3.78506 6.93359C12.9214 6.94058 22.0585 6.93272 31.1948 6.93709C31.1895 8.73572 31.1948 10.5335 31.1922 12.3321C24.1327 12.3295 17.0741 12.3304 10.0156 12.3312C10.0164 14.9672 10.0147 17.6023 10.0164 20.2374C16.0101 20.2365 22.0037 20.2356 27.9974 20.2382C27.9983 21.926 27.9947 23.6137 27.9991 25.3024C22.0046 25.3058 16.0101 25.3041 10.0156 25.3024C10.0173 29.2663 10.0138 33.2294 10.0173 37.1934C7.93961 37.2004 5.86189 37.1943 3.78418 37.196C3.78683 27.1088 3.78506 17.0217 3.78506 6.93359Z"
          fill="black"
        />
        <path
          d="M113.271 7.24916C115.208 7.24654 117.145 7.24654 119.082 7.24829C119.084 9.61445 119.084 11.9806 119.082 14.3468C120.946 14.3442 122.809 14.3476 124.672 14.345C124.667 15.7831 124.669 17.222 124.671 18.66C122.808 18.6548 120.945 18.6574 119.082 18.6583C119.086 24.8365 119.083 31.0147 119.083 37.1929C117.145 37.2016 115.207 37.2008 113.27 37.1929C113.275 31.0138 113.271 24.8347 113.271 18.6556C111.408 18.6591 109.546 18.6556 107.683 18.6574C107.687 17.2202 107.689 15.7822 107.682 14.345C109.544 14.3476 111.406 14.3433 113.269 14.3477C113.275 11.9815 113.271 9.61532 113.271 7.24916Z"
          fill="black"
        />
        <path
          d="M68.9728 14.3474C76.5183 14.3465 84.0629 14.3334 91.6085 14.3386C86.8583 20.3702 82.1045 26.3982 77.3437 32.421C81.835 32.414 86.3271 32.435 90.8184 32.4236C90.8131 34.0162 90.8219 35.6097 90.8148 37.2022C82.762 37.197 74.7101 37.2031 66.6582 37.1996C71.3669 31.1663 76.0994 25.1505 80.8169 19.1233C76.8691 19.119 72.9223 19.1286 68.9745 19.1181C68.979 17.5282 68.9825 15.9382 68.9728 14.3474Z"
          fill="black"
        />
        <path
          d="M48.592 13.9198C49.4457 13.8395 50.2994 13.9399 51.154 13.9399C52.4999 14.0822 53.8592 14.2647 55.1371 14.7248C56.1269 15.0261 57.0575 15.481 57.9731 15.9542C58.5537 16.3253 59.1688 16.6553 59.669 17.1355C59.9889 17.3145 60.2028 17.6245 60.5139 17.8183C61.205 18.5491 61.8704 19.3175 62.3786 20.188C63.2367 21.6199 63.7431 23.2631 63.8545 24.9238C63.949 25.5437 63.9296 26.1811 63.8333 26.7993C63.8368 27.3039 63.7007 27.792 63.6238 28.2871C63.5672 28.4006 63.554 28.5254 63.5354 28.6477C63.1457 30.0071 62.5324 31.315 61.6486 32.4318C60.1701 34.4094 58.0005 35.7863 55.6938 36.6463C52.8163 37.6958 49.6693 37.8635 46.6468 37.4898C44.8227 37.2619 43.0526 36.6961 41.4194 35.8675C41.2091 35.7688 41.0261 35.6134 40.7955 35.5628C40.51 35.3113 40.1689 35.1219 39.8366 34.9376C39.5635 34.6451 39.1888 34.4705 38.9077 34.1859C38.6356 33.8977 38.2812 33.6943 38.0585 33.3608C37.507 32.8413 37.066 32.224 36.648 31.5988C35.713 30.1014 35.1491 28.3831 35.0263 26.6281C35.0466 25.8432 34.9609 25.0495 35.1076 24.2742C35.1279 23.8586 35.2499 23.4569 35.3135 23.0474C35.5238 22.3943 35.7209 21.7351 36.017 21.1135C36.6842 19.6789 37.6943 18.4147 38.891 17.3756C39.4451 16.9574 39.9656 16.4833 40.5948 16.1751C41.422 15.5927 42.3809 15.2382 43.3177 14.8689C45.0057 14.2603 46.7997 13.9469 48.592 13.9198ZM47.9901 19.0695C46.0794 19.3009 44.19 20.0954 42.8732 21.5116C41.9231 22.5585 41.2939 23.9179 41.2356 25.3307C41.0995 26.7774 41.5396 28.2521 42.3898 29.4326C42.7088 29.8953 43.1401 30.2647 43.5413 30.655C45.5183 32.258 48.2128 32.7505 50.7059 32.4772C52.857 32.265 55.0381 31.3395 56.3699 29.6055C57.6284 28.0068 58.0367 25.7882 57.3854 23.8629C56.9894 22.5367 56.0827 21.4051 54.9665 20.5905C53.6974 19.6955 52.1738 19.1769 50.6237 19.0468C50.0917 19.0599 49.5623 18.9342 49.0321 19.011C48.6865 19.0555 48.3357 19.0258 47.9901 19.0695Z"
          fill="#747A7A"
        />
        <path
          d="M141.776 13.9432C142.868 13.8463 143.963 13.9449 145.053 14.02C147.667 14.2723 150.212 15.176 152.381 16.6376C152.945 17.0655 153.499 17.5073 154.021 17.984C154.585 18.6257 155.157 19.2701 155.598 20.0044C156.542 21.5245 157.103 23.276 157.199 25.0572C157.203 25.9477 157.239 26.8462 157.056 27.7237C156.725 29.6419 155.858 31.465 154.575 32.9406C153.703 33.9543 152.643 34.796 151.492 35.484C150.093 36.2436 148.615 36.8923 147.042 37.2067C144.898 37.6825 142.672 37.748 140.49 37.528C138.64 37.328 136.807 36.8565 135.144 36.0175C133.501 35.2491 132 34.1499 130.859 32.745C129.589 31.2336 128.765 29.3651 128.485 27.4216C128.252 25.6046 128.385 23.7256 129.02 21.996C129.465 20.7946 130.116 19.6665 130.953 18.6895C131.209 18.445 131.42 18.1621 131.661 17.9037C132.423 17.2488 133.19 16.5861 134.079 16.0989C135.604 15.2406 137.254 14.5622 138.988 14.2557C139.906 14.0706 140.839 13.9633 141.776 13.9432ZM141.484 19.0562C139.618 19.2596 137.789 20.0114 136.427 21.3036C135.99 21.7629 135.6 22.2701 135.311 22.8333C134.435 24.4172 134.321 26.3913 135.002 28.0651C135.545 29.4577 136.626 30.6128 137.931 31.348C139.73 32.3827 141.887 32.6813 143.937 32.4848C145.492 32.3442 147.038 31.8431 148.311 30.9359C149.558 30.0453 150.507 28.7243 150.856 27.2347C151.34 25.2885 150.81 23.1171 149.45 21.624C148.377 20.4086 146.852 19.6377 145.282 19.2587C144.04 18.9619 142.75 18.9724 141.484 19.0562Z"
          fill="#6BABB7"
        />
        <path
          d="M47.9904 19.0679C48.3359 19.0243 48.6868 19.0539 49.0323 19.0094C49.5626 18.9326 50.092 19.0583 50.624 19.0452C52.1741 19.1753 53.6977 19.6939 54.9668 20.5889C56.083 21.4035 56.9897 22.5351 57.3856 23.8614C58.0369 25.7866 57.6286 28.0052 56.3702 29.6039C55.0384 31.3379 52.8572 32.2634 50.7062 32.4756C48.2131 32.7489 45.5185 32.2564 43.5415 30.6534C43.1403 30.2631 42.709 29.8938 42.39 29.431C41.5398 28.2505 41.0997 26.7758 41.2358 25.3291C41.2941 23.9164 41.9234 22.5569 42.8734 21.51C44.1902 20.0938 46.0797 19.2993 47.9904 19.0679Z"
          fill="#FFDF7A"
        />
      </svg>
    </LogoSvgStyled>
  );
};

export default LogoSvg;
const LogoSvgStyled = styled.div``;
