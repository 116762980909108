import React from 'react';
import { AddIconProps } from '..';
const TurnIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18126 4.13397C6.47286 3.79677 7.06446 3.85797 7.29486 4.23357C7.47366 4.49277 7.45086 4.86357 7.23846 5.09757C6.68526 5.75757 6.09846 6.39117 5.54646 7.05237C8.01726 7.04997 10.4881 7.05357 12.9589 7.05117C13.8625 7.03557 14.7625 7.38117 15.4213 7.99917C16.3057 8.81397 16.7185 10.1016 16.4629 11.2776C16.2277 12.48 15.3097 13.5132 14.1469 13.896C13.3801 14.1708 12.5533 14.0592 11.7565 14.0868C11.4241 14.0916 11.0197 14.1024 10.8121 13.7904C10.5073 13.4124 10.7269 12.774 11.2033 12.6696C12.0361 12.558 12.9049 12.7788 13.7173 12.5136C14.4805 12.24 15.0385 11.4876 15.0709 10.6764C15.1249 9.85077 14.6293 9.02877 13.8781 8.68797C13.4065 8.44917 12.8653 8.49117 12.3541 8.49117C10.0837 8.49477 7.81206 8.48997 5.54166 8.49357C6.11166 9.16437 6.71166 9.80997 7.27206 10.4892C7.59966 10.8804 7.32966 11.544 6.83046 11.6172C6.54246 11.6844 6.26166 11.5308 6.08526 11.31C5.05686 10.1304 4.02606 8.95197 2.99646 7.77117C4.05966 6.56037 5.11086 5.33757 6.18126 4.13397Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M9.85321 10.0992C10.6212 9.82436 11.448 9.94076 12.246 9.91076C12.5796 9.90356 12.9888 9.89756 13.1916 10.2168C13.488 10.5972 13.2648 11.2236 12.7932 11.328C11.9868 11.4348 11.154 11.2392 10.3608 11.454C9.59641 11.6952 9.01081 12.4092 8.93521 13.2084C8.82961 14.0688 9.33241 14.9556 10.122 15.3108C10.5924 15.5472 11.1324 15.5076 11.6424 15.5064C13.914 15.504 16.1856 15.5076 18.456 15.5052C17.8848 14.832 17.2836 14.184 16.722 13.5024C16.4268 13.1412 16.626 12.5364 17.0688 12.4044C17.3796 12.2856 17.7204 12.4356 17.9136 12.6888C18.942 13.8684 19.9716 15.0468 21.0012 16.2264C20.016 17.3592 19.0212 18.4848 18.0408 19.6224C17.8464 19.8492 17.6172 20.1096 17.2884 20.0868C16.752 20.1048 16.3728 19.416 16.7004 18.9828C17.262 18.2844 17.8776 17.6304 18.4584 16.9476C15.9876 16.9452 13.5168 16.9464 11.0472 16.9476C10.1472 16.962 9.24721 16.6248 8.58841 16.0104C7.66681 15.1728 7.25641 13.8204 7.55881 12.612C7.82521 11.4528 8.72521 10.4712 9.85321 10.0992Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default TurnIcon;
