import React from 'react';
import { AddIconProps } from '..';
const EmailIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C2.67157 3 2 3.67157 2 4.5V19.5C2 20.3284 2.67157 21 3.5 21H20.5C21.3284 21 22 20.3284 22 19.5V4.5C22 3.67157 21.3284 3 20.5 3H3.5ZM4 19V5H20V19H4ZM6.06921 7.6778L12 11.7837L17.9308 7.6778L19.0692 9.32218L12.8538 13.6251C12.3401 13.9808 11.6598 13.9808 11.1462 13.6251L4.93079 9.32218L6.06921 7.6778Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default EmailIcon;
