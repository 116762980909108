import React from 'react';
import { AddIconProps } from '..';
const CustomerPersonIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1831_155013)">
        <path
          d="M10.8993 0.87447C12.1557 -0.166728 14.0702 -0.284564 15.4619 0.555442C15.9867 0.872196 16.4178 1.32611 16.7577 1.83307C17.1548 2.42907 17.431 3.09857 17.6273 3.78513C17.8675 4.61793 17.999 5.47613 18.1528 6.3275C18.5658 8.69028 19.3133 10.9947 20.3686 13.1487C20.6368 13.7019 20.9111 14.2664 20.9979 14.8818C21.132 15.8032 20.907 16.7747 20.3731 17.5389C19.8669 18.2725 19.0481 18.7457 18.631 19.5467C18.2888 20.2124 18.3271 20.9827 18.2994 21.7094C18.2699 22.5297 18.1088 23.3864 17.6068 24.057C17.1389 24.6864 16.3102 25.0929 15.5248 24.9058C16.0753 24.9937 16.558 24.5136 16.6543 24.0006C16.7319 23.5478 16.5611 23.0954 16.3303 22.7127C15.9651 22.1057 15.4187 21.6196 15.1262 20.9668C14.9091 20.486 14.8939 19.9332 15.0231 19.4266C15.2043 18.7075 15.6002 18.0675 16.0143 17.4613C16.3671 16.9611 16.7236 16.4587 16.993 15.9071C17.273 15.3361 17.4719 14.6904 17.3662 14.0497C17.2863 13.5083 16.9593 13.0521 16.6278 12.6338C16.2731 12.1962 15.9526 11.7301 15.6877 11.2327C14.9068 9.77619 14.5294 8.14694 14.2631 6.52793C14.1828 6.07137 14.1354 5.60836 14.0471 5.15331C13.6163 4.66833 13.1116 4.23374 12.5262 3.94502C12.1041 3.73625 11.6283 3.6851 11.1649 3.651C10.8807 3.62296 10.592 3.70329 10.3408 3.83135C10.1195 4.09317 10.1294 4.45463 10.0195 4.76457C9.77661 5.47651 9.38862 6.13692 8.90364 6.71094C8.69487 6.94699 8.50049 7.19516 8.30195 7.43955C8.14661 7.63733 7.88328 7.69455 7.64799 7.73433C7.04214 7.81011 6.43287 7.69568 5.84711 7.54716C5.80694 7.52973 5.74821 7.53352 5.72131 7.49866C5.86681 6.7026 5.97934 5.89935 6.16727 5.11125C6.36429 4.26897 6.64657 3.44071 7.07169 2.68444C7.37405 2.1502 7.7249 1.63188 8.18942 1.22457C8.59598 0.863861 9.10445 0.606214 9.64778 0.544454C10.0846 0.499745 10.5586 0.578555 10.8993 0.87447Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M5.79609 8.4923C5.83171 8.36424 5.94992 8.25398 6.0886 8.2608C6.40535 8.28467 6.70544 8.44077 6.9233 8.66962C7.16352 8.92045 7.31924 9.24403 7.40184 9.57935C7.06728 9.31147 6.75469 9.01745 6.42013 8.74919C6.31934 9.35504 6.49061 10.0272 6.92141 10.4751C7.12828 10.3746 7.34008 10.2841 7.54203 10.1735C7.67919 10.5827 7.7849 11.0025 7.94101 11.4056C8.39947 12.6029 9.14854 13.6903 10.1124 14.5372C10.5743 14.932 11.0832 15.2976 11.6689 15.481C12.0603 15.6125 12.4805 15.5045 12.8465 15.3461C13.3971 15.1032 13.8798 14.73 14.3106 14.3148C14.8338 13.8014 15.2802 13.2099 15.6348 12.5684C15.65 12.5423 15.6685 12.5188 15.6909 12.4983C15.8284 12.6931 15.9716 12.8844 16.1243 13.0678C15.4988 14.1272 14.6542 15.0824 13.5926 15.7189C13.0307 16.0433 12.3748 16.3051 11.7155 16.1865C11.0998 16.0603 10.5482 15.7303 10.0511 15.3575C9.08299 14.6133 8.29641 13.6434 7.72542 12.5662C7.48747 12.1088 7.2806 11.6329 7.13624 11.1377C6.87442 11.2268 6.59783 11.112 6.41293 10.9233C6.02684 10.5383 5.82565 10.0029 5.74153 9.4725C5.6991 9.14741 5.69493 8.80792 5.79609 8.4923Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M4.96791 9.75132C5.00011 9.67781 5.01982 9.59559 5.07741 9.53687C5.16266 10.1439 5.38469 10.7444 5.7776 11.2207C6.00456 11.498 6.30881 11.7182 6.65777 11.8121C7.04008 12.8385 7.61069 13.7926 8.3196 14.6265C8.39159 14.7141 8.42834 14.8232 8.4757 14.9251C8.77768 15.5897 9.15392 16.2202 9.59647 16.8006C9.95452 17.2792 10.3118 17.8013 10.3777 18.4113C10.4315 18.8959 10.2088 19.3491 9.98597 19.7632C9.67831 20.3543 9.30662 20.9241 9.13763 21.5758C9.01525 22.0407 9.04291 22.5579 9.28312 22.9815C9.5241 23.4191 9.94998 23.7548 10.4384 23.8689C9.52637 24.5554 8.18168 24.6085 7.2227 23.9848C6.56191 23.5627 6.07996 22.8648 5.93219 22.0934C5.79541 21.3621 5.95303 20.6237 5.99016 19.8909C6.04056 19.0611 5.85717 18.2173 5.46009 17.486C5.16948 16.9294 4.72959 16.4748 4.3397 15.989C4.0059 15.576 3.70468 15.1142 3.59783 14.5864C3.44514 13.857 3.60162 13.1041 3.84373 12.4127C4.17034 11.506 4.59432 10.6387 4.96791 9.75132Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M13.8478 16.3306C14.0793 16.1938 14.3089 16.0521 14.5199 15.8843C14.5294 16.3094 14.5218 16.7349 14.5245 17.1604C14.5279 17.3771 14.5097 17.5957 14.5495 17.8106C14.3407 17.8985 14.1263 17.9716 13.9152 18.0531C13.8573 17.8621 13.8436 17.6613 13.8455 17.4631C13.8466 17.0857 13.844 16.708 13.8478 16.3306Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M12.0401 19.1017C12.9062 19.1347 13.7898 19.0013 14.5794 18.6331C14.3203 19.2938 14.1782 20.0266 14.3468 20.7283C14.4847 21.3474 14.8579 21.8764 15.2497 22.3617C15.5483 22.7444 15.8878 23.1313 15.9912 23.6197C15.9995 23.7159 16.0401 23.8413 15.9525 23.9141C15.7623 24.0853 15.5327 24.2051 15.3133 24.3339C14.9026 24.5574 14.4893 24.7806 14.0535 24.9515C14.4571 25.1432 14.8617 25.3334 15.2679 25.5191C15.6907 25.6593 16.1552 25.646 16.5819 25.5285C17.2684 25.3395 17.8732 24.8852 18.2592 24.2884C18.7488 23.5454 18.9348 22.6444 18.9647 21.7665C18.9932 21.2414 18.9644 20.7071 19.0951 20.1933C19.5979 20.3555 20.0923 20.5612 20.5209 20.8753C21.412 21.507 22.0482 22.4868 22.2588 23.5587C22.3312 23.9088 22.352 24.268 22.3433 24.6249C22.3456 24.953 22.3517 25.2811 22.349 25.6092C21.1843 25.2694 19.9196 25.2629 18.756 25.6108C18.7469 26.3181 18.7003 27.0255 18.604 27.7265C18.5192 28.3081 18.4108 28.8939 18.1763 29.4357C18.0796 29.638 17.9758 29.8525 17.7981 29.9953C13.9592 30.004 10.1195 29.9949 6.28016 29.9999C6.22976 30.0021 6.1752 29.9957 6.14413 29.951C5.85239 29.6282 5.71409 29.2019 5.59929 28.79C5.33747 27.7538 5.26018 26.6792 5.24199 25.6138C4.08258 25.266 2.8167 25.2599 1.65577 25.6043C1.66297 24.8602 1.59135 24.1012 1.77929 23.3723C2.05891 22.22 2.83412 21.1993 3.87153 20.6245C4.32166 20.3642 4.82634 20.2274 5.31209 20.0509C5.24237 20.8261 5.12112 21.6206 5.3136 22.3883C5.49244 23.1093 5.90657 23.7667 6.46885 24.2509C6.97618 24.6912 7.615 24.9788 8.28223 25.0602C9.15633 25.1716 10.0733 24.953 10.7932 24.4419C11.1982 24.1395 11.6157 23.8534 12.0117 23.5397C11.5513 23.4405 11.0932 23.3298 10.6333 23.2283C10.2472 23.1529 9.9145 22.8566 9.79022 22.4841C9.68224 22.1496 9.74817 21.7873 9.87055 21.4664C10.1915 20.6385 10.767 19.9255 11.0015 19.0612C11.3452 19.1146 11.6938 19.1074 12.0401 19.1017Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1831_155013">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerPersonIcon;
