import React from 'react';
import { AddIconProps } from '..';

const ArrowLeftIcon: React.FC<AddIconProps> = ({ active = false }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM19.375 15.9375H12.8875L15.0375 18.0875C15.4 18.45 15.4 19.05 15.0375 19.4125C14.85 19.6 14.6125 19.6875 14.375 19.6875C14.1375 19.6875 13.9 19.6 13.7125 19.4125L9.9625 15.6625C9.6 15.3 9.6 14.7 9.9625 14.3375L13.7125 10.5875C14.075 10.225 14.675 10.225 15.0375 10.5875C15.4 10.95 15.4 11.55 15.0375 11.9125L12.8875 14.0625H19.375C19.8875 14.0625 20.3125 14.4875 20.3125 15C20.3125 15.5125 19.8875 15.9375 19.375 15.9375Z"
        fill={active ? 'white' : '#8E97A8'}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
