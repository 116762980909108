import {  createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { ILoginResData } from './types/login';

const afterLogin = createAsyncAction<ILoginResData>(PREFIX_ACTIONS + 'afterLogin');
const afterLogout = createAsyncAction(PREFIX_ACTIONS + 'afterLogout');

const authActions = {
  afterLogin,
  afterLogout
};

export default authActions;

