import uiServiceReducer from 'services/UI/reducer';
import authServiceReducer from 'features/auth/services/reducers';
import accountServiceReducer from 'features/account/services/reducers';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  ui: uiServiceReducer,
  account: accountServiceReducer,
  auth: authServiceReducer,
});

export default rootReducer;
