import React from 'react';
import { AddIconProps } from '..';
const EmployeeIcon: React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_464_15198)">
        <path
          d="M11.1408 0H12.861C14.4972 0.2424 16.0218 1.1832 16.9284 2.5728C17.445 3.3264 17.7318 4.2126 17.8734 5.109C18.0492 6.4926 17.7372 7.9404 16.9782 9.114C16.0362 10.6104 14.3658 11.622 12.6036 11.7612C12.0426 11.7522 11.4768 11.8002 10.9212 11.7042C9.73203 11.4984 8.61123 10.9152 7.76703 10.0524C6.71223 8.9838 6.10923 7.5006 6.08043 6.003C6.02043 3.9768 7.07763 1.953 8.79963 0.8742C9.50523 0.417 10.3134 0.1332 11.1408 0Z"
          fill={active ? 'white' : '#1D2129'}
        />
        <path
          d="M3.32455 14.9083C4.50715 13.5727 6.01495 12.5239 7.68055 11.8843C8.53975 12.4315 9.49496 12.8371 10.4982 13.0225C10.8906 13.1203 11.295 13.1659 11.7 13.1623C12.1194 13.1581 12.5412 13.1779 12.9582 13.1251C14.0586 12.9559 15.1506 12.6199 16.0908 12.0109C16.1874 11.9641 16.29 11.8633 16.404 11.9161C18.7746 12.8461 20.7906 14.6149 22.0638 16.8151C22.6476 17.7679 23.0592 18.8167 23.3772 19.8841C23.6448 20.8111 23.7768 21.7681 23.8818 22.7251C23.055 22.9015 22.2348 23.1043 21.4026 23.2549C20.9016 23.3089 20.415 23.4529 19.9128 23.4961C19.392 23.5357 18.8844 23.6731 18.3618 23.6971C17.5008 23.7643 16.647 23.9083 15.7818 23.9233C15.4074 23.9671 15.0246 23.9107 14.6556 24.0001H9.34495C8.97596 23.9113 8.59256 23.9665 8.21815 23.9233C7.29415 23.9053 6.38215 23.7481 5.46175 23.6815C5.00035 23.6455 4.54795 23.5399 4.08715 23.4961C3.58435 23.4541 3.09775 23.3065 2.59615 23.2549C1.76575 23.0983 0.941954 22.9093 0.117554 22.7251C0.154154 22.2481 0.245954 21.7783 0.301754 21.3037C0.351554 20.9197 0.476954 20.5507 0.545354 20.1703C1.05115 18.2353 1.98115 16.3975 3.32455 14.9083ZM17.6436 15.7237C17.6364 16.6423 17.6442 17.5615 17.64 18.4801C16.7208 18.4843 15.8022 18.4771 14.883 18.4831C14.8794 19.0015 14.8794 19.5205 14.883 20.0389C15.801 20.0509 16.719 20.0323 17.6364 20.0479C17.6496 20.9683 17.6328 21.8887 17.6448 22.8091C18.1812 22.8247 18.7188 22.8235 19.2558 22.8097C19.2642 21.9283 19.2534 21.0463 19.2594 20.1649C19.2474 20.0689 19.3656 20.0329 19.4412 20.0419C20.2998 20.0389 21.1578 20.0479 22.0164 20.0389C22.0206 19.5199 22.0206 19.0015 22.0164 18.4831C21.0978 18.4771 20.1786 18.4837 19.2594 18.4807C19.2552 17.5615 19.2624 16.6423 19.2564 15.7231C18.7188 15.7195 18.1812 15.7195 17.6436 15.7237Z"
          fill={active ? 'white' : '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_464_15198">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployeeIcon;
