import React from 'react';
import { AddIconProps } from '..';

const MenuDashboardIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path style={{ fill: 'none' }} d="M9.00073 13.8939V11.4482" stroke="#566A7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path style={{ fill: 'none' }} d="M7.45757 1.5187L1.91442 6.04324C1.29051 6.54869 0.890572 7.61667 1.02655 8.41559L2.09039 14.9049C2.28236 16.0625 3.3702 17 4.52203 17H13.4807C14.6245 17 15.7203 16.0543 15.9123 14.9049L16.9761 8.41559C17.1041 7.61667 16.7042 6.54869 16.0883 6.04324L10.5451 1.52685C9.68924 0.825748 8.30544 0.825748 7.45757 1.5187Z" stroke="#566A7F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </span>
    );
};

export default MenuDashboardIcon;
