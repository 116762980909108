import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { ILoginResData } from './types/login';

const initialState: ILoginResData = {
  accessToken: null,
  adminAccountDto: null,
  role: null
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.afterLogin.success, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.adminAccountDto = payload.adminAccountDto;
        state.role = payload.role;
      })
      .addCase(actions.afterLogout.success, (state) => {
        state.accessToken = '';
        state.adminAccountDto = null;
        state.role = null;
      });
  },
});

const authServiceReducer = Slice.reducer;
export default authServiceReducer;
