import React from 'react';
import { AddIconProps } from '..';

const MenuEmailIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.478906 1.52368C0.813672 1.16313 1.3043 0.960003 1.79531 0.968597C7.26484 0.968597 12.7348 0.968597 18.2043 0.968597C18.659 0.959613 19.1137 1.13149 19.4434 1.44555C19.7797 1.75688 19.9793 2.20414 20 2.66118V13.3998C19.9559 13.8627 19.7391 14.3096 19.3805 14.61C19.0562 14.8885 18.6293 15.0389 18.2023 15.0311C12.7344 15.0311 7.26641 15.0311 1.79883 15.0311C1.26953 15.0416 0.741797 14.803 0.405469 14.3928C0.171094 14.1163 0.0402344 13.7651 0 13.4073V2.66274C0.0183594 2.24203 0.1875 1.82914 0.478906 1.52368ZM2.02852 2.14086C4.52461 4.64477 7.0207 7.14867 9.51641 9.65336C9.76133 9.92289 10.2402 9.92094 10.4855 9.65219C12.9812 7.14828 15.477 4.64438 17.9727 2.14047C12.6582 2.14086 7.34336 2.13969 2.02852 2.14086ZM1.17227 2.94203C1.17148 6.31391 1.17148 9.68578 1.17227 13.0577C2.85273 11.3713 4.53438 9.68657 6.21367 7.99907C4.5332 6.31352 2.85312 4.62719 1.17227 2.94203ZM13.7879 7.99868C15.466 9.68657 17.148 11.3705 18.8277 13.0569C18.8285 9.68539 18.8281 6.31391 18.8277 2.94243C17.148 4.62797 15.468 6.31352 13.7879 7.99868ZM2.02852 13.8584C7.34336 13.86 12.6582 13.8588 17.9727 13.8588C16.3016 12.183 14.6313 10.5057 12.9594 8.83032C12.4359 9.35571 11.9117 9.88071 11.3887 10.4061C11.2539 10.5459 11.1082 10.6776 10.9395 10.7752C10.2613 11.1772 9.32227 11.0905 8.74961 10.5405C8.17695 9.97368 7.61211 9.39907 7.04102 8.83032C5.36953 10.5057 3.7 12.1831 2.02852 13.8584Z" fill="#566A7F" />
            </svg>
        </span>
    );
};

export default MenuEmailIcon;
