import fetch from 'services/request';
import { IBodyApiLogin } from './types/api';
import { fastApi } from 'utils/fastApi';

const authenticate = async (values: any) => {
  const payload = {
    email: values.email,
    password: values.password
  };
  return await fastApi.post('/api/v1/admin/auth/login', payload).then((response) => {
    return response;
  });
};

const login = (body: IBodyApiLogin) => {
  return fetch({
    method: 'post',
    url: 'api/v1/admin/auth/login',
    body,
  });
};

const authApis = {
  authenticate,
  login
};

export default authApis;
