import React from 'react';
import styled from 'styled-components';
type IHeaderSignInProps = {};
const HeaderSignIn: React.FC<IHeaderSignInProps> = () => {
  return (
    <HeaderSignInStyled>
      <svg
        width="539"
        height="177"
        viewBox="0 0 539 177"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3016_4323)">
          <g filter="url(#filter0_f_3016_4323)">
            <circle
              cx="278.5"
              cy="-66.5"
              r="188.5"
              fill="url(#paint0_radial_3016_4323)"
            />
          </g>
          <mask
            id="mask0_3016_4323"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="71"
            y="-36"
            width="397"
            height="135"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M468 33L71 33L71 32L468 32L468 33Z"
              fill="url(#paint1_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M468 69L71 69L71 68L468 68L468 69Z"
              fill="url(#paint2_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M91 99L91 -36L92 -36L92 99L91 99Z"
              fill="url(#paint3_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M127 99L127 -36L128 -36L128 99L127 99Z"
              fill="url(#paint4_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M163 99L163 -36L164 -36L164 99L163 99Z"
              fill="url(#paint5_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M199 99L199 -36L200 -36L200 99L199 99Z"
              fill="url(#paint6_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M235 99L235 -36L236 -36L236 99L235 99Z"
              fill="url(#paint7_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M271 99L271 -36L272 -36L272 99L271 99Z"
              fill="url(#paint8_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M307 99L307 -36L308 -36L308 99L307 99Z"
              fill="url(#paint9_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M343 99L343 -36L344 -36L344 99L343 99Z"
              fill="url(#paint10_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M379 99L379 -36L380 -36L380 99L379 99Z"
              fill="url(#paint11_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M415 99L415 -36L416 -36L416 99L415 99Z"
              fill="url(#paint12_linear_3016_4323)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M451 99L451 -36L452 -36L452 99L451 99Z"
              fill="url(#paint13_linear_3016_4323)"
            />
          </mask>
          <g mask="url(#mask0_3016_4323)">
            <circle
              cx="270"
              cy="-23"
              r="198"
              fill="url(#paint14_linear_3016_4323)"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_3016_4323"
            x="-110"
            y="-455"
            width="777"
            height="777"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_3016_4323"
            />
          </filter>
          <radialGradient
            id="paint0_radial_3016_4323"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(278.5 -66.5) rotate(179.848) scale(188.501)"
          >
            <stop stopColor="#FFBDBA" />
            <stop offset="1" stopColor="#FFEAE9" stopOpacity="0" />
          </radialGradient>
          <linearGradient
            id="paint1_linear_3016_4323"
            x1="269.5"
            y1="32"
            x2="269.5"
            y2="33"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3016_4323"
            x1="269.5"
            y1="68"
            x2="269.5"
            y2="69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3016_4323"
            x1="92"
            y1="31.5"
            x2="91"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3016_4323"
            x1="128"
            y1="31.5"
            x2="127"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3016_4323"
            x1="164"
            y1="31.5"
            x2="163"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_3016_4323"
            x1="200"
            y1="31.5"
            x2="199"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_3016_4323"
            x1="236"
            y1="31.5"
            x2="235"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_3016_4323"
            x1="272"
            y1="31.5"
            x2="271"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_3016_4323"
            x1="308"
            y1="31.5"
            x2="307"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_3016_4323"
            x1="344"
            y1="31.5"
            x2="343"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_3016_4323"
            x1="380"
            y1="31.5"
            x2="379"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_3016_4323"
            x1="416"
            y1="31.5"
            x2="415"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_3016_4323"
            x1="452"
            y1="31.5"
            x2="451"
            y2="31.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAEAA" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_3016_4323"
            x1="270"
            y1="-221"
            x2="270"
            y2="175"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCD7D5" stopOpacity="0" />
            <stop offset="0.552083" stopColor="#FCD7D5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3016_4323">
            <rect width="539" height="176.403" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </HeaderSignInStyled>
  );
};

export default HeaderSignIn;
const HeaderSignInStyled = styled.div`
  position: absolute;
  z-index: 1;
`;
