import { createAsyncAction, createAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IBodyAddAccount, IParamsSalonList } from './types/api';

const addAccount = createAsyncAction<IBodyAddAccount>(PREFIX_ACTIONS + 'addAccount');
const getSalons = createAsyncAction<IParamsSalonList>(PREFIX_ACTIONS + 'getSalons');
const getAccounts = createAsyncAction(PREFIX_ACTIONS + 'getAccounts');
const setSalonsParams = createAction<IParamsSalonList>(PREFIX_ACTIONS + 'setSalonsParams');

const accountActions = {
  addAccount,
  getAccounts,
  getSalons,
  setSalonsParams
};

export default accountActions;

