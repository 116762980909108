import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  data: [],
  accounts: [],
  salons: [],
  salonParams: {
    accountId: '',
    shopName: '',
    page: 1,
    size: 10,
  },
  totalElement: 0,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(actions.addAccount.success, (state, { payload }) => {
      //   // state. = payload;
      // })
      .addCase(
        actions.getAccounts.success,
        (state, { payload }: PayloadAction<{ content: any[] }>) => {
          state.accounts = payload.content;
        }
      )
      .addCase(
        actions.getSalons.success,
        (
          state,
          { payload }: PayloadAction<{ content: any[]; totalElement: number }>
        ) => {
          state.salons = payload.content;
          state.totalElement = payload.totalElement;
        }
      )
      .addCase(actions.setSalonsParams, (state, { payload }) => {
        const _params = {
          ...(state.salonParams ?? {}),
          ...payload,
        };
        state.salonParams = _params;
      });
  },
});

const accountServiceReducer = Slice.reducer;
export default accountServiceReducer;
