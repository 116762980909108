import React from 'react';
import styled from 'styled-components';
type ILineBehindProps = {};
const LineBehind: React.FC<ILineBehindProps> = () => {
  return (
    <LineBehindStyled>
      <svg
        width="1187"
        height="403"
        viewBox="0 0 1187 403"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1186.06 95.5261L0.940918 95.5262L0.940918 92.541L1186.06 92.5409L1186.06 95.5261Z"
          fill="url(#paint0_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1186.06 205.978L0.940918 205.978L0.940918 202.993L1186.06 202.993L1186.06 205.978Z"
          fill="url(#paint1_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1186.06 313.444L0.940918 313.444L0.940918 310.459L1186.06 310.459L1186.06 313.444Z"
          fill="url(#paint2_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.6447 403L60.6447 -1.30487e-07L63.6299 0L63.6299 403L60.6447 403Z"
          fill="url(#paint3_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.111 403L168.111 -1.30487e-07L171.097 0L171.097 403L168.111 403Z"
          fill="url(#paint4_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M275.578 403L275.578 -1.30487e-07L278.563 0L278.563 403L275.578 403Z"
          fill="url(#paint5_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M383.045 403L383.045 -1.30487e-07L386.03 0L386.03 403L383.045 403Z"
          fill="url(#paint6_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M490.511 403L490.511 -1.30487e-07L493.497 0L493.497 403L490.511 403Z"
          fill="url(#paint7_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M597.978 403L597.978 -1.30487e-07L600.963 0L600.963 403L597.978 403Z"
          fill="url(#paint8_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M705.444 403L705.445 -1.30487e-07L708.43 0L708.43 403L705.444 403Z"
          fill="url(#paint9_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M812.911 403L812.911 -1.30487e-07L815.896 0L815.896 403L812.911 403Z"
          fill="url(#paint10_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M920.378 403L920.378 -1.30487e-07L923.363 0L923.363 403L920.378 403Z"
          fill="url(#paint11_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1027.84 403L1027.84 -1.30487e-07L1030.83 0L1030.83 403L1027.84 403Z"
          fill="url(#paint12_linear_3016_4463)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1135.31 403L1135.31 -1.30487e-07L1138.3 0L1138.3 403L1135.31 403Z"
          fill="url(#paint13_linear_3016_4463)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3016_4463"
            x1="593.5"
            y1="92.541"
            x2="593.5"
            y2="95.5261"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3016_4463"
            x1="593.5"
            y1="202.993"
            x2="593.5"
            y2="205.978"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3016_4463"
            x1="593.5"
            y1="310.459"
            x2="593.5"
            y2="313.444"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3016_4463"
            x1="63.6299"
            y1="201.5"
            x2="60.6447"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3016_4463"
            x1="171.097"
            y1="201.5"
            x2="168.111"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3016_4463"
            x1="278.563"
            y1="201.5"
            x2="275.578"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_3016_4463"
            x1="386.03"
            y1="201.5"
            x2="383.045"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_3016_4463"
            x1="493.497"
            y1="201.5"
            x2="490.511"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_3016_4463"
            x1="600.963"
            y1="201.5"
            x2="597.978"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_3016_4463"
            x1="708.43"
            y1="201.5"
            x2="705.444"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_3016_4463"
            x1="815.896"
            y1="201.5"
            x2="812.911"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_3016_4463"
            x1="923.363"
            y1="201.5"
            x2="920.378"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_3016_4463"
            x1="1030.83"
            y1="201.5"
            x2="1027.84"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_3016_4463"
            x1="1138.3"
            y1="201.5"
            x2="1135.31"
            y2="201.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </LineBehindStyled>
  );
};

export default LineBehind;
const LineBehindStyled = styled.div`
  position: absolute;
`;
