import CouponsPageStyled from './styles';
import Box from 'components/Box';
import Text from 'components/Text';
import Button from 'components/Button';
import { Col, DatePicker, Form, Input, Pagination, Row, Select, Switch, message } from 'antd';
import Modal from 'components/Modal';
import styled from 'styled-components';
import Table, { ColumnsType } from 'antd/es/table';
import { Fragment, useEffect, useRef, useState } from 'react';
import couponApis from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import dayjs from 'dayjs';
import { Content } from 'antd/es/layout/layout';
import accountApis from 'features/account/services/apis';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import Swal from 'sweetalert2';
import SelectOrDatePicker from 'components/Modal/SelectOrDatePicker';
import FormItemNoMarginStyled from 'components/FormItem/FormItemNoMarginStyled';

interface DataType {
  key: React.Key;
  shopName: string;
  title: string;
  counponName: string;
  promotion: string;
  quantity: number;
  couponUsed: string;
  daysExpired: any;
  status: string;
  action: string;
  discountType: string;
  totalPrint: number;
  couponId: string;
  startTime: any;
  templateType: any;
}

const CouponsPage = () => {
  const navigate = useNavigate();
  const setPageLoading = useSetLoadingPage();
  const COUPON_ITEMS_PER_PAGE = 10;
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const [formActive] = Form.useForm();
  const [lstCounpon, setLstCounpon] = useState<any[]>([]);
  const [lstShop, setLstShop] = useState<any[]>([]);

  const getDataCounpon = async (shopId: string | null, type: string | null, status: string | null, couponName: string | null, page: number, size: number) => {
    try {
      const response = await couponApis.filterCoupon(shopId ? shopId : null, type ? type : null, status ? status : null, couponName, page, size);
      if (response.status === 200) {
        setLstCounpon(response.data.data.content);
        setTotal(response.data.data.totalElements);
      } else {
        message.error({ content: response.data.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    }
  };

  useEffect(() => {
    const initData = async () => {
      try {
        setPageLoading(true);
        const response = await accountApis.filterSalon(null, null, null, null);
        if (response.status === 200) {
          setLstShop(response.data.data.content);
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
        await getDataCounpon(null, null, null, null, currentPage, COUPON_ITEMS_PER_PAGE);
      } catch (error) {
      } finally {
        setPageLoading(false);
      }
    };
    initData();
  }, []);

  const handleSearch = async () => {
    await onPageChanged(1);
  };

  const onPageChanged = async (pageNumber: number) => {
    setPageLoading(true);
    try {
      setCurrentPage(pageNumber);
      const values = await form.validateFields();
      await getDataCounpon(values.shopId, values.type, values.status, values.couponName, pageNumber, COUPON_ITEMS_PER_PAGE);
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 2 });
    } finally {
      setPageLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <p>SALON<br />NAME</p>,
      dataIndex: 'shopName',
      className: 'shopName',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      className: 'title',
      render: (title: number) =>
        <span>{title ? title : '--'}</span>,
    },
    {
      title: 'PROMOTION',
      dataIndex: 'discountValue',
      className: 'discountValue',
      render: (discountValue: string, record: DataType) =>
        <span>{record.discountType == 'PERCENT' ? discountValue + '%' : '$' + discountValue}</span>,
    },
    {
      title: <p style={{ textAlign: 'center' }}>PRINTED<br />QUANTITY</p>,
      dataIndex: 'totalPrint',
      className: 'totalPrint',
      render: (totalPrint: number, record: DataType) =>
        <div className="progress">
          <div style={{ width: `${(totalPrint / record.quantity) * 100}%` }}
            className="progress-bar bg-warning" role="progressbar" />
          <span>{totalPrint ? totalPrint : 0} | {record.quantity}</span>
        </div>,
    },
    {
      title: 'COUPON USED',
      dataIndex: 'couponUsed',
    },
    {
      title: <p>START<br />DATE</p>,
      dataIndex: 'startTime',
      render: (startTime: string, record: DataType) => <span id={`startTime_${record.couponId}`}>
        <span>{dayjs(startTime).format('MM-DD-YYYY')}</span>
      </span>,
      className: 'startTime',
    },
    {
      title: 'DAYS EXPIRED',
      dataIndex: 'endTime',
      render: (endTime: string, record: DataType) =>
        <span id={`endTime_${record.couponId}`}>
          <span>
            {endTime ? dayjs(endTime).format('MM-DD-YYYY') : '--'}
          </span>
        </span>,
      className: 'endTime',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (status: string, record: DataType) =>
        <Fragment>
          <span id={'status_' + record.couponId} >
            <span className='coupon-label-status'
              style={{
                backgroundColor: status === 'ACTIVE' ? 'rgba(232, 243, 255, 1)' : status === 'PENDING' ? 'rgba(255, 247, 232, 1)' : 'rgba(206, 206, 206, 1)',
              }}>
              {status === 'ACTIVE' ? 'Active' : status === 'PENDING' ? 'Pending' : 'Expired'}
            </span>
          </span>
        </Fragment>

    },
    {
      title: <p style={{ textAlign: 'center' }}>ACTION</p>,
      dataIndex: 'status',
      render: (status: string, item: DataType) =>
        <Fragment>
          {
            <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
              <Switch
                key={item.couponId}
                onChange={(checked) => handleSwitchChange(checked, item.couponId, item.templateType)}
                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                checked={switchStates[item.couponId] === undefined ? status === 'ACTIVE' : switchStates[item.couponId]} />

              <Button className='btn-delete' icon="delete" onClick={() => handleDelete(item.couponId, item.shopName)}></Button>
              <Button className='btn-edit' icon="edit" onClick={() => navigate(`/coupons/detail/${item.couponId}`)}></Button>
            </Box>
          }
        </Fragment>
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDeleteMultiple = async () => {
    if (selectedRowKeys.length <= 0) {
      message.warning({ content: 'Coupon has not been selected yet', duration: 2 });
      return;
    }
    const result = await Swal.fire({
      title: 'Delete multiple Coupons',
      html: 'Are you sure want to delete Coupons?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete them',
      confirmButtonColor: '#d42b3c',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setPageLoading(true);
      try {
        const response = await couponApis.deleteMultiple(selectedRowKeys);
        if (response.status === 200) {
          await onPageChanged(currentPage);
          message.success('Delete multiple Coupons successful');
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleDelete = async (couponId: string, shopName: string) => {
    const result = await Swal.fire({
      title: shopName,
      html: 'Are you sure want to delete Coupon?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      confirmButtonColor: '#d42b3c',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setPageLoading(true);
      try {
        const couponIds: any[] = [
          couponId
        ];
        const response = await couponApis.deleteMultiple(couponIds);
        if (response.status === 200) {
          await onPageChanged(currentPage);
          message.success('Delete coupon successful');
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleCloseEditorModal = () => {
    setShowModalActive(false);
  };

  const [showModalActive, setShowModalActive] = useState(false);
  const initialSwitchStateRef = useRef<any>({});
  const [switchStates, setSwitchStates] = useState<any>({});

  // Hàm để cập nhật trạng thái của Switch
  const updateSwitchState = (itemId: string, checked: any) => {
    setSwitchStates((prevStates: any) => ({
      ...prevStates,
      [itemId]: checked,
    }));
  };

  const [templateSelected, setTemplateSelected] = useState<any>();
  const handleSwitchChange = async (checked: any, itemId: any, templateType: any) => {
    setTemplateSelected(templateType);
    if (checked) {
      formActive.setFieldsValue({
        couponId: itemId
      });
      setShowModalActive(true);
    } else {
      const response = await couponApis.updateStatusCoupon(itemId, 'PENDING');
      if (response.status === 200) {
        message.success('Change Pending successful');
        const element = document.getElementById('status_' + itemId);
        if (element) {
          element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(255, 247, 232);">Pending</span>';
        }
        updateSwitchState(itemId, checked);
      } else {
        updateSwitchState(itemId, initialSwitchStateRef.current[itemId]);
        message.error({ content: response.data.message, duration: 2 });
      }
    }
  };

  const [startDate, setStartDateEntered] = useState<any>(null);
  const handleStartDateChange = (date: any) => {
    setStartDateEntered(date);
  };

  const [expireDate, setExpireDate] = useState('');
  const handleChangeExpireDate = (value: any) => {
    if (!isNaN(value)) {
      value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
    }
    setExpireDate(value);
  };

  const onSubmitUpdateAcvite = async (values: any) => {
    try {
      const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
      const dateStartCheck = dayjs(startDate.format('MM-DD-YYYY'), { format: 'MM-DD-YYYY' });
      if (dateCheck.isBefore(dateStartCheck)) {
        message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
        return;
      }
      setPageLoading(true);
      const itemId = values.couponId;
      const response = await couponApis.updateTime(values.couponId, startDate.format('MM-DD-YYYY'), expireDate);
      if (response.status === 200) {
        message.success('Update time successful');

        // update ui status
        let element = document.getElementById('status_' + itemId);
        if (element) {
          element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(232, 243, 255);">Active</span>';
        }

        // update ui startTime
        element = document.getElementById('startTime_' + itemId);
        if (element) {
          element.innerHTML = `<span>${startDate.format('MM-DD-YYYY')}</span>`;
        }

        element = document.getElementById('endTime_' + itemId);
        if (element) {
          element.innerHTML = `<span>${expireDate}</span>`;
        }

        formActive.resetFields();
        handleCloseEditorModal();
        updateSwitchState(itemId, !initialSwitchStateRef.current[itemId]);
      } else {
        if (initialSwitchStateRef.current[itemId] !== undefined) {
          updateSwitchState(itemId, initialSwitchStateRef.current[itemId]);
        }
        message.error({ content: response.data.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 1 });
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <CouponsPageStyled>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        open={showModalActive}
        onCancel={handleCloseEditorModal}
        onOk={() => formActive.submit()}
        title='Active Coupon'
        closeIcon={null}
        centered>
        {/* Hiển thị trình soạn thảo trong modal */}
        <Form form={formActive} name="formActive"
          layout="vertical"
          onFinish={onSubmitUpdateAcvite}>
          <Box px={1}>
            <FormItemNoMarginStyled
              name="startDate" // Tên của trường trong form
              label='Start Date'
              rules={[
                {
                  required: true
                }
              ]}>
              <DatePicker
                onChange={handleStartDateChange}
                style={{ width: '100%' }}
                size="large"
                format="MM-DD-YYYY"
                showTime={false}
                inputReadOnly={true} />
            </FormItemNoMarginStyled>

            {templateSelected !== 'LOYALTY_POINT' ? (
              <FormItemNoMarginStyled
                name="expiresDate" // Tên của trường trong form
                label='Expires Date'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf={''} />
              </FormItemNoMarginStyled>
            ) : null}

            <FormItemNoMarginStyled name='couponId'>
              <Input type='hidden' />
            </FormItemNoMarginStyled>
          </Box>
        </Form>
      </Modal>

      <Box className="d-between">
        <Text variant="H5">Coupons List</Text>
        <Box display="flex" gap={1}>
          <Button icon="delete" onClick={handleDeleteMultiple}>Delete</Button>
          <Button variant="PRIMARY" icon="add" onClick={() => navigate('/coupons/create')}>
            Create Coupon
          </Button>
        </Box>
      </Box>

      <CouponsContentStyled>
        <Form form={form} name="search">
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="shopId">
                <Select size='large' style={{ width: '100%' }}
                  showSearch optionFilterProp="label"
                  onChange={handleSearch}
                  defaultValue={''}>
                  <Select.Option key={'0'} value=''>{'All Salon'}</Select.Option>
                  {lstShop.map((item: any) => (
                    <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.state} - ${item.zip}`}>
                      {item.shopName} - {item.state} {item.zip}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="type">
                <Select defaultValue={''} size="large" onChange={handleSearch}>
                  <Select.Option key={'0'} value=''>{'All Promotion'}</Select.Option>
                  <Select.Option key={'MONEY'} value='MONEY'>{'Dollar'}</Select.Option>
                  <Select.Option key={'PERCENT'} value='PERCENT'>{'Percent'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="status">
                <Select defaultValue={''} size="large" onChange={handleSearch}>
                  <Select.Option key={'0'} value=''>{'All Status'}</Select.Option>
                  <Select.Option key={'ACTIVE'} value={'ACTIVE'}>{'Active'}</Select.Option>
                  <Select.Option key={'PENDING'} value={'PENDING'}>{'Pending'}</Select.Option>
                  <Select.Option key={'EXPIRED'} value={'EXPIRED'}>{'Expired Date'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item name="couponName">
                <Input size='large' onBlur={handleSearch} placeholder='Coupon Name' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={COUPON_ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>

        <TableContentStyled
          rowSelection={rowSelection}
          columns={columns}
          dataSource={lstCounpon}
          rowKey="couponId"
          pagination={false} />

        <PagingStyled>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={COUPON_ITEMS_PER_PAGE}
            onChange={onPageChanged}
            showSizeChanger={false}
          />
        </PagingStyled>
      </CouponsContentStyled>
    </CouponsPageStyled>
  );
};

const CouponsContentStyled = styled.div`
  background: rgba(255, 255, 255, 1);
  margin-top: 2rem;
  padding: 1.5rem;
`;

const TableContentStyled = styled(Table)`
  && .shopName {
    width: 15%;
  };

  && .title {
    width: 15%;
  }

  && .discountValue {
    width: 5%;
  }

  && .startTime {
    width: 15%;
  }

  && .endTime {
    width: 15%;
  }

  && .progress {
    background-color: #eee;
    border-radius: 10px;
    display: inline-flex;
    font-size: .75rem;
    height: 20px;
    line-height: 0;
    overflow: hidden;
    position: relative;
    width: 104px;
  }

  && .progress span {
    color: rgba(245, 118, 127, 1);
    font-size: 12px;
    font-weight: 700;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    text-align: center;
  }

  && .progress-bar {
    background-color: rgba(255, 229, 231, 1);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: width .6s ease;
    white-space: nowrap;
  }

  && .coupon-label-status {
    border-radius: 2px;
    color: #1d2129;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    height: 28px;
    padding: 4px 8px;
    width: 60px;
  }

  && .btn-delete, .btn-edit {
    background: transparent;
    border: none;
    padding: 8px;
  }

  && .btn-delete:hover {
    background: rgba(245, 118, 127, 1) !important;
    border: none !important;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }
`;


const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
`;

export default CouponsPage;