import { set } from 'lodash';

export interface ISpacing {
    m?: number;
    mx?: number;
    my?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
    p?: number;
    px?: number;
    py?: number;
    pt?: number;
    pb?: number;
    pl?: number;
    pr?: number;
}
const checkValid = (size: number | undefined) => size !== undefined;
const getSize = (size: number | undefined) => checkValid(size) ? (size + 'rem') : undefined;
export const generateCssSpacing = ({
    p,
    px,
    pb,
    pl,
    pr,
    pt,
    py,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
}: ISpacing) => {
    const styles = {};

    if (checkValid(p)) set(styles, 'padding', getSize(p));
    if (checkValid(pt)) set(styles, 'paddingTop', getSize(pt));
    if (checkValid(pr)) set(styles, 'paddingRight', getSize(pr));
    if (checkValid(pb)) set(styles, 'paddingBottom', getSize(pb));
    if (checkValid(pl)) set(styles, 'paddingLeft', getSize(pl));

    if (checkValid(px)) {
        set(styles, 'paddingRight', getSize(px));
        set(styles, 'paddingLeft', getSize(px));
    }

    if (checkValid(py)) {
        set(styles, 'paddingTop', getSize(py));
        set(styles, 'paddingBottom', getSize(py));
    }

    if (checkValid(m)) set(styles, 'margin', getSize(m));
    if (checkValid(mt)) set(styles, 'marginTop', getSize(mt));
    if (checkValid(mr)) set(styles, 'marginRight', getSize(mr));
    if (checkValid(mb)) set(styles, 'marginBottom', getSize(mb));
    if (checkValid(ml)) set(styles, 'marginLeft', getSize(ml));

    if (checkValid(mx)) {
        set(styles, 'marginRight', getSize(mx));
        set(styles, 'marginLeft', getSize(mx));
    }

    if (checkValid(my)) {
        set(styles, 'marginTop', getSize(my));
        set(styles, 'marginBottom', getSize(my));
    }

    return styles;
};