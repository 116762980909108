import React from 'react';
import { AddIconProps } from '..';

const MenuCouponsIcon: React.FC<AddIconProps> = ({ active = false }) => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <svg width={20} height={14} viewBox="0 0 20 14" fill="#566A7F" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.17734 0.531396C2.35234 0.531787 3.52695 0.531005 4.70195 0.531787C4.7043 0.733349 4.69805 0.934912 4.70352 1.13647C4.70859 1.38257 4.88594 1.61069 5.12148 1.68022C5.42109 1.78257 5.77266 1.58882 5.85742 1.28647C5.91328 1.0396 5.8707 0.782568 5.88398 0.531787C10.1969 0.530615 14.5098 0.531787 18.8223 0.531396C18.8277 0.748193 18.8145 0.96538 18.8285 1.18179C18.8531 1.45757 19.098 1.68921 19.3734 1.70444C19.582 1.71499 19.791 1.70522 20 1.70874V12.2919C19.791 12.2947 19.5816 12.2857 19.373 12.2955C19.098 12.3111 18.8523 12.5427 18.8285 12.8185C18.8145 13.0349 18.8277 13.2521 18.8223 13.4689C14.5098 13.4681 10.1969 13.4697 5.88398 13.4685C5.86758 13.2072 5.92188 12.9361 5.84648 12.6818C5.74531 12.3849 5.38398 12.2111 5.09102 12.3302C4.86953 12.4095 4.70781 12.6294 4.70352 12.8654C4.69844 13.0662 4.70391 13.2669 4.70234 13.4681C3.52734 13.4701 2.35234 13.4681 1.17734 13.4689C1.17227 13.2521 1.18594 13.0353 1.17109 12.8189C1.14688 12.5427 0.902344 12.3087 0.625391 12.2955C0.417188 12.2857 0.208594 12.2951 0 12.2916V1.70835C0.218359 1.70405 0.437109 1.71772 0.654688 1.70171C0.919531 1.67515 1.14609 1.4478 1.17109 1.18296C1.18555 0.966162 1.17227 0.748584 1.17734 0.531396ZM2.24805 1.70366C2.0707 2.19858 1.66719 2.60288 1.17227 2.77944C1.17148 5.59351 1.17188 8.40718 1.17188 11.2208C1.66797 11.3962 2.0707 11.8017 2.24805 12.2962C2.7082 12.2978 3.16836 12.2962 3.62852 12.2974C3.78281 11.8736 4.09805 11.5095 4.50352 11.3091C4.99922 11.0544 5.61758 11.0607 6.10938 11.3224C6.50273 11.5251 6.80508 11.8841 6.9582 12.2974C10.5559 12.2958 14.1539 12.2982 17.752 12.2962C17.9281 11.8009 18.3332 11.3986 18.8277 11.2208C18.8281 8.40718 18.8281 5.59351 18.8277 2.77944C18.334 2.60093 17.9277 2.20015 17.7523 1.70405C14.1543 1.7021 10.5559 1.70405 6.95742 1.70288C6.81484 2.08882 6.54258 2.42749 6.18594 2.63491C5.7082 2.92085 5.09141 2.95757 4.58398 2.72866C4.14141 2.53726 3.79297 2.15444 3.62812 1.70288C3.16797 1.70405 2.7082 1.70249 2.24805 1.70366Z" />
                <path d="M9.62684 2.92394C10.0757 2.82707 10.5628 2.91105 10.9483 3.16261C11.4046 3.45129 11.7136 3.9591 11.7546 4.49777C11.7975 4.97277 11.6343 5.46144 11.3155 5.81613C10.9616 6.22004 10.4155 6.44347 9.87997 6.40324C9.36981 6.37199 8.8827 6.10168 8.58153 5.68918C8.27176 5.2759 8.16356 4.72199 8.29012 4.2216C8.44208 3.58175 8.98348 3.0591 9.62684 2.92394ZM9.85067 4.07589C9.53817 4.1505 9.33075 4.50949 9.43583 4.81652C9.52215 5.12746 9.89247 5.32121 10.1952 5.20168C10.4932 5.10519 10.6698 4.74504 10.5557 4.45168C10.4636 4.16965 10.1366 3.99191 9.85067 4.07589Z" />
                <path d="M8.40723 10.1156C10.7604 7.7625 13.1135 5.40937 15.4666 3.05664C15.7432 3.33164 16.0186 3.6082 16.2943 3.88398C13.9436 6.23945 11.5893 8.59141 9.23535 10.9441C8.95918 10.668 8.68301 10.3922 8.40723 10.1156Z" />
                <path d="M4.7073 4.06072C5.09753 4.06072 5.48816 4.06033 5.87839 4.06072C5.87917 4.45135 5.87917 4.84197 5.87839 5.2326C5.48933 5.23416 5.10027 5.22947 4.7112 5.23533C4.70144 4.84392 4.70886 4.45252 4.7073 4.06072Z" />
                <path d="M4.70605 6.41655C5.09746 6.41304 5.48887 6.41343 5.88027 6.41655C5.87871 6.80562 5.87832 7.19468 5.88066 7.58413C5.48926 7.58882 5.09785 7.58687 4.70645 7.58491C4.70762 7.19546 4.70801 6.80601 4.70605 6.41655Z" />
                <path d="M14.5056 7.60351C14.9794 7.54765 15.4747 7.69335 15.8384 8.0039C16.2509 8.34608 16.4915 8.88476 16.4669 9.42069C16.4509 9.93671 16.1903 10.4359 15.781 10.7488C15.4087 11.0402 14.9149 11.1668 14.4485 11.0957C13.9212 11.0207 13.4399 10.6891 13.1778 10.2266C12.9278 9.79648 12.8774 9.25819 13.0384 8.78827C13.2474 8.15546 13.8419 7.67265 14.5056 7.60351ZM14.6181 8.76952C14.3017 8.81288 14.0606 9.14296 14.1263 9.45741C14.1778 9.77929 14.5286 10.0144 14.8454 9.92616C15.147 9.85976 15.3579 9.52929 15.2833 9.22812C15.2243 8.9328 14.9153 8.71796 14.6181 8.76952Z" />
                <path d="M4.7041 8.77187C5.09434 8.76016 5.48496 8.77266 5.87559 8.76562C5.88262 9.15664 5.87715 9.54805 5.87832 9.93906C5.48809 9.93984 5.09746 9.93945 4.70723 9.93945C4.70449 9.55 4.71035 9.16094 4.7041 8.77187Z" />
            </svg>
        </span>
    );
};
export default MenuCouponsIcon;
