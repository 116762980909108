import React from 'react';
import { AddIconProps } from '..';
const DeleteIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00151 1.96973C8.00197 1.44801 8.47494 0.990046 8.99526 1.00083C11.0113 1.00036 13.0274 0.998015 15.0431 1.00223C15.5507 1.0102 15.996 1.46208 15.9979 1.96973C16.0031 2.64661 15.9974 3.32348 16.0007 4.00036C17.3249 3.99989 18.6492 4.00036 19.9729 3.99989C20.3081 3.99005 20.6409 4.15927 20.827 4.43864C21.0412 4.74708 21.0562 5.17833 20.8645 5.5013C20.6868 5.81489 20.3301 6.0113 19.9701 6.00005C14.6568 6.00005 9.34307 6.00005 4.02979 6.00005C3.73494 6.00801 3.44057 5.88145 3.24791 5.65786C3.00838 5.3902 2.93479 4.98895 3.06229 4.6538C3.17151 4.35098 3.43682 4.11098 3.74901 4.03223C3.90182 3.99051 4.06166 4.00083 4.21869 3.99989C5.47869 4.00036 6.73916 3.99989 7.99916 4.00036C8.00197 3.32348 7.99682 2.64661 8.00151 1.96973ZM9.99979 3.00051C10.0017 3.33426 9.99697 3.66755 10.0021 4.00083C11.3348 3.99895 12.667 4.00036 13.9992 4.00036C14.001 3.66708 13.9992 3.3338 14.0001 3.00098C12.6665 2.99958 11.3334 2.99958 9.99979 3.00051Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M4.65789 7.05981C5.13039 6.87606 5.71117 7.12121 5.91039 7.58668C6.01023 7.79246 6.00227 8.02543 5.99992 8.24856C5.99992 11.8748 5.99992 15.5015 5.99992 19.1278C6.00648 19.5717 6.17805 20.0104 6.47148 20.3432C6.82023 20.7445 7.34148 20.9934 7.87492 20.9995C10.6091 21.0004 13.3434 20.9995 16.0776 21.0004C16.582 21.0051 17.0835 20.7975 17.4388 20.4398C17.7848 20.0957 17.9929 19.6157 17.9995 19.1273C18.0004 15.4228 17.9995 11.7178 18.0004 8.01324C17.9934 7.67715 18.1691 7.34527 18.4523 7.16387C18.7288 6.97824 19.1001 6.94871 19.4034 7.08512C19.7249 7.2234 19.9588 7.54309 19.993 7.89137C20.0043 8.02637 19.9991 8.16184 20.0001 8.29731C20.0001 11.8284 19.9996 15.3595 20.0001 18.8911C20.0605 20.1257 19.506 21.3722 18.5338 22.1386C17.8579 22.6861 16.9935 22.9945 16.1235 22.9996C13.3734 23.0001 10.6232 23.0001 7.87258 22.9996C6.91633 22.9945 5.97039 22.6172 5.2682 21.9689C4.47742 21.2503 4.00305 20.1965 4.00023 19.1268C3.99977 15.4223 3.99977 11.7173 4.00023 8.01231C3.98852 7.59699 4.26695 7.19809 4.65789 7.05981Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M9.76916 9.02718C10.0823 8.95124 10.4292 9.03843 10.6673 9.2564C10.8848 9.44812 11.0067 9.7378 10.9996 10.0275C11.0001 12.3426 11.0001 14.6578 11.0001 16.973C11.0099 17.3198 10.8271 17.6644 10.5304 17.8462C10.199 18.0605 9.74009 18.0478 9.42134 17.8148C9.15134 17.6269 8.99009 17.2997 8.99994 16.9716C9.00088 14.6414 8.99806 12.3112 9.00134 9.98155C8.99994 9.53671 9.33603 9.1228 9.76916 9.02718Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M13.7568 9.03C14.069 8.95031 14.4177 9.03328 14.6582 9.24796C14.8818 9.44015 15.0069 9.73453 14.9999 10.028C14.9999 12.3408 14.9999 14.6536 14.9999 16.9664C15.0098 17.2852 14.8607 17.6039 14.6048 17.7952C14.3493 17.993 13.9954 18.052 13.6893 17.9498C13.3701 17.8477 13.1123 17.5744 13.0321 17.2491C12.9904 17.0962 13.0007 16.9364 12.9998 16.7798C13.0012 14.5139 12.9983 12.2484 13.0012 9.9825C12.9998 9.54187 13.3288 9.13031 13.7568 9.03Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default DeleteIcon;
