import React, { Fragment, useEffect, useRef, useState } from 'react';
import CreateGiftCardPageStyled from '../../giftCard/pages/styles';
import { Col, Divider, Form, Input, InputRef, Row, Select, Space, Switch, message } from 'antd';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import Box from 'components/Box';
import Text from 'components/Text';
import Button from 'components/Button';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import giftCardApis from '../../giftCard/services/apis';
import giftCardData from '../../../mockdata/_gift_card_name.json';
import SelectOrDatePicker from 'components/Modal/SelectOrDatePicker';
import dayjs from 'dayjs';

export const SalonCreateGiftCardPage = () => {
    const navigate = useNavigate();
    const [formCreate] = Form.useForm();
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [listImage, setListImage] = useState<any[]>([]);

    const inputRef = useRef<InputRef>(null);
    const [giftCardDataItems, setgiftCardDataItems] = useState<any>([]);
    const [newGiftCardItems, setNewGiftCardItems] = useState('');

    useEffect(() => {
        const initData = async () => {
            if (id) {
                const response = await giftCardApis.getListLibraryImg();
                if (response.status === 200) {
                    setListImage(response.data.data);
                }
                const giftCardDataItemsTemp: any[] = [];
                giftCardData.map((item: any) => (
                    giftCardDataItemsTemp.push(item.value)
                ));
                setgiftCardDataItems(giftCardDataItemsTemp);
            }
        };
        initData();
    }, []);

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (!newGiftCardItems) {
            return;
        }
        giftCardDataItems.unshift(newGiftCardItems);
        setgiftCardDataItems([...giftCardDataItems]);
        setNewGiftCardItems('');
    };

    const onNewPromotionItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewGiftCardItems(event.target.value);
    };

    const [expireDate, setExpireDate] = useState<any>();
    const startDate = dayjs();
    const handleChangeExpireDate = (value: any) => {
        if (!isNaN(value)) {
            value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
        }
        setExpireDate(value);
    };

    const [urlImage, SetUrlImage] = useState('');
    const onSubmit = async (values: any) => {
        try {
            if (urlImage === '') {
                message.error({ content: 'Thumbnail is requied', duration: 2 });
                return;
            }
            let totalExpireDate = 0;
            const currentDate = dayjs();
            const expireDateTem = dayjs(expireDate, 'MM-DD-YYYY');
            totalExpireDate = expireDateTem.diff(currentDate, 'day') + 1;
            if (totalExpireDate <= 0) {
                message.error({ content: 'Expires Date must to > Current Date', duration: 2 });
                return;
            }
            setPageLoading(true);
            const response = await giftCardApis.CreateOrUpdateTemplate(null, true, totalExpireDate, values.amountOptions, urlImage, values.giftCardName);
            if (response.status === 200) {
                message.success({ content: 'Create Success' as string, duration: 2 });
                navigate(`/gift-card/setting/${id}#GIFT_CARDS_ONLINET_TAB`);
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
                setPageLoading(false);
            }
        } catch (error: any) {
            setPageLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <CreateGiftCardPageStyled>
            <Form form={formCreate}
                layout="vertical"
                onFinish={onSubmit}>
                <Box className="d-between">
                    <Text variant="H5">Create Gift Card</Text>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate(`/accounts/salon/${id}#GIFT_CARDS_ONLINET_TAB`)}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formCreate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <ContentStyled>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} style={{ borderRight: '1px solid rgba(218, 218, 218, 1)' }}>
                            <Box style={{ background: 'white' }} p={1.5}>
                                <Text variant="H5">Thumbnail Preview</Text>
                                <Box style={{ background: 'white', borderRadius: '5px', textAlign: 'center' }}>
                                    <div style={{ backgroundColor: 'rgba(244, 248, 251, 1)', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', padding: '5px', marginTop: '20px' }}>
                                        <div style={{
                                            padding: '10px', color: '#000000', backgroundColor: 'rgba(244, 248, 251, 1)',
                                            border: '1px dashed #000', strokeDasharray: '10px', minWidth: '250px', minHeight: '177px', borderRadius: '5px',
                                        }}>
                                            {
                                                urlImage == '' ?
                                                    (
                                                        <Fragment>
                                                            <img id='dataImage' src='/images/icon_image.png' />
                                                            <Text variant="H8" style={{ color: 'rgba(86, 106, 127, 1)' }}>Drag your photos here</Text>
                                                            <Text variant="H8" style={{ color: 'rgba(86, 106, 127, 1)' }}>to start uploading.</Text>
                                                            <Divider style={{ color: 'rgba(86, 106, 127, 1)' }}>Or</Divider>
                                                            <Button variant='SECONDARY' size="large" onClick={() => message.warning({ content: 'Feature is being completed', duration: 2 })}>
                                                                Choose Image
                                                            </Button>
                                                        </Fragment>
                                                    )
                                                    :
                                                    (
                                                        <img id='dataImage' src={urlImage} />
                                                    )
                                            }
                                        </div>
                                    </div>
                                </Box>
                                <Box mt={3}>
                                    <Text variant="H5">Reccomment Photos</Text>
                                    <Row gutter={10}>
                                        {listImage?.map((item: any, index) => (
                                            <Col key={index} span={8} style={{ paddingTop: '20px' }}>
                                                <BoxTemplateStyled>
                                                    <Box p={0.2}>
                                                        <img src={item.urlImage} width={'100%'} onClick={() => SetUrlImage(item.urlImage)} />
                                                    </Box>
                                                </BoxTemplateStyled>
                                            </Col>
                                        ))}
                                    </Row>
                                </Box>
                            </Box>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 14 }}>
                            <Box style={{ background: 'white' }} p={1.5}>
                                <Box style={{ display: 'flex' }}>
                                    <Form.Item name="activePrint" valuePropName="checked">
                                        <Switch style={{ textAlign: 'right' }} title='Activate Print Coupon' defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                    </Form.Item>
                                    <Text variant="H6" style={{ fontWeight: 'bold' }} ml={0.5}>Activate Print Coupon</Text>
                                </Box>

                                <Form.Item
                                    name="giftCardName"
                                    label="Gift Card Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Gift Card Name is required',
                                        }
                                    ]}>
                                    <Select
                                        placeholder="Select"
                                        size="large"
                                        showSearch optionFilterProp="label"
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space style={{ padding: '0 8px 4px', textAlign: 'center' }}>
                                                    <Input
                                                        size="large"
                                                        placeholder="Please enter item"
                                                        ref={inputRef}
                                                        value={newGiftCardItems}
                                                        onChange={onNewPromotionItems}
                                                    />
                                                    <Button variant='SECONDARY' size="large" onClick={addItem} icon="add">
                                                        Add item
                                                    </Button>
                                                </Space>
                                            </>
                                        )}>
                                        <Select.Option key={''} value={''}>{''}</Select.Option>
                                        {giftCardDataItems?.map((item: any) => (
                                            <Select.Option key={item} value={item} label={item}>{item}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="amountOptions"
                                    label="Amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Amount is required',
                                        }
                                    ]}>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select"
                                        size="large" >
                                        <Select.Option value={50}>$50.00</Select.Option>
                                        <Select.Option value={100}>$100.00</Select.Option>
                                        <Select.Option value={200}>$200.00</Select.Option>
                                        <Select.Option value={500}>$500.00</Select.Option>
                                        <Select.Option value={1000}>$1000.00</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="expiresDate" // Tên của trường trong form
                                    label='Expires Date'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Expires date is required',
                                        }
                                    ]}>
                                    <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf='' />
                                </Form.Item>
                            </Box>
                        </Col>
                    </Row>
                </ContentStyled>

            </Form>
        </CreateGiftCardPageStyled >
    );
};

const ContentStyled = styled.div`
  background: rgba(238, 238, 238, 1);
  margin-top: 1rem;
  
`;

const BoxTemplateStyled = styled(Box)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;