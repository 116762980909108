
import { takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { ISagaFunc } from 'services/actionConfigs';
import { ILoginResData } from './types/login';
import storage from 'utils/sessionStorage';

const afterLogin: ISagaFunc<ILoginResData> = function* ({ payload }) {
  yield storage.token.set(payload.accessToken as string);
};

export default function* authServiceSagas() {
  yield takeLatest(actions.afterLogin.success, afterLogin);
}
