import { Col, Input, Row, Select, Layout, message, Pagination, Form, Checkbox } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React, { Fragment, useEffect, useState } from 'react';
import AddAccountButton from '../components/AddAccountButton';
import AddSalonButton from '../components/AddSalonButton';
import SalonItem from '../components/SalonItem';
import AccountPageStyled from './styles';
import accountApis from 'features/account/services/apis';
import styled from 'styled-components';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import Swal from 'sweetalert2';
import Modal from 'components/Modal';
import { initialAccountDevices } from 'features/account/services/constants';
import FormItemNoMarginStyled from 'components/FormItem/FormItemNoMarginStyled';
import SelectDevices from '../components/SelectDevices';
const { Content } = Layout;

const AccountPage = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [lstAccount, setLstAccount] = useState<any[]>([]);
  const [lstTimezone, setLstTimezone] = useState<any[]>([]);
  const [listSalon, setLstSalon] = useState<any[]>([]);
  const setPageLoading = useSetLoadingPage();

  const SALON_MAX_ITEMS_PER_PAGE = 12;
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getDataShop = async (accountId: string | null, shopName: string | null, page: number, size: number) => {
    try {
      const response = await accountApis.filterSalon(accountId, shopName, page, size);
      if (response.status === 200) {
        setLstSalon(response.data.data.content);
        setTotal(response.data.data.totalElements);
      } else {
        message.error({ content: response.data.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    }
  };

  useEffect(() => {
    const initData = async () => {
      try {
        setPageLoading(true);
        const resAccounts = await accountApis.getAll();
        if (resAccounts.status === 200) {
          setLstAccount(resAccounts.data.data);
        } else {
          message.error({ content: resAccounts.data.message, duration: 2 });
        }

        const resTimeZone = await accountApis.getTimeZone();
        if (resTimeZone.status === 200) {
          setLstTimezone(resTimeZone.data.data);
        }

        await getDataShop(null, null, currentPage, SALON_MAX_ITEMS_PER_PAGE);
      } catch (error: any) {
        message.error({ content: 'System error: ' + error.message as string, duration: 3 });
      } finally {
        setPageLoading(false);
      }
    };
    initData();
  }, []);

  const onPageChanged = async (pageNumber: number) => {
    setPageLoading(true);
    try {
      const values = await form.validateFields();
      setCurrentPage(pageNumber);
      await getDataShop(values.accountId, values.shopName, pageNumber, SALON_MAX_ITEMS_PER_PAGE);
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 2 });
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = async () => {
    await onPageChanged(1);
  };

  const handleDeleteSalon = async (shopId: string, shopName: string) => {
    const result = await Swal.fire({
      title: shopName,
      html: 'Are you sure want to delete this Salon ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      confirmButtonColor: '#d42b3c',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      setPageLoading(true);
      try {
        const response = await accountApis.deleteShop(shopId);
        if (response.status === 200) {
          await onPageChanged(currentPage);
          message.success('Delete Salon successful');
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleAddSalon = async (values: any): Promise<boolean> => {
    try {
      setPageLoading(true);
      const deviceFeatures = values?.devices?.map((item: any) => ({
        feature: item.feature,
        numberDevice: Number(item.numberDevice) || 0,
      }));
      const response = await accountApis.addSalon(values.accountId, values.salon_name, values.time_zone, values.phone,
        values.email, values.street, values.city, values.state, values.zip,
        values.password ? values.password : '123456789',
        values.bookingOnline, values.giftOnline, deviceFeatures);
      if (response.status === 200) {
        await onPageChanged(1);
        message.success({ content: 'Add Salon success' as string, duration: 2 });
        return true;
      } else {
        message.error({ content: response.data.message as string, duration: 2 });
        return false;
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
      return false;
    } finally {
      setPageLoading(false);
    }
  };

  const handleAddAccount = async (values: any): Promise<boolean> => {
    try {
      setPageLoading(true);
      const deviceFeatures = values?.devices?.map((item: any) => ({
        feature: item.feature,
        numberDevice: Number(item.numberDevice) || 0,
      }));
      const response = await accountApis.addAccount(values.salon_name, values.time_zone, values.phone, values.firsName,
        values.lastName, values.email, values.street, values.city, values.state, values.zip,
        values.password ? values.password : '123456789',
        values.bookingOnline, values.giftOnline, deviceFeatures);
      if (response.status === 200) {
        await onPageChanged(1);
        message.success({ content: 'Add Account success' as string, duration: 2 });
        return true;
      } else {
        message.error({ content: response.data.message as string, duration: 2 });
        return false;
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
      return false;
    } finally {
      setPageLoading(false);
    }
  };

  const handleGenLink = async (shopId: string, type: string, shopName: string) => {
    const result = await Swal.fire({
      title: shopName,
      html: `Are you sure want to generate ${type === 'BOOKING' ? 'Booking' : 'Gift Card'} link?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, generate it',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      setPageLoading(true);
      try {
        const response = await accountApis.genLinkShop(shopId, type);
        if (response.status === 200) {
          await onPageChanged(currentPage);
          message.success('Generate successful');
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 1 });
      } finally {
        setPageLoading(false);
      }
    }
  };

  const [formUpdateSalon] = Form.useForm();
  const [showModalActive, setShowModalActive] = useState(false);
  const [listDevice, setListDevice] = useState<any>(null);
  const watchDevices = Form.useWatch('devices', formUpdateSalon);

  const handleUpdateSalon = async (shopId: string) => {
    setPageLoading(true);
    const response = await accountApis.genShopById(shopId);
    if (response.status === 200) {
      const data = response.data.data;
      const updatedDevices = initialAccountDevices.map(device => {
        const feature = data.deviceFeatures.find((f: any) => f.feature === device.feature);
        if (feature) {
          return {
            ...device,
            numberDevice: feature.numberDevice,
            enable: true,
            id: feature.id,
            shopId: feature.shopId
          };
        }
        return device;
      });

      setListDevice(updatedDevices);
      formUpdateSalon.setFieldsValue({
        shopId: data.id,
        shopName: data.shopName,
        address: data.address,
        email: data.email,
        phone: data.phone,
        zip: data.zip,
        city: data.city,
        state: data.state,
        bookingOnline: data.bookingLink ? true : false,
        giftOnline: data.giftOnlineLink ? true : false,
        devices: updatedDevices,
        timeZone: data.timeZone
      });
      setShowModalActive(true);
      setPageLoading(false);
    } else {
      message.error({ content: response.data.message as string, duration: 2 });
    }
  };
  const handleCloseEditorModal = () => {
    setShowModalActive(false);
  };

  const onSubmitUpdateSalon = async (values: any) => {
    try {
      setPageLoading(true);
      const deviceFeatures = values?.devices?.filter((item: any) => item.enable)?.map((item: any) => ({
        id: item.id,
        feature: item.feature,
        shopId: item.shopId,
        numberDevice: Number(item.numberDevice) || 0,
      }));

      const response = await accountApis.updateSalon(
        values.shopId, values.shopName, values.timeZone, values.phone, values.email, values.address,
        values.city, values.state, values.zip, deviceFeatures, values.giftOnline, values.bookingOnline
      );
      if (response.status === 200) {
        await onPageChanged(currentPage);
        message.success({ content: 'Update Salon success' as string, duration: 2 });
      } else {
        message.error({ content: response.data.message as string, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 3 });
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <AccountPageStyled>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        open={showModalActive}
        onCancel={handleCloseEditorModal}
        onOk={() => formUpdateSalon.submit()}
        title='Update info Salon'
        width={800}
        closeIcon={null}
        centered>
        <Form
          form={formUpdateSalon}
          layout="vertical"
          onFinish={onSubmitUpdateSalon}
          initialValues={{ devices: listDevice }}>
          <FormItemNoMarginStyled name="shopId">
            <Input type='hidden' />
          </FormItemNoMarginStyled>
          <Box px={1}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="shopName" label="Salon’s Name"
                  rules={
                    [
                      { required: true }
                    ]
                  }
                >
                  <Input size='large' autoComplete='off' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="address" label="address"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Input size='large' autoComplete='off' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="email" label="Email" rules={
                  [
                    { required: true },
                    {
                      type: 'email',
                      message: 'The input is not valid email',
                    }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled
                  name="phone"
                  label="Phone"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Input size='large' type="number" autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="zip" label="Zip Code" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="city" label="City" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="state" label="State" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <SelectDevices watchDevices={watchDevices} />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="timeZone" label="Timezone"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Select size='large' showSearch placeholder="Select">
                    {lstTimezone?.map((tz, index) => (
                      <Option key={index} value={tz.value}>
                        {tz.name}
                      </Option>
                    ))}
                  </Select>
                </FormItemNoMarginStyled>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemCheckBoxStyled name="bookingOnline" valuePropName="checked" label="Booking Online">
                      <Checkbox>Booking Online</Checkbox>
                    </FormItemCheckBoxStyled>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemCheckBoxStyled name="giftOnline" valuePropName="checked" label="Gift Cards Online">
                      <Checkbox>Gift Cards Online</Checkbox>
                    </FormItemCheckBoxStyled>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Form>
      </Modal>
      {lstAccount ?
        (
          <Fragment>
            <Box className="d-between">
              <Text variant="H5">Accounts</Text>
              <Box display="flex" gap={1}>
                {/* <Button icon="delete">Delete</Button> */}
                <AddSalonButton lstAccount={lstAccount} handleAddSalon={handleAddSalon} lstTimeZone={lstTimezone} />
                <AddAccountButton handleAddAccount={handleAddAccount} lstTimeZone={lstTimezone} />
              </Box>
            </Box>

            <Form form={form} name="search">
              <Box mt={2.5} mb={1}>
                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item name="accountId">
                      <Select size='large' style={{ width: '100%' }} placeholder="All Account"
                        showSearch optionFilterProp="label"
                        onChange={handleSearch}>
                        <Select.Option value={''} label={'All Accounts'}>
                          All Accounts
                        </Select.Option>
                        {lstAccount.map((item: any) => (
                          <Select.Option key={item.id} value={item.id} label={`${item.firstName} ${item.lastName} - ${item.email}`}>
                            {item.firstName} {item.lastName} - {item.email}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item name="shopName">
                      <Input size='large' placeholder='Input Shop Name' onBlur={handleSearch} autoComplete='off' />
                    </Form.Item>
                  </Col>
                </Row>
              </Box>
              <Box mt={1}>
                <PagingStyled>
                  <Pagination
                    current={currentPage}
                    total={totalItems}
                    pageSize={SALON_MAX_ITEMS_PER_PAGE}
                    onChange={onPageChanged}
                    showSizeChanger={false}
                  />
                </PagingStyled>

                <Row gutter={[16, 16]}>
                  {listSalon?.map((item: any, index) => (
                    <Col key={index} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
                      <Box>
                        <SalonItem salonData={item} handleDeleteSaLon={handleDeleteSalon} handleGenLink={handleGenLink} handleUpdateSalon={handleUpdateSalon} />
                      </Box>
                    </Col>
                  ))}
                </Row>

                <PagingStyled>
                  <Pagination
                    current={currentPage}
                    total={totalItems}
                    pageSize={SALON_MAX_ITEMS_PER_PAGE}
                    onChange={onPageChanged}
                    showSizeChanger={false}
                  />
                </PagingStyled>
              </Box>
            </Form>

          </Fragment>
        )
        :
        (
          <Fragment>
            <ContentImgStyled>
              <img src="/images/account_bg.png" alt="Logo" />
              <p>Accounts</p>
              <AddAccountButton handleAddAccount={handleAddAccount} lstTimeZone={lstTimezone} />
            </ContentImgStyled>
          </Fragment>
        )
      }
    </AccountPageStyled>
  );
};

const ContentImgStyled = styled(Content)`
  text-align: center;
    && img {
      margin-top: 100px;
    }

    && p {
      font-size: 24px;
      font-weight: 500;
      margin: 10px 0px 10px 0px;
    }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FormItemCheckBoxStyled = styled(Form.Item)`
  margin: 10px;
  && .ant-form-item-label label {
    color: transparent;
  }
`;

export default AccountPage;
