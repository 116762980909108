
import { message } from 'antd';

export const copyClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    message.success({ content: 'Copied', duration: 1 });
};

export const formatDecimal = (number: any) => {
    number = number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const numberString = number.toString();
    const decimalPart = numberString.split('.')[1];
    return decimalPart ? decimalPart : '00';
};

export const currencyFormat = (value: any, currency: any) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    }).format(value);
    return formattedValue;
};

const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};


export const generateRandomNumberString = (length: number): string => {
    if (length <= 0) {
        throw new Error('Độ dài chuỗi phải lớn hơn 0');
    }

    let randomNumberString = '';
    for (let i = 0; i < length; i++) {
        const randomNumber = getRandomNumber(0, 9); // Số ngẫu nhiên từ 0 đến 9
        randomNumberString += randomNumber.toString();
    }
    return randomNumberString;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return phoneNumber;
  };