import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, rootSaga } from './root';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setAuthToken } from 'utils/axiosInterceptor';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'], // Danh sách các reducer bạn muốn lưu trữ
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// disable thunk and add redux-saga middleware
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];
  },
});

sagaMiddleware.run(rootSaga);
const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

let currentState = store.getState() as RootState;
store.subscribe(() => {
  // keep track of the previous and current state to compare changes
  const previousState = currentState;
  currentState = store.getState() as RootState;
  // if the token changes set the value in localStorage and axios headers
  if (previousState.auth.accessToken !== currentState.auth.accessToken) {
    const token = currentState.auth.accessToken;
    if (token) {
      setAuthToken(token);
    }
  }
});

export { store, persistedStore };
