import GiftCardPageStyled from '../../giftCard/pages/styles';
import { Col, Form, Input, Row, Select, Switch, Tabs, message, Checkbox, InputNumber, Pagination } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import giftCardApis from '../../giftCard/services/apis';
import Swal from 'sweetalert2';
import accountApis from 'features/account/services/apis';
import { LeftOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseOutlined, SaveOutlined, EyeInvisibleOutlined, EyeTwoTone, DownloadOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import Modal from 'components/Modal';
import FormItemNoMarginStyled from 'components/FormItem/FormItemNoMarginStyled';
import { initialAccountDevices } from 'features/account/services/constants';
import SelectDevices from '../components/SelectDevices';
import { currencyFormat, formatPhoneNumber } from 'utils/FunctionUtils';
import { TableRowSelection } from 'antd/es/table/interface';
import { Content } from 'antd/es/layout/layout';

const { Option } = Select;

export const SalonSettingPage = () => {
    const navigate = useNavigate();

    const [tabDefaulf, setTabDefaulf] = useState<any>('SALON_INFORMATION');
    const onChange = (key: string) => {
        setTabDefaulf(key);
    };
    const { id } = useParams();
    const [shopDate, setShopDate] = useState<any>(null);

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !shopDate) {
                    const response = await accountApis.genShopById(id);
                    if (response.status === 200) {
                        setShopDate(response.data.data);
                    }
                }
                const tabFromHash = window.location.hash.substring(1);
                if (tabFromHash) {
                    onChange(tabFromHash);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    return (
        <GiftCardPageStyled>
            <Box className="d-between">
                <Text variant="H5">Salon {shopDate?.shopName}</Text>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate('/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>
            <ContentStyled>
                <Tabs
                    activeKey={tabDefaulf}
                    onChange={onChange}
                    items={[
                        {
                            label: 'SALON INFORMATION',
                            key: 'SALON_INFORMATION',
                            children: <SALON_INFORMATION />,
                        },
                        {
                            label: 'CUSTOMERS',
                            key: 'CUSTOMERS_TAB',
                            children: <CUSTOMERS_TAB />,
                        },
                        {
                            label: 'GIFT CARDS ONLINE',
                            key: 'GIFT_CARDS_ONLINET_TAB',
                            children: <GIFT_CARDS_ONLINET_TAB />,
                        },
                        {
                            label: 'MARKETING',
                            key: 'MARKETING_TAB',
                            children: <MARKETING_TAB />,
                        },
                        {
                            label: 'CREDIT CARD',
                            key: 'CREDIT_CARD',
                            children: <CREDIT_CARD />,
                        }
                    ]}
                />
            </ContentStyled>
        </GiftCardPageStyled>
    );
};

export const CUSTOMERS_TAB = () => {
    const [formSearch] = Form.useForm();
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [lstGroupByShopId, setLstGroupByShopId] = useState<any>(null);
    const [lstCustomer, setLstCustomer] = useState<any[]>([]);
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getDataCustomer = async (shopId: any, groupId: string | null, pointFrom: string | null, pointTo: string | null,
        sendingAmountFrom: string | null, sendingAmountTo: string | null, types: any, lastPhoneNumber: string | null, page: number, size: number) => {
        try {
            const response = await accountApis.filterCustomerByShopId(
                shopId, groupId, pointFrom, pointTo, sendingAmountFrom, sendingAmountTo, types, lastPhoneNumber, page, size
            );
            if (response.status === 200) {
                setLstCustomer(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !lstGroupByShopId) {
                    setPageLoading(true);
                    const response = await accountApis.allCustomerGroupListByShopId(id);
                    if (response.status === 200) {
                        setLstGroupByShopId(response.data.data);
                    }
                    await getDataCustomer(id, null, null, null, null, null, null, null, currentPage, COUPON_ITEMS_PER_PAGE);
                    setPageLoading(false);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        setPageLoading(true);
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataCustomer(id, values.groupId, values.pointFrom, values.pointTo, values.sendingAmountFrom
                , values.sendingAmountTo, values.types, values.lastPhoneNumber, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            setPageLoading(false);
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>NAME</p>,
            dataIndex: 'name',
            className: 'name',
            render: (name: string) => <a>{name}</a>,
        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
            className: 'phone',
            render: (phone: string) =>
                <span>{formatPhoneNumber(phone)}</span>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            className: 'email',
            render: (email: string) =>
                <span>{email ? email : '--'}</span>,
        },
        {
            title: 'DOB',
            dataIndex: 'birthday',
            className: 'birthday',
            render: (birthday: string) =>
                <span>{birthday ? birthday : '--'}</span>
        },
        {
            title: 'POINT',
            dataIndex: 'point',
            className: 'point',
            render: (point: number) =>
                <span>{point}</span>,
        },
        {
            title: <p>AMOUNT<br />SPENT</p>,
            dataIndex: 'amountSpent',
            className: 'amountSpent',
            render: (amountSpent: string) =>
                <span>{currencyFormat(amountSpent, 'USD')}</span>
        },
        {
            title: <p>CREATED<br />DATE</p>,
            dataIndex: 'createdDate',
            className: 'createdDate',
            render: (createdDate: string) =>
                <span>{createdDate}</span>
        },
        {
            title: 'VISITED',
            dataIndex: 'numberVisit',
            className: 'numberVisit',
            render: (numberVisit: string) =>
                <span>{numberVisit}</span>,
        },
        {
            title: <p>LAST<br />VISITED</p>,
            dataIndex: 'lastVisitedDate',
            className: 'lastVisitedDate',
            render: (lastVisitedDate: string) =>
                <span>{lastVisitedDate}</span>,
        },
        {
            title: 'TYPE',
            dataIndex: 'status',
            className: 'status',
            render: (status: string) =>
                <CustomerTypeStyled
                    style={{
                        backgroundColor:
                            status === 'NEW'
                                ? 'rgba(254, 237, 191, 1)'
                                : status === 'REGULAR'
                                    ? 'rgba(198, 233, 239, 1)'
                                    : 'rgba(255, 229, 231, 1)',
                    }}
                >
                    {status}
                </CustomerTypeStyled>

        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const resetRowSelection = () => {
        setSelectedRowKeys([]);
    };

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    const [showModalAddGroup, setModalAddGroup] = useState(false);
    const [formNewGroup] = Form.useForm();
    const onSubmitAddGroup = async (values: any) => {
        try {
            if (selectedRowKeys.length == 0) {
                message.error({ content: 'Please select the Customers', duration: 2 });
                return;
            }
            setPageLoading(true);
            const response = await accountApis.addGroupCustomerByShopId(id, selectedRowKeys, values.groupName);
            if (response.status === 200) {
                message.success('Add new group successful');
                resetRowSelection();
                formNewGroup.resetFields();
                const response = await accountApis.allCustomerGroupListByShopId(id);
                if (response.status === 200) {
                    setLstGroupByShopId(response.data.data);
                }
                setModalAddGroup(false);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false);
        }
    };

    const exportCustomer = async () => {
        try {
            setPageLoading(true);
            const values = await formSearch.validateFields();
            const response = await accountApis.exportExcelCustomer(id, values.groupId, values.pointFrom, values.pointTo,
                values.sendingAmountFrom, values.sendingAmountTo, values.types, values.lastPhoneNumber);
            if (response.status === 200) {
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
                const fileName = `export_${timestamp}.xlsx`;

                anchorElement.download = fileName;
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                message.success('Export successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <div>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showModalAddGroup}
                onCancel={() => setModalAddGroup(false)}
                onOk={() => formNewGroup.submit()}
                title='Group’s Name'
                closeIcon={null}
                centered>
                {/* Hiển thị trình soạn thảo trong modal */}
                <Form form={formNewGroup}
                    layout="vertical"
                    onFinish={onSubmitAddGroup}>
                    <Box px={1}>
                        <FormItemNoMarginStyled
                            name="groupName"
                            rules={[
                                {
                                    required: true
                                }
                            ]}>
                            <Input size='large' placeholder='Please enter' autoComplete='off' />
                        </FormItemNoMarginStyled>
                    </Box>
                </Form>
            </Modal>

            <Box className="d-between" mb={2}>
                <Text variant="H5">Customer List</Text>
                <Box display="flex" gap={1}>
                    <Button variant="SECONDARY" icon="add" onClick={() => setModalAddGroup(true)}>
                        Add Group
                    </Button>
                    <Button variant="PRIMARY" onClick={() => exportCustomer()}>
                        <DownloadOutlined />Export Customer
                    </Button>
                </Box>
            </Box>

            <Form form={formSearch}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="groupId">
                            <Select size='large' style={{ width: '100%' }}
                                showSearch optionFilterProp="label"
                                defaultValue={''}
                                onChange={handleSearch}
                            >
                                <Select.Option key={'0'} value=''>{'All Groups'}</Select.Option>
                                {lstGroupByShopId?.map((item: any) => (
                                    <Select.Option key={item.id} value={item.id} label={`${item.groupName}`}>
                                        {item.groupName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Input.Group compact>
                            <InputNumber
                                name="sendingAmountFrom" // Thêm name cho ô nhập từ
                                style={{ width: '50%' }}
                                placeholder="Sending Amount From"
                                min={0}
                                size='large'
                                onBlur={handleSearch}
                            />
                            <InputNumber
                                name="sendingAmountTo" // Thêm name cho ô nhập đến
                                style={{ width: '50%' }}
                                placeholder="Sending Amount To"
                                size='large'
                                min={0}
                                onBlur={handleSearch}
                            />
                        </Input.Group>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                        <Input.Group compact>
                            <InputNumber
                                name="pointFrom" // Thêm name cho ô nhập từ
                                style={{ width: '50%' }}
                                placeholder="Point From"
                                min={0}
                                size='large'
                                onBlur={handleSearch}
                            />
                            <InputNumber
                                name="pointTo" // Thêm name cho ô nhập đến
                                style={{ width: '50%' }}
                                placeholder="Point To"
                                size='large'
                                min={0}
                                onBlur={handleSearch}
                            />
                        </Input.Group>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item name="types">
                            <Select size='large' style={{ width: '100%' }}
                                showSearch
                                mode="multiple"
                                placeholder="Types"
                                onChange={handleSearch}
                            >
                                <Select.Option key={'VIP'} value='VIP'>
                                    VIP
                                </Select.Option>
                                <Select.Option key={'REGULAR'} value='REGULAR'>
                                    REGULAR
                                </Select.Option>
                                <Select.Option key={'NEW'} value='NEW'>
                                    NEW
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Form.Item name="lastPhoneNumber">
                            <Input size='large' placeholder='Last Phone Number' onBlur={handleSearch} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <TableContentStyled
                rowSelection={rowSelection}
                columns={columns}
                dataSource={lstCustomer}
                rowKey="id"
                pagination={false} />

            <PagingStyled>
                <Pagination
                    current={currentPage}
                    total={totalItems}
                    pageSize={COUPON_ITEMS_PER_PAGE}
                    onChange={onPageChanged}
                    showSizeChanger={false}
                />
            </PagingStyled>

        </div>
    );
};

export const MARKETING_TAB = () => {
    return (
        <div>
            <Tabs
                className='tab-marketing-child'
                items={[
                    {
                        label: 'SMS CAMPAIGN',
                        key: '1',
                        children: <MARKETING_TAB_Sms />,
                    },
                    {
                        label: 'EMAIL CAMPAIGN',
                        key: '2',
                        children: <CREDIT_CARD_Payment />,
                    },
                    {
                        label: 'MARKETING REPORTS',
                        key: '3',
                        children: <CREDIT_CARD_Payment />,
                    }
                ]}
            />
        </div>
    );
};

export const MARKETING_TAB_Sms = () => {
    const [formSearch] = Form.useForm();
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [lstSmsCampaign, setLstSmsCampaign] = useState<any>(null);
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const getDataSmsCampaign = async (shopId: any, status: string | null, type: string | null, title: string | null, page: number, size: number) => {
        try {
            const response = await accountApis.filterSmsCampaignByShopId(shopId, status ? status : null, type, title, page, size);
            if (response.status === 200) {
                setLstSmsCampaign(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !lstSmsCampaign) {
                    setPageLoading(true);
                    await getDataSmsCampaign(id, null, 'SMS', null, currentPage, COUPON_ITEMS_PER_PAGE);
                    setPageLoading(false);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        setPageLoading(true);
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataSmsCampaign(id, values.status, 'SMS', values.title, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            setPageLoading(false);
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>TITLE</p>,
            dataIndex: 'title',
            className: 'title',
            render: (title: string) => <a>{title}</a>,
        },
        {
            title: 'PROMOTION',
            dataIndex: 'promotionValue',
            className: 'promotionValue',
            render: (promotionValue: string, promotionType: string) =>
                <span>{promotionValue ? promotionValue : '--'}</span>,
        },
        {
            title: 'CUSTOMER',
            dataIndex: 'totalCustomer',
            className: 'totalCustomer',
            render: (totalCustomer: string) =>
                <span>{totalCustomer ? totalCustomer : '--'}</span>,
        },
        {
            title: <p>SMS<br />QUANTITY</p>,
            dataIndex: 'totalSms',
            className: 'totalSms',
            render: (totalSms: string) =>
                <span>{totalSms ? totalSms : '--'}</span>
        },
        {
            title: <p>USED<br /> COUPON</p>,
            dataIndex: 'couponUsed',
            className: 'couponUsed',
            render: (couponUsed: number) =>
                <span>{couponUsed}</span>,
        },
        {
            title: <p>CREATED<br />DATE</p>,
            dataIndex: 'createDated',
            className: 'createDated',
            render: (createDated: string) =>
                <span>{createDated}</span>
        },
        {
            title: <p>SEND TIME</p>,
            dataIndex: 'sendTime',
            className: 'sendTime',
            render: (sendTime: string) =>
                <span>{sendTime}</span>
        },
        {
            title: <p>DAYS<br />EXPIRED</p>,
            dataIndex: 'endTime',
            className: 'endTime',
            render: (endTime: string) =>
                <span>{endTime ? endTime : '--'}</span>,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (status: string, record: any) =>
                <Fragment>
                    <span id={'td_status_' + record.id} >
                        <span className='coupon-label-status'
                            style={{
                                backgroundColor: status === 'SENT' ? 'rgba(232, 243, 255, 1)' : status === 'PENDING' ? 'rgba(255, 247, 232, 1)' : 'rgba(206, 206, 206, 1)',
                            }}>
                            {status === 'SENT' ? 'Sent' : status === 'PENDING' ? 'Pending' : 'Cancel'}
                        </span>
                    </span>
                </Fragment>
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            render: (record: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={record.id}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) => handleSwitchChange(checked, record.id, record.status)}
                                checked={switchStates[record.id] === undefined
                                    ? record.status === 'PENDING' || record.status === 'SENT'
                                    : switchStates[record.id]}
                            />
                            <Button className='btn-delete' icon="delete" onClick={() => handleDelete(record.id, record.title)}></Button>
                            <Button className='btn-edit' icon="edit" onClick={() => handleEditCampaign(record.id, record.status)}></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];
    const handleEditCampaign = async (smsId: string, ststus: string) => {
        if (ststus == 'SENT' || ststus == 'CANCEL') {
            message.warning('Not edit because ststus is ' + ststus);
            return;
        }
        navigate(`/accounts/salon/gift-card/${id}/update/${smsId}`);
    };

    const handleDelete = async (smsId: string, title: string) => {
        const result = await Swal.fire({
            title: title,
            html: 'Are you sure want to delete SMS?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                const campIds: any[] = [
                    smsId
                ];
                const response = await accountApis.deleteMultipleCampaign(campIds);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await onPageChanged(currentPage);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    const [switchStates, setSwitchStates] = useState<any>({});
    // Hàm để cập nhật trạng thái của Switch
    const updateSwitchState = (itemId: string, checked: any) => {
        setSwitchStates((prevStates: any) => ({
            ...prevStates,
            [itemId]: checked,
        }));
    };

    const handleSwitchChange = async (checked: any, campaignId: any, status: string) => {
        try {
            if (status == 'SENT') {
                message.warning('Status is Senting not update');
                return;
            }
            if (checked) {
                const response = await accountApis.updateCampaignStatus(id, 'PENDING', campaignId);
                if (response.status === 200) {
                    message.success('Update successful');
                    const element = document.getElementById('td_status_' + campaignId);
                    if (element) {
                        element.innerHTML = '<span class="coupon-label-status" style="background-color: rgba(255, 247, 232, 1);">Pending</span>';
                    }
                    updateSwitchState(campaignId, checked);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
                handleSearch();
            } else {
                const response = await accountApis.updateCampaignStatus(id, 'CANCEL', campaignId);
                if (response.status === 200) {
                    message.success('Update successful');
                    const element = document.getElementById('td_status_' + campaignId);
                    if (element) {
                        element.innerHTML = '<span class="coupon-label-status" style="background-color: rgba(206, 206, 206, 1);">Cancel</span>';
                    }
                    updateSwitchState(campaignId, checked);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
                handleSearch();
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    return (
        <div>
            <Form form={formSearch}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item name="status">
                            <Select size='large' style={{ width: '100%' }}
                                showSearch optionFilterProp="label"
                                defaultValue={''}
                                onChange={handleSearch}>
                                <Select.Option key={''} value=''>{'All Status'}</Select.Option>
                                <Select.Option key={'PENDING'} value='PENDING'>{'PENDING'}</Select.Option>
                                <Select.Option key={'SENT'} value='SENT'>{'SENT'}</Select.Option>
                                <Select.Option key={'CANCEL'} value='CANCEL'>{'CANCEL'}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>

                        <Form.Item name="title">
                            <Input size='large' placeholder='Title' onBlur={handleSearch} />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <div style={{ float: 'right' }}>
                            <Button icon="delete">Delete</Button>
                            <Button ml={1} variant="PRIMARY" icon="add" onClick={() => navigate(`/accounts/salon/campaign-sms/${id}/create`)}>
                                Create SMS Campaign
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>

            <TableContentStyled
                rowSelection={rowSelection}
                columns={columns}
                dataSource={lstSmsCampaign}
                rowKey="id"
                pagination={false} />

            <PagingStyled>
                <Pagination
                    current={currentPage}
                    total={totalItems}
                    pageSize={COUPON_ITEMS_PER_PAGE}
                    onChange={onPageChanged}
                    showSizeChanger={false}
                />
            </PagingStyled>
        </div>
    );
};

export const SALON_INFORMATION = () => {
    return (
        <div>
            <Tabs
                className='tab-credit-card'
                tabPosition={'left'}
                items={[
                    {
                        label: 'Basic Information',
                        key: '1',
                        children: <SALON_INFORMATION_basic_information />,
                    },
                    {
                        label: 'Reset Password',
                        key: '2',
                        children: <SALON_INFORMATION_reset_password />,
                    }
                ]}
            />
        </div>
    );
};

export const SALON_INFORMATION_basic_information = () => {
    const setPageLoading = useSetLoadingPage();
    const [formUpdateSalon] = Form.useForm();
    const [listDevice, setListDevice] = useState<any>(null);
    const [lstTimezone, setLstTimezone] = useState<any[]>([]);
    const watchDevices = Form.useWatch('devices', formUpdateSalon);
    const { id } = useParams();

    useEffect(() => {
        const initData = async () => {
            try {
                setPageLoading(true);
                const resTimeZone = await accountApis.getTimeZone();
                if (resTimeZone.status === 200) {
                    setLstTimezone(resTimeZone.data.data);
                }
                const response = await accountApis.genShopById(id);
                if (response.status === 200) {
                    const data = response.data.data;
                    const updatedDevices = initialAccountDevices.map(device => {
                        const feature = data.deviceFeatures.find((f: any) => f.feature === device.feature);
                        if (feature) {
                            return {
                                ...device,
                                numberDevice: feature.numberDevice,
                                enable: true,
                                id: feature.id,
                                shopId: feature.shopId
                            };
                        }
                        return device;
                    });
                    setListDevice(updatedDevices);
                    formUpdateSalon.setFieldsValue({
                        shopId: data.id,
                        shopName: data.shopName,
                        address: data.address,
                        email: data.email,
                        phone: data.phone,
                        zip: data.zip,
                        city: data.city,
                        state: data.state,
                        bookingOnline: data.bookingLink ? true : false,
                        giftOnline: data.giftOnlineLink ? true : false,
                        devices: updatedDevices,
                        timeZone: data.timeZone
                    });
                } else {
                    message.error({ content: response.data.message as string, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: 'System error: ' + error.message as string, duration: 3 });
            } finally {
                setPageLoading(false);
            }
        };
        initData();
    }, []);

    const onSubmitUpdateSalon = async (values: any) => {
        try {
            setPageLoading(true);
            const deviceFeatures = values?.devices?.filter((item: any) => item.enable)?.map((item: any) => ({
                id: item.id,
                feature: item.feature,
                shopId: item.shopId,
                numberDevice: Number(item.numberDevice) || 0,
            }));
            const response = await accountApis.updateSalon(
                values.shopId, values.shopName, values.timeZone, values.phone, values.email, values.address,
                values.city, values.state, values.zip, deviceFeatures, values.giftOnline, values.bookingOnline
            );
            if (response.status === 200) {
                message.success({ content: 'Update Salon success' as string, duration: 2 });
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <ContentCreditStyled>
            <Form
                form={formUpdateSalon}
                layout="vertical"
                onFinish={onSubmitUpdateSalon}
                initialValues={{ devices: listDevice }}>
                <FormItemNoMarginStyled name="shopId" style={{ display: 'none' }}>
                    <Input type='hidden' />
                </FormItemNoMarginStyled>
                <Box px={1}>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="shopName" label="Salon’s Name"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }
                            >
                                <Input size='large' autoComplete='off' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="address" label="address"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Input size='large' autoComplete='off' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="email" label="Email" rules={
                                [
                                    { required: true },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid email',
                                    }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled
                                name="phone"
                                label="Phone"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Input size='large' type="number" autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="zip" label="Zip Code" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="city" label="City" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="state" label="State" rules={
                                [
                                    { required: true }
                                ]
                            }>
                                <Input size='large' autoComplete='off' placeholder='Please enter...' />
                            </FormItemNoMarginStyled>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <SelectDevices watchDevices={watchDevices} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <FormItemNoMarginStyled name="timeZone" label="Timezone"
                                rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                <Select size='large' showSearch placeholder="Select">
                                    {lstTimezone?.map((tz, index) => (
                                        <Option key={index} value={tz.value}>
                                            {tz.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItemNoMarginStyled>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Row>
                                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <FormItemCheckBoxStyled name="bookingOnline" valuePropName="checked" label="Booking Online">
                                        <Checkbox>Booking Online</Checkbox>
                                    </FormItemCheckBoxStyled>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <FormItemCheckBoxStyled name="giftOnline" valuePropName="checked" label="Gift Cards Online">
                                        <Checkbox>Gift Cards Online</Checkbox>
                                    </FormItemCheckBoxStyled>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Box style={{ textAlign: 'right' }}>
                    <Button variant="PRIMARY" onClick={() => formUpdateSalon.submit()}>
                        <SaveOutlined style={{ marginRight: '5px' }} />
                        Save
                    </Button>
                </Box>
            </Form>

        </ContentCreditStyled>
    );
};

export const SALON_INFORMATION_reset_password = () => {
    const setPageLoading = useSetLoadingPage();
    const [formResetPin] = Form.useForm();
    const { id } = useParams();

    const onSubmit = async (values: any) => {
        try {
            const result = await Swal.fire({
                title: 'Confirm reset password',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Reset it',
                confirmButtonColor: '#d42b3c',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                setPageLoading(true);
                const response = await accountApis.resetPwdShop(id, values.newPwd);
                if (response.status === 200) {
                    formResetPin.resetFields();
                    message.success({ content: 'Reset password success' as string, duration: 2 });
                } else {
                    message.error({ content: response.data.message as string, duration: 2 });
                }
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <ContentCreditStyled>
            <Form form={formResetPin}
                layout="vertical" style={{ marginTop: '10px' }}
                onFinish={onSubmit}>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="newPwd"
                            label="New Password"
                            style={{ paddingRight: '10px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters long',
                                },
                            ]}>
                            <Input.Password
                                size="large"
                                placeholder="New PIN"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input confirm password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPwd') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Do not match');
                                    },
                                }),
                            ]}>
                            <Input.Password
                                size="large"
                                placeholder="Confirm Owner PIN"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Box style={{ textAlign: 'right' }}>
                    <Button variant="PRIMARY" onClick={() => formResetPin.submit()}>
                        <SaveOutlined style={{ marginRight: '5px' }} />
                        Save
                    </Button>
                </Box>
            </Form>
        </ContentCreditStyled>
    );
};

export const GIFT_CARDS_ONLINET_TAB = () => {
    const navigate = useNavigate();
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [lstGiftCardOfShop, setLstGiftCardOfShop] = useState<any[]>([]);
    const [listCardTemplate, setListCardTemplate] = useState<any[]>([]);
    const [sizeListGiftCard, setSizeListGiftCard] = useState(6);
    const [totalTemplateGiftCard, setTotalListGiftCard] = useState(0);
    const TEMS_PER_MORE = 6;

    const feshData = async (shopId: any, sizeListGiftCard: number) => {
        const response = await giftCardApis.getCardByShop(shopId);
        if (response.status === 200) {
            setLstGiftCardOfShop(response.data.data);
        } else {
            message.error({ content: response.data.message, duration: 2 });
        }
        const response1 = await giftCardApis.getListCardTemplate(shopId, 1, sizeListGiftCard);
        if (response1.status === 200) {
            setListCardTemplate(response1.data.data.content);
            setTotalListGiftCard(response1.data.data.totalElements);
        } else {
            message.error({ content: response1.data.message, duration: 2 });
        }
    };

    const loadMore = async () => {
        setPageLoading(true);
        setSizeListGiftCard(sizeListGiftCard + TEMS_PER_MORE);
        const response1 = await giftCardApis.getListCardTemplate(id, 1, sizeListGiftCard + TEMS_PER_MORE);
        if (response1.status === 200) {
            setListCardTemplate(response1.data.data.content);
            setTotalListGiftCard(response1.data.data.totalElements);
        } else {
            message.error({ content: response1.data.message, duration: 2 });
        }
        setPageLoading(false);
    };

    useEffect(() => {
        setPageLoading(true);
        const initData = async () => {
            try {
                if (id) {
                    await feshData(id, sizeListGiftCard);
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setPageLoading(false); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const handleDeleteTemplate = async (templateId: string, giftName: string) => {
        const result = await Swal.fire({
            title: giftName,
            html: 'Are you sure want to delete Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                const response = await giftCardApis.deleteTemplateById(templateId);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    const handleDeleteAllTemplate = async () => {
        const result = await Swal.fire({
            html: 'Are you sure want to delete All Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete All',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                const response = await giftCardApis.deleteAllTemplateByShopId(id);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    const handAddTemplate = async (templateId: string, giftName: string) => {
        const result = await Swal.fire({
            title: giftName,
            html: 'Are you sure want to add Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Add it',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                const response = await giftCardApis.assignTemplateById(templateId, id);
                if (response.status === 200) {
                    message.success('Add gift card successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    const handAddAllTemplate = async () => {
        const result = await Swal.fire({
            html: 'Are you sure want to add All Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Add them',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            setPageLoading(true);
            try {
                const response = await giftCardApis.assignAllTemplateByShopId(id);
                if (response.status === 200) {
                    message.success('Add gift card successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setPageLoading(false);
            }
        }
    };

    return (
        <div>
            <Box className="d-between">
                <Text variant="H7">Fozito’s Gift Cards ({lstGiftCardOfShop?.length})</Text>
                <Box display="flex" gap={1}>
                    <Button icon="delete" onClick={handleDeleteAllTemplate}>Delete</Button>
                </Box>
            </Box>

            <Box mt={1}>
                <Row gutter={10}>
                    {lstGiftCardOfShop?.map((item: any, index) => (
                        <Col key={index} span={4}>
                            <BoxTemplateStyled>
                                <Box p={0.2}>
                                    <img src={item.urlImage} width={'100%'} />
                                    <BtnDeleteTemplateStyled variant="PRIMARY" icon="delete" onClick={() => handleDeleteTemplate(item.id, item.giftName)}></BtnDeleteTemplateStyled>
                                </Box>
                                <Box p={0.75} pt={0.5}>

                                    <Text variant="CONTENT_1" style={{ fontWeight: 'bold' }}>
                                        {item.giftName}
                                    </Text>
                                    <Text variant="CONTENT_1">
                                        Expires Date: {item.numberDayExpired} Days
                                    </Text>
                                </Box>
                            </BoxTemplateStyled>
                        </Col>
                    ))}
                </Row>
            </Box>

            <Box className="d-between" mt={3}>
                <Text variant="H7">Library Gift Card ({totalTemplateGiftCard})</Text>
                <Box display="flex" gap={1}>
                    <Button variant="SECONDARY" icon="add" onClick={() => navigate(`/accounts/salon/gift-card/${id}/create`)}>
                        Create Gift Cards
                    </Button>
                    <Button variant="PRIMARY" icon="giftCardIcon" onClick={handAddAllTemplate}>
                        Add All Gift Cards
                    </Button>
                </Box>
            </Box>
            <Box mt={1}>
                <Row gutter={10}>
                    {listCardTemplate?.map((item: any, index) => (
                        <Col key={index} span={4}>
                            <BoxTemplateStyled>
                                <Box p={0.2}>
                                    <img src={item.urlImage} width={'100%'} />
                                    <BtnDeleteTemplateStyled variant="SECONDARY" icon="add" onClick={() => handAddTemplate(item.id, item.giftName)}>
                                    </BtnDeleteTemplateStyled>
                                </Box>
                                <Box p={0.75} pt={0.5}>
                                    <Text variant="CONTENT_1" style={{ fontWeight: 'bold' }}>
                                        {item.giftName}
                                    </Text>
                                    <Text variant="CONTENT_1">
                                        Expires Date: {item.numberDayExpired} Days
                                    </Text>
                                </Box>
                            </BoxTemplateStyled>
                        </Col>
                    ))}
                </Row>

                {listCardTemplate.length < totalTemplateGiftCard ?
                    (<Box justifyContent='center' display='flex'>
                        <Button mt={1.5} variant='SECONDARY' onClick={() => loadMore()}>
                            Load More
                        </Button>
                    </Box>) : null
                }
            </Box>

        </div>
    );
};

export const CREDIT_CARD = () => {
    return (
        <div>
            <Tabs
                className='tab-credit-card'
                tabPosition={'left'}
                items={[
                    {
                        label: 'Fee',
                        key: '1',
                        children: <CREDIT_CARD_Fee />,
                    },
                    {
                        label: 'Payment',
                        key: '2',
                        children: <CREDIT_CARD_Payment />,
                    }
                ]}
            />
        </div>
    );
};

export const CREDIT_CARD_Fee = () => {
    const setPageLoading = useSetLoadingPage();

    const [formFee] = Form.useForm();
    const { id } = useParams();
    const [cardFeeData, setCardFeeData] = useState<any>();
    const [enable, setEnable] = useState<boolean>(false);
    const handleSwitchChange = (checked: boolean) => {
        setEnable(checked);
    };

    useEffect(() => {
        setPageLoading(true);
        const initData = async () => {
            try {
                if (id) {
                    const response = await giftCardApis.getCardFeeData(id);
                    if (response.status === 200) {
                        setCardFeeData(response.data.data);
                        handleSwitchChange(response.data.data.enable);
                        formFee.setFieldsValue({
                            enable: response.data.data.enable,
                            feeType: response.data.data.feeType,
                            feeValue: response.data.data.feeValue,
                            tipFeeType: response.data.data.tipFeeType,
                            tipFeeValue: response.data.data.tipFeeValue
                        });
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setPageLoading(false); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const onSubmit = async (values: any) => {
        try {
            setPageLoading(true);
            let feeValue = values.feeValue;
            let tipFeeType = values.tipFeeType;
            if (enable === false) {
                feeValue = 0;
                tipFeeType = 0;
            }
            const response = await giftCardApis.upodateCardFeeData(cardFeeData.id, id, enable, values.feeType, feeValue,
                tipFeeType, values.tipFeeValue);
            if (response.status === 200) {
                message.success('Update successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }

        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false);
        }
    };

    return (
        <ContentCreditStyled>
            <Text variant="H7">Fee</Text>
            <Form form={formFee}
                layout="vertical" style={{ marginTop: '10px' }}
                onFinish={onSubmit}>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Box style={{ display: 'flex' }}>
                            {cardFeeData && (
                                <Form.Item name="enable" valuePropName="checked" style={{ marginBottom: '10px' }}>
                                    <Switch title='Have Fee'
                                        defaultChecked={cardFeeData.enable}
                                        onChange={handleSwitchChange}
                                        checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                    <span style={{ fontSize: '16px', marginLeft: '10px' }}>Have Fee</span>
                                </Form.Item>
                            )}
                        </Box>
                    </Col>
                </Row>
                {enable && (
                    <Fragment>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="feeType" label="Type Fee Credit Card" style={{ marginRight: '20px' }}>
                                    <Select placeholder='Select'>
                                        <Option value='MONEY'>Dollar</Option>
                                        <Option value='PERCENT'>Percent</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="tipFeeType" label="Type Fee Tip Credit Card">
                                    <Select placeholder='Select'>
                                        <Option value='MONEY'>Dollar</Option>
                                        <Option value='PERCENT'>Percent</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="feeValue" label="Fee Credit Card" style={{ marginRight: '20px' }}
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="tipFeeValue" label="Fee Tip Credit Card"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Fragment>

                )}
                <Box style={{ textAlign: 'right' }}>
                    <Button variant="PRIMARY" onClick={() => formFee.submit()}>
                        <SaveOutlined style={{ marginRight: '5px' }} />
                        Save
                    </Button>
                </Box>
            </Form>
        </ContentCreditStyled>
    );
};

export const CREDIT_CARD_Payment = () => {
    const setPageLoading = useSetLoadingPage();
    const { id } = useParams();
    const [lstData, setLstData] = useState<any[]>([]);
    const [formCreatePayment] = Form.useForm();
    const [formEditPayment] = Form.useForm();
    const [lstStation, setLstStation] = useState<any[]>([]);

    const getDataList = async (shopId: any) => {
        try {
            const response = await giftCardApis.getListDataPayment(shopId);
            if (response.status === 200) {
                setLstData(response.data.data);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        setPageLoading(true);
        const initData = async () => {
            try {
                if (id) {
                    await getDataList(id);
                    const response = await giftCardApis.getListStation(id);
                    if (response.status === 200) {
                        setLstStation(response.data.data);
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setPageLoading(false); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: <p>STATION</p>,
            dataIndex: 'stationName',
            className: 'stationName',
            render: (stationNumber: string) =>
                <span>{stationNumber ? 'Station ' + stationNumber : '--'}</span>,
        },
        {
            title: <p>DEVICE ID</p>,
            dataIndex: 'deviceId',
            className: 'deviceId',
            render: (deviceId: string) =>
                <span>{deviceId ? deviceId : '--'}</span>,
        },
        {
            title: <p>PAYMENT TYPE</p>,
            dataIndex: 'sourceType',
            className: 'sourceType',
            render: (sourceType: string) =>
                <span>{sourceType ? (sourceType === 'DE_JA_VOO' ? 'Dejavoo' : sourceType) : '--'}</span>,
        },
        {
            title: <p>AUTH KEY</p>,
            dataIndex: 'authKey',
            className: 'authKey',
            render: (authKey: string) =>
                <span>{authKey ? authKey : '--'}</span>,
        },
        {
            title: <p>TPN</p>,
            dataIndex: 'tpnAccount',
            className: 'tpnAccount',
            render: (tpnAccount: string) =>
                <span>{tpnAccount ? tpnAccount : '--'}</span>,
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            render: (item: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={item.couponId}
                                onChange={(checked) => handleSwitchChange(checked, item.id)}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                defaultChecked={item.active} />
                            <Button className='btn-edit' icon="edit" onClick={() => showEditPaymentMethod(item.id)}></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const hideModal = () => {
        setIsModalVisible(false);
        setIsModalVisibleEdit(false);
        formCreatePayment.resetFields();
        formEditPayment.resetFields();
    };

    const handleSwitchChange = async (checked: any, itemId: any) => {
        try {
            const response = await giftCardApis.updateStatusPaymentMethod(id, itemId, checked);
            if (response.status === 200) {
                message.success('Update successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const showEditPaymentMethod = async (id: any) => {
        try {
            setPageLoading(true);
            const response = await giftCardApis.getDetailPaymentMethod(id);
            if (response.status === 200) {
                setEditTitle(`Edit ${response.data.data.stationName}`);
                formEditPayment.setFieldsValue({
                    sourceType: response.data.data.sourceType,
                    tpn: response.data.data.tpnAccount,
                    authKey: response.data.data.authKey,
                    registerKey: response.data.data.registerId,
                    token: response.data.data.iposToken,
                    id: response.data.data.id,
                    stationId: response.data.data.stationId,
                    active: response.data.data.active,
                    enableGiftOnline: response.data.data.enableGiftOnline
                });
                setIsModalVisibleEdit(true);
                handleEnableGiftOnlineChange(response.data.data.enableGiftOnline);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        } finally {
            setPageLoading(false);

        }
    };

    const onFinishCreate = async (values: any) => {
        try {
            setPageLoading(true);
            const response = await giftCardApis.createOrUpdatePaymentMethod(id, null, values.stationId, values.sourceType, values.tpn, values.authKey, values.registerKey, values.token, true, values.enableGiftOnline);
            if (response.status === 200) {
                await getDataList(id);
                message.success('Create successful');
                hideModal();
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false); // Set loading to false regardless of success or failure
        }
    };

    const onFinishUpdate = async (values: any) => {
        try {
            setPageLoading(true);
            const response = await giftCardApis.createOrUpdatePaymentMethod(id, values.id,
                values.stationId, values.sourceType, values.tpn, values.authKey, values.registerKey, values.token, values.active, values.enableGiftOnline);
            if (response.status === 200) {
                await getDataList(id);
                message.success('update successful');
                hideModal();
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setPageLoading(false); // Set loading to false regardless of success or failure
        }
    };
    const [enableGiftOnline, setEnableGiftOnline] = useState(false);
    const handleEnableGiftOnlineChange = (checked: boolean) => {
        setEnableGiftOnline(checked);
    };
    return (
        <ContentCreditStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={isModalVisible}
                onCancel={hideModal}
                onOk={() => formCreatePayment.submit()}
                title={'Add New'}
                closeIcon={null}
                width={600}
                centered>
                <Form form={formCreatePayment}
                    layout="vertical"
                    onFinish={onFinishCreate}>
                    <Form.Item name="stationId" label="Station" style={{ marginRight: '20px', marginLeft: '20px' }}>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            {lstStation.map((item, index) => (
                                <Option key={index} value={item.id} label={`Station ${item.stationNumber}`}>
                                    {`Station ${item.stationNumber}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="sourceType" label="Payment Type" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            <Option key='DE_JA_VOO' value='DE_JA_VOO' label='Dejavoo'>
                                Dejavoo
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="tpn" label="TPN" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="authKey" label="Auth Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 10 },
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="registerKey" label="Register Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 12 }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="enableGiftOnline" label="Enable Gift Online" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                <Switch
                                    defaultChecked={false}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={handleEnableGiftOnlineChange} />
                            </Form.Item>
                        </Col>
                        {enableGiftOnline && (
                            <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                <Form.Item name="token" label="Token" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal>

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={isModalVisibleEdit}
                onCancel={hideModal}
                onOk={() => formEditPayment.submit()}
                title={editTitle}
                closeIcon={null}
                width={600}
                centered>
                <Form form={formEditPayment}
                    layout="vertical"
                    onFinish={onFinishUpdate}>

                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input type='hidden' />
                    </Form.Item>

                    <Form.Item name="active" style={{ display: 'none' }}>
                        <Input type='hidden' />
                    </Form.Item>

                    <Form.Item name="stationId" label="Station" style={{ marginRight: '20px', marginLeft: '20px' }}>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            {lstStation.map((item, index) => (
                                <Option key={index} value={item.id} label={`Station ${item.stationNumber}`}>
                                    {`Station ${item.stationNumber}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="sourceType" label="Payment Type" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            <Option key='DE_JA_VOO' value='DE_JA_VOO' label='Dejavoo'>
                                Dejavoo
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="tpn" label="TPN" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="authKey" label="Auth Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 10 },
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="registerKey" label="Register Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 12 }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="enableGiftOnline" label="Enable Gift Online" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                <Switch
                                    defaultChecked={enableGiftOnline}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={handleEnableGiftOnlineChange} />
                            </Form.Item>
                        </Col>
                        {enableGiftOnline && (
                            <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                <Form.Item name="token" label="Token" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal>

            <Box className="d-between">
                <Text variant="H7">Payment</Text>
                <Box display="flex" gap={1}>
                    <Button variant="SECONDARY" icon='add' onClick={() => setIsModalVisible(true)}>
                        Add New
                    </Button>
                </Box>
            </Box>
            <Box mt={1}>
                <TableContentStyled
                    className='custom-th'
                    columns={columns}
                    dataSource={lstData}
                    rowKey="couponId"
                    pagination={false} />
            </Box>
        </ContentCreditStyled>
    );
};

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;

    .tab-credit-card .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 18px;
    }

    .tab-credit-card .ant-tabs-nav {
        background: rgba(246, 247, 252, 1);
        width: 235px;
        min-height: 500px;
    }

    .tab-credit-card .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
    }

    .tab-credit-card .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    .custom-th .ant-table-thead >tr>th {
        background: rgba(227, 233, 237, 1)
    }

    .tab-marketing-child .ant-tabs-tab {
        min-width: 120px !important;
    }

    .tab-marketing-child .ant-tabs-tab .ant-tabs-tab-btn {
        line-height: 1;
    }

    .tab-marketing-child .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
        min-width: 150px;
        padding: 8px 24px;
        text-align: center;
    }

    .tab-marketing-child .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    && .btn-delete, .btn-edit {
        background: transparent;
        border: none;
        padding: 8px;
      }
    
    && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
    }

    && .coupon-label-status {
        border-radius: 2px;
        color: #1d2129;
        padding: 5px 20px 5px 20px;
    }
`;

const ContentCreditStyled = styled(Box)`
    background: rgba(246, 247, 252, 1);
    padding: 8px 24px;
    
`;

const BoxTemplateStyled = styled(Box)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const BtnDeleteTemplateStyled = styled(Button)`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 7px;
    position: absolute;
    top: 55%;
    right: 5%;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 8px;
`;

const TableContentStyled = styled(Table)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }
`;

const FormItemCheckBoxStyled = styled(Form.Item)`
  margin: 10px;
  && .ant-form-item-label label {
    color: transparent;
  }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
`;

const CustomerTypeStyled = styled(Box)`
  padding: 8px 0px 8px 0px;
  border-radius: 20px;
  text-align: center;
`;
