import React from 'react';
import { AddIconProps } from '..';
const VoidIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1416_62915)">
        <path
          d="M0.464063 2.1558C0.999375 1.29893 1.98656 0.740648 3 0.750492C7.37531 0.749555 11.7511 0.750023 16.1264 0.750023C16.4391 0.749555 16.7545 0.74018 17.0625 0.802992C17.7155 0.924867 18.3197 1.27643 18.7561 1.77612C19.2239 2.3044 19.4977 3.00049 19.5038 3.70737C19.5066 4.95565 19.5038 6.2044 19.5052 7.45268C19.5061 7.61393 19.4813 7.78034 19.395 7.91955C19.1273 8.3658 18.368 8.36252 18.1083 7.90971C17.9798 7.70159 18.0056 7.4494 18.0042 7.21643C18.0042 6.06143 18.0052 4.9069 18.0038 3.7519C18.0056 3.3558 17.8777 2.94565 17.5908 2.66299C17.3067 2.37565 16.8952 2.24721 16.4977 2.25049C12.0141 2.24955 7.53047 2.25049 3.04688 2.25002C2.63625 2.24112 2.20641 2.3644 1.91344 2.66346C1.61484 2.95737 1.49063 3.38627 1.50047 3.7969C1.50094 9.28174 1.49953 14.7666 1.50094 20.2514C1.49719 20.6789 1.65328 21.1233 1.98656 21.4036C2.27625 21.6558 2.66859 21.7561 3.04594 21.75C5.28141 21.7505 7.51688 21.7491 9.75188 21.7505C10.0059 21.7449 10.2713 21.863 10.3988 22.0899C10.5638 22.3856 10.537 22.7991 10.2938 23.0452C10.1414 23.1999 9.91781 23.2556 9.70688 23.2505C7.45594 23.2486 5.20453 23.2519 2.95359 23.2486C2.11453 23.2425 1.29516 22.8511 0.745313 22.2206C0.300938 21.7219 0.0417188 21.0708 0 20.4052V3.57096C0.0417188 3.07174 0.188906 2.57721 0.464063 2.1558Z"
          fill={active ? 'white' : 'black'}
        />
        <path
          d="M5.03576 6.77545C5.18389 6.74076 5.3367 6.75107 5.48717 6.74967C8.40795 6.75061 11.3287 6.7492 14.2495 6.75061C14.4056 6.75061 14.565 6.78576 14.6962 6.87389C15.1101 7.14811 15.1087 7.85826 14.6911 8.12873C14.5476 8.22529 14.3714 8.25295 14.2017 8.2506C11.2172 8.2492 8.23264 8.25154 5.24811 8.24967C4.9467 8.25998 4.6378 8.07764 4.54639 7.78185C4.3978 7.38811 4.59608 6.86264 5.03576 6.77545Z"
          fill={active ? 'white' : 'black'}
        />
        <path
          d="M15.104 10.0964C16.507 9.62998 18.0628 9.63561 19.4615 10.1151C20.5626 10.4878 21.5597 11.1576 22.327 12.03C23.333 13.162 23.9208 14.6433 24 16.1536V16.844C23.9559 17.5392 23.8237 18.2311 23.5767 18.8836C23.0578 20.2851 22.0523 21.4992 20.7731 22.2717C19.2858 23.183 17.4394 23.4684 15.742 23.0803C14.1745 22.733 12.7476 21.7973 11.8026 20.4994C10.9945 19.4015 10.5389 18.0515 10.5065 16.6894C10.4648 15.315 10.8492 13.9294 11.6076 12.7814C12.427 11.5265 13.6809 10.5651 15.104 10.0964ZM16.7076 11.28C14.8851 11.4675 13.2323 12.6965 12.4767 14.3573C11.978 15.4275 11.8669 16.672 12.1767 17.812C12.5151 19.0861 13.3551 20.212 14.4642 20.9203C15.2494 21.4261 16.1714 21.7209 17.1061 21.7467C18.1472 21.7795 19.1925 21.4772 20.0639 20.9081C21.203 20.1736 22.0537 18.9965 22.3659 17.6747C22.6097 16.664 22.5281 15.5794 22.1414 14.6147C21.6919 13.4784 20.8373 12.5109 19.7719 11.9147C18.8484 11.3934 17.7623 11.1633 16.7076 11.28Z"
          fill={active ? 'white' : 'black'}
        />
        <path
          d="M5.03344 11.2758C5.11876 11.2547 5.20688 11.2504 5.29454 11.2495C6.78001 11.2509 8.26547 11.249 9.75047 11.2504C10.0055 11.2444 10.2722 11.3629 10.3997 11.5917C10.553 11.8683 10.5384 12.2419 10.3411 12.4931C10.2 12.6698 9.96985 12.7523 9.7486 12.7495C8.26407 12.7509 6.77954 12.749 5.29501 12.7504C5.07188 12.7575 4.83469 12.6904 4.68329 12.5179C4.33688 12.134 4.49344 11.3859 5.03344 11.2758Z"
          fill={active ? 'white' : 'black'}
        />
        <path
          d="M14.3292 14.4418C14.3452 14.0692 14.6747 13.7396 15.0478 13.7256C15.262 13.7138 15.4711 13.8118 15.6183 13.9646C16.1634 14.5098 16.7095 15.054 17.2542 15.5996C17.8177 15.0413 18.3741 14.4756 18.9389 13.9187C19.2038 13.6613 19.6608 13.6646 19.9247 13.922C20.1319 14.1038 20.2336 14.4057 20.1511 14.6729C20.1061 14.8388 19.9908 14.971 19.8694 15.0868C19.3472 15.6071 18.8264 16.1288 18.3056 16.6506C18.847 17.191 19.3875 17.7324 19.9289 18.2734C20.0845 18.4201 20.1877 18.6277 20.1792 18.8452C20.1708 19.2259 19.8324 19.5643 19.4517 19.5746C19.2464 19.5821 19.0453 19.4926 18.9024 19.3473C18.3525 18.7998 17.8055 18.2485 17.2547 17.701C16.703 18.2499 16.1545 18.8021 15.6024 19.3506C15.4359 19.5184 15.1884 19.6088 14.9536 19.5629C14.5852 19.4987 14.2894 19.1317 14.332 18.7548C14.348 18.5724 14.4431 18.4051 14.5739 18.2799C15.1177 17.7371 15.6619 17.1943 16.2038 16.6496C15.6586 16.1031 15.112 15.5574 14.5664 15.0118C14.4127 14.8656 14.3166 14.6556 14.3292 14.4418Z"
          fill={active ? 'white' : 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1416_62915">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoidIcon;
