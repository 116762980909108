import { fastApi } from 'utils/fastApi';

const getCardByShop = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/gift-card/template-by-shop/${shopId}`).then((response: any) => {
    return response;
  });
};

const getListCardTemplate = async (shopId: any, page: number, size: number) => {
  const params = { shopId, size, page };
  return await fastApi.get('/api/v1/admin/gift-card/list-template-config', { params }).then((response: any) => {
    return response;
  });
};

const deleteTemplateById = async (id: any) => {
  return await fastApi.delete(`/api/v1/admin/gift-card/deleteTemplate/${id}`).then((response: any) => {
    return response;
  });
};

const deleteAllTemplateByShopId = async (id: any) => {
  return await fastApi.delete(`/api/v1/admin/gift-card/delete-all-template/${id}`).then((response: any) => {
    return response;
  });
};

const assignTemplateById = async (templateId: any, shopId: any) => {
  const payload = { templateId, shopId };
  return await fastApi.post('/api/v1/admin/gift-card/assign-template-salon', payload).then((response: any) => {
    return response;
  });
};

const assignAllTemplateByShopId = async (shopId: any) => {
  return await fastApi.put(`/api/v1/admin/gift-card/assign-template-all/${shopId}`).then((response: any) => {
    return response;
  });
};

const getListLibraryImg = async () => {
  return await fastApi.get('/api/v1/admin/gift-card/library-image').then((response: any) => {
    return response;
  });
};

const CreateOrUpdateTemplate = async (templateId: any, shopOnline: Boolean, totalExpireDate: number, amountOptions: any, urlImage: string, giftName: string) => {
  const payload = { templateId, shopOnline, totalExpireDate, amountOptions, urlImage, giftName };
  return await fastApi.post('/api/v1/admin/gift-card/create-or-update-template', payload).then((response: any) => {
    return response;
  });
};

const filterListGiftCard = async (shopId: any, customerName: any,
  sourceType: any, giftCode: any,
  resetTime: any, page: any, size: any) => {
  const params = { shopId, customerName, sourceType, resetTime, giftCode, size, page };
  return await fastApi.get('/api/v1/admin/gift-card/list', { params }).then((response: any) => {
    return response;
  });
};

const getCardFeeData = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/credit-card/fee/${shopId}`).then((response: any) => {
    return response;
  });
};

const upodateCardFeeData = async (id: any, shopId: any, enable: boolean, feeType: string, feeValue: any, tipFeeType: string, tipFeeValue: any) => {
  const payload = { id, shopId, enable, feeType, feeValue, tipFeeType, tipFeeValue };
  return await fastApi.put(`/api/v1/admin/credit-card/fee/${shopId}`, payload).then((response: any) => {
    return response;
  });
};

const getListDataPayment = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/credit-card/payment-method/${shopId}`).then((response: any) => {
    return response;
  });
};

const getListStation = async (shopId: any) => {
  return await fastApi.get(`/api/v1/admin/credit-card/station-device/${shopId}`).then((response: any) => {
    return response;
  });
};

const createOrUpdatePaymentMethod = async (shopId: any, id: any, stationId: any, sourceType: any, tpn: any, authKey: any, registerKey: any, token: any, active: boolean, enableGiftOnline: boolean) => {
  const payload = { id, stationId, sourceType, tpn, authKey, registerKey, token, active, enableGiftOnline };
  return await fastApi.post(`/api/v1/admin/credit-card/payment-method/${shopId}`, payload).then((response: any) => {
    return response;
  });
};

const getDetailPaymentMethod = async (id: any) => {
  return await fastApi.get(`/api/v1/admin/credit-card/detail/${id}`).then((response: any) => {
    return response;
  });
};

const updateStatusPaymentMethod = async (shopId: any, id: any, active: boolean) => {
  const payload = { id, active };
  return await fastApi.put(`/api/v1/admin/credit-card/update-status-method/${shopId}`, payload).then((response: any) => {
    return response;
  });
};

const giftCardApis = {
  getCardByShop,
  getListCardTemplate,
  deleteTemplateById,
  assignTemplateById,
  deleteAllTemplateByShopId,
  assignAllTemplateByShopId,
  getListLibraryImg,
  CreateOrUpdateTemplate,
  filterListGiftCard,
  getCardFeeData,
  upodateCardFeeData,
  getListDataPayment,
  getListStation,
  createOrUpdatePaymentMethod,
  getDetailPaymentMethod,
  updateStatusPaymentMethod
};

export default giftCardApis;
