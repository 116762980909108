import React from 'react';
import { AddIconProps } from '..';
const DiscountIcon : React.FC<AddIconProps> = ({active=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4454 0.0995184C11.4769 -0.0350339 12.5246 -0.0320356 13.5561 0.101767C15.5592 0.36525 17.4945 1.14183 19.1161 2.3498C20.3547 3.26356 21.4132 4.42093 22.2185 5.7331C23.0467 7.08499 23.5983 8.6033 23.8424 10.1696C23.9169 10.6111 23.9221 11.0608 24 11.5012V12.7208C23.9712 12.7943 23.9405 12.8692 23.9401 12.9498C23.7315 15.8365 22.4125 18.6209 20.3289 20.6246C18.392 22.5132 15.7966 23.7054 13.1041 23.9483C11.2287 24.1233 9.31133 23.8534 7.56206 23.1507C5.17404 22.2171 3.123 20.4705 1.77585 18.2911C0.871638 16.8185 0.287551 15.1488 0.0879875 13.4314C0.0426833 13.1717 0.0531669 12.9064 0 12.6477V11.324C0.026209 11.1819 0.0471763 11.0387 0.0524181 10.8941C0.247488 8.71012 1.06147 6.58389 2.37604 4.82946C2.801 4.27401 3.25105 3.73206 3.77635 3.26768C4.27657 2.77332 4.83969 2.3483 5.42378 1.95889C6.9293 0.971298 8.66022 0.330019 10.4454 0.0995184ZM11.3642 1.58184C8.87251 1.72951 6.43994 2.80743 4.67457 4.57835C3.15033 6.0648 2.11133 8.0366 1.72905 10.131C1.60549 10.8363 1.53997 11.5515 1.55869 12.2677C1.59239 13.1934 1.73504 14.1192 2.00649 15.0067C2.71151 17.3777 4.30016 19.4694 6.38565 20.794C8.04243 21.8644 10.0126 22.4337 11.9813 22.4416C13.9683 22.4378 15.9579 21.8614 17.6263 20.7741C19.7609 19.4087 21.3682 17.2371 22.0373 14.7901C22.3653 13.6342 22.4746 12.4214 22.3881 11.2243C22.2159 8.74497 21.1031 6.34889 19.3382 4.60309C18.2816 3.55553 17.0041 2.72535 15.6052 2.21563C14.2555 1.7104 12.8012 1.49601 11.3642 1.58184Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M14.7225 4.79729C14.9583 4.76731 15.2099 4.76469 15.4252 4.88087C15.7895 5.02405 16.0423 5.41758 15.9973 5.81037C15.955 6.033 15.8352 6.22977 15.7382 6.43179C13.7449 10.522 11.747 14.6095 9.75397 18.7C9.66635 18.8878 9.51883 19.0516 9.32863 19.1382C9.14442 19.2382 8.931 19.2322 8.72807 19.218C8.375 19.1517 8.06011 18.8863 7.96239 18.5355C7.87815 18.3387 7.93057 18.121 8.0223 17.9373C10.0801 13.7111 12.1326 9.48189 14.1915 5.25642C14.2911 5.03267 14.4866 4.86363 14.7225 4.79729Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M7.32017 5.52193C7.78631 5.48595 8.26444 5.47095 8.72273 5.58039C9.15705 5.67372 9.59287 5.8495 9.90663 6.17482C10.3338 6.58298 10.5117 7.1924 10.5087 7.77109C10.5128 8.67022 10.5117 9.56936 10.5091 10.4685C10.5184 11.0768 10.3237 11.7173 9.86769 12.1382C9.48129 12.5112 8.94513 12.6697 8.42544 12.7409C7.72117 12.8166 6.9787 12.7728 6.33134 12.4624C5.85433 12.2353 5.4934 11.7949 5.34663 11.2889C5.189 10.8324 5.22344 10.3441 5.22007 9.8692C5.2212 9.18182 5.21783 8.49482 5.22195 7.80744C5.22307 7.20214 5.40616 6.56199 5.85845 6.13847C6.25046 5.76142 6.79524 5.60251 7.32017 5.52193ZM7.61745 7.05147C7.50026 7.08071 7.38232 7.12081 7.2846 7.19427C7.09702 7.33482 7.00865 7.57881 7.01502 7.80819C7.01202 8.68222 7.01315 9.55662 7.01465 10.4306C7.00379 10.6552 7.0768 10.8928 7.24678 11.0461C7.43661 11.2084 7.6972 11.253 7.93982 11.2395C8.21989 11.2372 8.5168 11.109 8.65234 10.8527C8.76691 10.657 8.74931 10.4239 8.75081 10.2061C8.74893 9.40633 8.7538 8.60614 8.74819 7.80594C8.75193 7.53309 8.6089 7.24262 8.35056 7.13093C8.12553 7.01362 7.86195 7.02149 7.61745 7.05147Z"
        fill={active ? 'white' : '#1D2129'}
      />
      <path
        d="M15.5833 11.2056C16.1787 11.1783 16.7976 11.1944 17.3528 11.4354C17.7377 11.5808 18.0758 11.854 18.2907 12.2063C18.5435 12.6149 18.6382 13.1032 18.6322 13.5785C18.633 14.4165 18.6333 15.2542 18.6322 16.0919C18.6359 16.6773 18.4843 17.2931 18.0807 17.7338C17.6411 18.2046 16.9874 18.4096 16.3599 18.4448C16.0128 18.5003 15.6616 18.4561 15.3156 18.419C14.7986 18.3433 14.2725 18.1484 13.9075 17.7601C13.5488 17.4006 13.3859 16.8875 13.3466 16.3909C13.335 15.4412 13.3432 14.4915 13.3417 13.5417C13.3335 13.1516 13.3971 12.7539 13.566 12.4001C13.7221 12.0527 13.9962 11.7629 14.3216 11.5688C14.7065 11.3477 15.1453 11.2502 15.5833 11.2056ZM15.7346 12.7464C15.5732 12.7764 15.4145 12.8457 15.3078 12.9739C15.1457 13.1444 15.1161 13.3918 15.1131 13.6167C15.1131 14.3659 15.1135 15.1155 15.1131 15.8651C15.112 16.1012 15.1048 16.3531 15.2209 16.5671C15.3291 16.7687 15.547 16.8849 15.7679 16.916C16.0341 16.9513 16.3284 16.9351 16.5579 16.7811C16.7815 16.6308 16.8665 16.3478 16.8653 16.0907C16.8653 15.2403 16.8665 14.3903 16.865 13.5402C16.8552 13.3221 16.7994 13.0856 16.6276 12.9372C16.3943 12.7082 16.0371 12.7142 15.7346 12.7464Z"
        fill={active ? 'white' : '#1D2129'}
      />
    </svg>
  );
};

export default DiscountIcon;
